import React from "react"
import styled from "styled-components"

import { ReactComponent as ExitIcon } from "images/exit.svg"
import { Colors, Shadows } from "helpers/constants"
import Breadcrumbs from "Authenticated/components/Breadcrumbs"
import { getUserLabel, useUser, useSession } from "components/user"
import { useMode } from "Authenticated/components/mode"
import { SSO_ENDPOINT } from "services/api"
import Notifications from "./Notifications"

const Container = styled.div`
  height: 80px;
  background-color: ${Colors.yellow};
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  box-shadow: ${Shadows.menu};
`
const Right = styled.div`
  display: flex;
  align-items: center;
`
const User = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  font-weight: 500;
  border-left: 1px solid ${Colors.white};
  margin-left: 20px;
  color: ${Colors.white};
  padding: 0 20px 0 30px;
  line-height: 18px;
`
const LogoutButton = styled.button`
  background: ${Colors.white};
  width: 45px;
  height: 45px;
  border-radius: 25px;
  box-shadow: ${Shadows.paper};
  svg {
    vertical-align: middle;
    pointer-events: none;
  }
`

const Header: React.FC = () => {
  const { setSession } = useSession()
  const { user, sso } = useUser()
  const { ping } = useMode()

  const handleLogout = () => {
    ping()
    if (sso) {
      window.open(`${SSO_ENDPOINT}/logout`)
    }
    setSession(null)
  }

  return (
    <Container>
      <Breadcrumbs />
      <Right>
        <Notifications />
        <User>
          Bonjour, <br />
          {getUserLabel(user)}
        </User>
        <LogoutButton type="button" title="Déconnexion" onClick={handleLogout}>
          <ExitIcon />
        </LogoutButton>
      </Right>
    </Container>
  )
}

export default Header
