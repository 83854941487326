import PdfIcon from "./Pdf"
import ImageIcon from "./Image"
import VideoIcon from "./Video"
import AudioIcon from "./Audio"
import ThreeSixtyIcon from "./ThreeSixty"
import ArIcon from "./Ar"
import TextIcon from "./Text"
import QuizIcon from "./Quiz"

interface Props {
  bgColor?: string
}

const RESOURCE_ICONS: Record<string, React.FC<Props>> = {
  pdf: PdfIcon,

  image: ImageIcon,
  picto_poi: ImageIcon,

  video: VideoIcon,

  audio: AudioIcon,

  360: ThreeSixtyIcon,
  file360: ThreeSixtyIcon,

  ar: ArIcon,
  object3D: ArIcon,
  tagScene: ArIcon,
  scene: ArIcon,
  scenario: ArIcon,
  gps: ArIcon,

  text: TextIcon,
  simple: TextIcon,

  quiz: QuizIcon,
  exercice: QuizIcon,
}

export default RESOURCE_ICONS
