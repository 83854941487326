import React from "react"

import { Colors } from "helpers/constants"

interface Props {
  bgColor?: string
}

const ThreeSixtyIcon: React.FC<Props> = ({ bgColor, ...props }) => (
  <svg viewBox="0 0 77 77" {...props}>
    <circle
      stroke="#FFFEFE"
      fill={bgColor || Colors.blue}
      fillRule="nonzero"
      cx="37.5"
      cy="37.5"
      r="37.5"
    />
    <g fill="none" fillRule="evenodd">
      <path
        d="M63.189 22.44L34.134 12.58c-.709-.236-1.477.118-1.713.827l-9.626 28.287c-.236.709.118 1.476.827 1.713l29.055 9.862c.709.236 1.477-.118 1.713-.827l9.567-28.287a1.266 1.266 0 0 0-.768-1.713z"
        fill="#FBB900"
        fillRule="nonzero"
      />
      <path
        fill="#E6E9EE"
        fillRule="nonzero"
        d="M60.546 24.843L52.07 49.841l-25.836-8.76 8.476-24.998z"
      />
      <path
        d="M42.224 21.378L12.047 26.87a1.321 1.321 0 0 0-1.063 1.595l5.374 29.409c.118.709.827 1.24 1.595 1.063l30.118-5.492c.709-.118 1.24-.827 1.122-1.595l-5.374-29.41c-.177-.708-.886-1.24-1.595-1.062z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        fill="#E6E9EE"
        fillRule="nonzero"
        d="M41.081 24.686l4.736 25.97-26.842 4.895-4.736-25.97z"
      />
      <g fill="#4CDBC4" fillRule="nonzero">
        <path d="M23.976 40.098l-4.96 15.473 15.118-2.776z" />
        <path d="M34.843 32.126l-4.725 15.65 4.016 5.02 11.693-2.127-.945-4.901z" />
      </g>
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M34.843 32.126l-2.067 6.791 1.712-1.476 1.654 1.594.945-2.185 1.948.945zM23.976 40.098l-2.185 6.792 1.831-1.536 1.654 1.595.944-2.185 2.186.886z"
      />
      <circle fill="#FFD05B" fillRule="nonzero" cx="26.929" cy="33.602" r="2.421" />
      <path
        d="M64.91 53.66a.667.667 0 0 1-.591.34H39.282a2.29 2.29 0 0 1-.678-.102A2.265 2.265 0 0 1 37 51.742V41.444c0-.245.201-.444.448-.444h26.87c.358 0 .533.238.591.34a.652.652 0 0 1-.008.677l-2.727 4.498a1.894 1.894 0 0 0 0 1.97l2.727 4.498a.653.653 0 0 1 .008.676z"
        stroke="#FBB900"
        fill="#FBB900"
        fillRule="nonzero"
      />
      <text
        fontFamily="Klavika-Regular, Klavika"
        fontSize="11"
        letterSpacing="-.138"
        fill="#1D1D1B"
        transform="translate(10 13)"
      >
        <tspan x="30.331" y="38">
          360°
        </tspan>
      </text>
      <path
        d="M25.93 51.886l1.282-.71c.992-.551.933-1.985-.099-2.441l-8.67-3.781a1.383 1.383 0 0 0-1.92 1.063l-1.388 9.358c-.168 1.12 1.024 1.926 2.017 1.376l1.158-.642c6.367 7.232 16.199 6.28 21.086 1.108.43-.453-.091-1.16-.668-.893-5.686 2.638-11.321-.407-12.799-4.438z"
        fill="#FF7058"
        fillRule="nonzero"
      />
      <path
        d="M45.828 23.766H44.48c-1.044 0-1.636 1.174-1.01 1.997l5.293 6.881c.504.66 1.514.66 2.019 0l5.292-6.881c.635-.823.035-1.997-1.01-1.997h-1.218c-1.906-8.612-10.245-12.21-16.485-10.24-.549.172-.444.968.139 1.011 5.762.412 8.939 5.348 8.33 9.23z"
        fill="#324A5E"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default ThreeSixtyIcon
