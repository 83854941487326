type Values = Record<string, any>

export const pick = <T>(values?: T, keys?: (keyof T)[], fallbackValue: any = ""): Values => {
  const obj = {} as Values
  keys &&
    keys.forEach((key) => {
      obj[key as string] = values && values[key] != null ? values[key] : fallbackValue
    })
  return obj
}
