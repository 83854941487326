import React from "react"
import { NavLink, Switch, Route, Redirect } from "react-router-dom"

import { Container } from "Authenticated/components/containers"
import { Tabs, Tab } from "Authenticated/components/tabs"
import UserGroups from "../Groups/List"
import UserList from "./List"

const Users = () => {
  return (
    <Container title="Utilisateur·rice·s">
      <Tabs>
        <Tab path="/users/groups">
          <NavLink to="/users/groups">Groupe d'utilisateur·rice·s</NavLink>
        </Tab>
        <Tab path="/users/list">
          <NavLink to="/users/list">Liste d'utilisateur·rice·s</NavLink>
        </Tab>
      </Tabs>
      <Switch>
        <Route path="/users/groups" component={UserGroups} />
        <Route path="/users/list" component={UserList} />
        <Redirect to="/users/groups" />
      </Switch>
    </Container>
  )
}

export default Users
