import React from "react"
import { RouteComponentProps, Switch, Route, Redirect, Link } from "react-router-dom"
import styled from "styled-components"

import { Colors } from "helpers/constants"
import Menu from "Authenticated/components/Menu"
import Header from "Authenticated/components/Header"
import Home from "Authenticated/Home"
import { ModeProvider } from "Authenticated/components/mode"
import { TourProvider } from "Authenticated/components/tour"

import Users from "Authenticated/Users"
import EditGroup from "Authenticated/Groups/Edit"
import ImportGroup from "Authenticated/Groups/Import"
import Group from "Authenticated/Groups/View"
import EditUser from "Authenticated/Users/Edit"
import User from "Authenticated/Users/View"

import Modules from "Authenticated/Modules/List"
import EditModule from "Authenticated/Modules/Edit"
import EditStep from "Authenticated/Modules/EditStep"
import Module from "Authenticated/Modules/View"

import Resources from "Authenticated/Resources/List"
import NewResource from "Authenticated/Resources/New"
import Resource from "Authenticated/Resources/View"

import Stats from "Authenticated/Stats"

import Help from "Authenticated/Help"

import Profile from "Authenticated/Profile/View"
import EditProfile from "Authenticated/Profile/Edit"
import { useUser } from "components/user"
import ModulesUser from "./ModulesUser/ListModuleUser"

const Container = styled.div`
  background: ${Colors.background};
  min-height: 100vh;
  display: flex;
`
const Main = styled.div`
  flex: 1;
  overflow-x: hidden;
`
const Content = styled.div`
  padding: 40px 30px;
`
const Bottom = styled.div`
  text-align: center;
  font-size: 13px;
  margin: 30px;
`

const Authenticated: React.FC<RouteComponentProps> = () => {
  const { admin } = useUser()

  return (
    <ModeProvider>
      <TourProvider>
        <Container>
          <Menu />
          <Main>
            <Header />
            <Content>
              <Switch>
                {admin ? null : <Route exact path="/" component={Home} />}

                <Route path="/users/groups/new" component={EditGroup} />
                <Route path="/users/groups/import" component={ImportGroup} />
                <Route path="/users/groups/:id/edit" component={EditGroup} />
                <Route path="/users/groups/:id" component={Group} />
                <Route path="/users/groups" component={Users} />
                <Route path="/users/list" component={Users} />
                <Route path="/users/new" component={EditUser} />
                <Route path="/users/:id/edit" component={EditUser} />
                <Route path="/users/:id" component={User} />
                <Route path="/users" component={Users} />

                <Route path="/modules/new" component={EditModule} />
                <Route path="/modules/:id/edit" component={EditModule} />
                <Route path="/modules/:moduleId/steps/new" component={EditStep} />
                <Route path="/modules/:moduleId/steps/:stepId" component={EditStep} />
                <Route path="/modules/:id" component={Module} />
                <Route path="/modules" component={Modules} />

                <Route path="/my_modules" component={ModulesUser} />

                <Route path="/resources/new" component={NewResource} />
                <Route path="/resources/:id" component={Resource} />
                <Route path="/resources" component={Resources} />

                <Route path="/stats" component={Stats} />

                <Route path="/help" component={Help} />

                <Route path="/profile/edit" component={EditProfile} />
                <Route path="/profile" component={Profile} />

                <Redirect to={admin ? "/modules" : "/"} />
              </Switch>
            </Content>
            <Bottom>
              <Link to="/help">Besoin d’aide ?</Link>
              &nbsp; — &nbsp;
              <a href="/CGU_010923.pdf" target="_blank">
                Conditions Générales d’Utilisation
              </a>{" "}
              –{" "}
              <a href="/RGPD_290520.pdf" target="_blank">
                Traitement des données
              </a>
            </Bottom>
          </Main>
        </Container>
      </TourProvider>
    </ModeProvider>
  )
}

export default Authenticated
