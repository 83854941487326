import React, { useEffect } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import Authenticated from "./Authenticated"
import Anonymous from "./Anonymous"
import Tos from "Anonymous/Tos"
import AppTos from "Anonymous/AppTos"
import { bugsnagClient } from "helpers/bugsnag"
import api from "services/api"
import { useSession } from "components/user"
import { useToast } from "components/toast"

const App: React.FC = () => {
  const { session, setSession } = useSession()
  const toast = useToast()

  useEffect(() => {
    if (session && session.user) {
      api.get<IUser>(`users/${session.user.id}`).then((user) => {
        try {
          setSession({
            ...session,
            user,
          })
        } catch (error) {
          toast(error.message)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    bugsnagClient.user = session?.user as object
  }, [session])

  return (
    <Router>
      <Switch>
        <Route path="/tos" component={Tos} />
        <Route path="/cgu" component={AppTos} />
        <Route component={session ? Authenticated : Anonymous} />
      </Switch>
    </Router>
  )
}

export default App
