import React, { useState, useRef } from "react"
import { RouteComponentProps } from "react-router-dom"
import { AnyObject } from "final-form"

import { Container, Paper, Placeholder } from "Authenticated/components/containers"
import { Form, Title, Caption, CaptionRed, Row, TextInput, Select, Checkbox } from "components/form"
import api, { getRef } from "services/api"
import { useApi } from "helpers/hooks"
import { pick } from "helpers/object"
import { useToast } from "components/toast"
import { SubmitButton } from "components/buttons"

interface MatchParams {
  id: string
}

const EditModule: React.FC<RouteComponentProps<MatchParams>> = ({ match, history }) => {
  const { id } = match.params
  const { data, loading, error } = useApi<IModule>(id && `modules/${id}`)
  const categories = useApi<ICategory[]>(`categories`)
  const groups = useApi<IUserGroup[]>(`groupes`)
  const toast = useToast()
  const [submitting, setSubmitting] = useState(false)
  const alternate = useRef(false)

  const page = localStorage.getItem("paginationModule")
  const from = localStorage.getItem("from")
  const linkBack = page ? `/${from}?page=${page}` : `/${from}`

  if (loading || error) {
    return (
      <Container title="Modifier un module pédagogique" back={linkBack}>
        <Placeholder loading={loading} error={error} />
      </Container>
    )
  }

  const initialValues = pick(data, ["label", "category", "description", "visibility", "mail"])
  if (data) {
    initialValues.group = data.groupes && data.groupes[0]
    initialValues.category = data.category.id
    initialValues.isPublished = data.isPublished
    initialValues.isArchived = data.isArchived
  } else {
    initialValues.visibility = false
    initialValues.isPublished = false
    initialValues.isArchived = false
  }

  const handleAlternate = () => {
    alternate.current = true
  }

  const handleSubmit = async (values: AnyObject) => {
    const payload = {
      ...values,
      category: getRef("categories", values.category),
      groupes: values.group && [getRef("groupes", values.group)],
    }

    setSubmitting(true)

    if (id) {
      await api.put<IModule>(`modules/${id}`, payload)
      setSubmitting(false)
      history.push(`/modules/${id}`)
    } else {
      const module = await api.post<IModule>("modules", payload)
      setSubmitting(false)
      if (alternate.current) {
        history.push(`/modules/${module.id}`)
      } else {
        history.push(`/modules/${module.id}/steps/new`)
      }
    }

    toast(id ? "Le module a bien été modifié" : "Le module a bien été créé")
  }

  return (
    <Container
      title={id ? "Modifier un module pédagogique" : "Nouveau module pédagogique"}
      back={linkBack}
    >
      <Paper>
        <Form
          initialValues={initialValues}
          submitLabel="Enregistrer et créer une nouvelle étape"
          onSubmit={handleSubmit}
        >
          <Title>{id ? "Modifier" : "Ajouter"} le nom du module</Title>
          <Row>
            <TextInput required name="label" placeholder="Saisissez ici le nom du module" />
          </Row>
          <Title>Choisir une catégorie</Title>
          <Row>
            <Select
              required
              name="category"
              placeholder="Choisir une catégorie"
              loading={categories.loading}
              error={categories.error}
            >
              {categories.data &&
                categories.data.map(({ id, label }) => (
                  <option key={id} value={id}>
                    {label}
                  </option>
                ))}
            </Select>
          </Row>
          <Title>Ajouter une description au module</Title>
          <Row>
            <TextInput
              required
              multiline
              name="description"
              placeholder="Saisissez ici la description du module"
            />
          </Row>
          <Title>Définir les droits d’accès</Title>
          <Caption>
            Vous avez la possibilité de partager ce module avec l’ensemble des concepteur·rice·s
            utilisant la plateforme. vous avez aussi le choix de partager ce module avec des groupes
            d’utilisateur·rice·s. Ce paramètre peut être modifié à tout moment.
          </Caption>
          <Checkbox name="visibility">Partager avec les concepteur·rice·s</Checkbox>
          <CaptionRed>
            Veillez à partager avec les autres concepteurs des modules finalisés et non à l'état de
            brouillon afin d'éviter la multiplication de versions inutiles et donc les confusions.
          </CaptionRed>
          <Select
            name="group"
            placeholder="Partager avec un groupe d’élèves"
            loading={groups.loading}
            error={groups.error}
          >
            {groups.data &&
              groups.data.map(({ id, groupName, userCount }: IUserGroup) => (
                <option key={id} value={id}>
                  {groupName} ({userCount} utilisateur·rice·s)
                </option>
              ))}
          </Select>
          <Title>Ajouter une adresse e-mail de contact</Title>
          <Caption>
            Ajoutez une adresse e-mail de contact, cell-ci permettra aux élèves de vous contacter en
            cas de besoin.
          </Caption>
          <Row>
            <TextInput
              required
              name="mail"
              type="email"
              placeholder="Saisissez ici l’adresse e-mail"
            />
          </Row>
          <SubmitButton loading={submitting} variant="white" onClick={handleAlternate}>
            Enregistrer et accéder à l’écran de synthèse
          </SubmitButton>
        </Form>
      </Paper>
    </Container>
  )
}

export default EditModule
