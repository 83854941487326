import React from "react"
import { FieldProps, FieldRenderProps, useField } from "react-final-form"

import { FreeInput } from "components/form"

const FileField: React.FC<FieldProps<FileList, FieldRenderProps<FileList>, HTMLInputElement>> = ({
  name,
  ...props
}) => {
  const {
    input: { value, onChange, ...input },
  } = useField(name)

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = target
    onChange(files && files[0])
  }
  return <FreeInput {...input} type="file" onChange={handleChange} {...props} />
}

export default FileField
