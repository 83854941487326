import React from "react"

import { Colors } from "helpers/constants"

interface Props {
  bgColor?: string
}

const TextIcon: React.FC<Props> = ({ bgColor, ...props }) => (
  <svg viewBox="0 0 77 77" {...props}>
    <circle
      stroke="#FFFEFE"
      fill={bgColor || Colors.blue}
      fillRule="nonzero"
      cx="37.5"
      cy="37.5"
      r="37.5"
    />
    <g fillRule="nonzero" fill="none">
      <path
        d="M55.276 13.11H19.37c-.768 0-1.417.65-1.417 1.418v45.944c0 .768.65 1.418 1.417 1.418h35.906c.767 0 1.417-.65 1.417-1.418V14.528c0-.768-.65-1.418-1.417-1.418z"
        fill="#FFF"
      />
      <path
        fill="#E6E9EE"
        d="M40.69 18.602h9.566v1.772H40.69zM34.606 24.094h15.65v1.772h-15.65zM40.69 29.645h9.566v1.772H40.69zM37.795 35.137h12.461v1.772h-12.46zM34.134 40.63h16.122v1.772H34.134zM24.39 18.602h15.413v1.772H24.39zM24.39 24.094h9.331v1.772h-9.33zM24.39 29.645h15.413v1.772H24.39zM24.39 35.137h12.461v1.772h-12.46zM24.39 40.63h8.858v1.772H24.39z"
      />
      <path fill="#FF7058" d="M45.445 53.365l-7.474-7.475 15.868-15.868 7.474 7.475z" />
      <path fill="#E6E9EE" d="M61.297 37.51l-2.17 2.17-7.475-7.474 2.17-2.171z" />
      <path
        d="M61.3 37.5l3.188-3.189c.473-.472.473-1.24 0-1.772l-5.728-5.728c-.473-.472-1.24-.472-1.772 0L53.8 30l7.5 7.5z"
        fill="#F1543F"
      />
      <path fill="#FFD05B" d="M37.913 45.886l-2.716 10.216 10.216-2.716z" />
      <path fill="#324A5E" d="M36.083 52.795l-.886 3.307 3.307-.885z" />
    </g>
  </svg>
)

export default TextIcon
