import React from "react"
import styled from "styled-components"
import { useFieldArray } from "react-final-form-arrays"

import { Row, FreeSelect, Caption, FreeInput, Textarea } from "components/form"
import { Colors } from "helpers/constants"
import { Button } from "components/buttons"
import { ReactComponent as AddIcon } from "images/add.svg"
import SimpleMap from "Authenticated/components/Map"

const Container = styled.div``

const Subtitle = styled.h2`
  font-weight: 200;
  font-size: 1.25rem;
  color: ${Colors.blue};
  text-transform: uppercase;
`
const AddButton = styled(Button)`
  margin: 25px 0 15px;
  svg {
    vertical-align: -4px;
  }
`

interface Props {
  name: string
  objects: IFileResource[]
  images: IFileResource[]
  pictos: IFileResource[]
}

export interface Poi {
  lat: string
  lng: string
  label: string
  pictoId?: number
  is3DObject: boolean
  // for object:
  objectId?: number
  // for image + description:
  imageId?: number
  description?: string
}

const PoisField: React.FC<Props> = ({ name, objects, images, pictos }) => {
  const { fields } = useFieldArray<Poi>(name)

  const handleChange = (index: number, key: string) => ({
    target: { value },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    let newValue: string | boolean
    if (key === "is3DObject") {
      newValue = value === "true"
    } else {
      newValue = value
    }

    fields.update(index, {
      ...fields.value[index],
      [key]: newValue,
    })
  }

  const handleAdd = () => {
    fields.push({
      lat: "",
      lng: "",
      label: "",
      is3DObject: false,
    })
  }

  return (
    <>
      {fields.value &&
        fields.value.map(
          (
            { label, lat, lng, is3DObject, objectId, imageId, description, pictoId }: Poi,
            index
          ) => (
            <Container key={index}>
              <Subtitle>Point d’intérêt N°{index + 1}</Subtitle>
              <Row>
                <FreeInput
                  required
                  value={label}
                  onChange={handleChange(index, "label")}
                  placeholder="Saisissez ici le label du point d’intérêt"
                />
              </Row>
              <Caption>
                Le point d’intérêt à visualiser peut prendre la forme d’un texte descriptif ou d’un
                objet 3D. Utilisez le pavé texte suivant pour rédiger la description ou sélectionnez
                le fichier à partir de la bibliothèque des ressources pour ajouter un objet 3D.
              </Caption>
              <Row>
                <FreeSelect
                  required
                  value={is3DObject ? "true" : "false"}
                  onChange={handleChange(index, "is3DObject")}
                >
                  <option value="false">Description</option>
                  <option value="true">Objet 3D</option>
                </FreeSelect>
              </Row>
              <Row>
                {is3DObject ? (
                  <FreeSelect required value={objectId} onChange={handleChange(index, "objectId")}>
                    <option value="">Bibliothèque de ressources</option>
                    {objects.map(({ id, label }) => (
                      <option key={id} value={id}>
                        {label}
                      </option>
                    ))}
                  </FreeSelect>
                ) : (
                  <Textarea
                    rows={4}
                    value={description}
                    onChange={handleChange(index, "description")}
                    placeholder="Saisissez ici la description"
                  />
                )}
              </Row>
              {is3DObject ? null : (
                <Row>
                  <FreeSelect required value={imageId} onChange={handleChange(index, "imageId")}>
                    <option value="">Bibliothèque de ressources</option>
                    {images.map(({ id, label }) => (
                      <option key={id} value={id}>
                        {label}
                      </option>
                    ))}
                  </FreeSelect>
                </Row>
              )}
              <Caption>
                Sélectionnez depuis la bibliothèque des ressources un pictogramme qui servira de
                repère visuel au déclenchement du point d’intérêt.
              </Caption>
              <Row>
                <FreeSelect required value={pictoId} onChange={handleChange(index, "pictoId")}>
                  <option value="">Bibliothèque de ressources</option>
                  {pictos.map(({ id, label }) => (
                    <option key={id} value={id}>
                      {label}
                    </option>
                  ))}
                </FreeSelect>
              </Row>
              <Caption>
                Indiquez les coordonnées GPS du point d’intérêt afin de le situer dans l’espace par
                rapport aux coordonnées de référence, renseignées plus haut. L’application calculera
                automatiquement et fera apparaître la distance entre le lieu de référence et le
                point d’intérêt.
              </Caption>
              <Row>
                <FreeInput
                  required
                  type="number"
                  step="any"
                  value={lat}
                  onChange={handleChange(index, "lat")}
                  placeholder="Latitude (décimale)"
                />
                <FreeInput
                  required
                  type="number"
                  step="any"
                  value={lng}
                  onChange={handleChange(index, "lng")}
                  placeholder="Longitude (décimale)"
                />
              </Row>
              {lat && lng && <SimpleMap lat={Number(lat)} lng={Number(lng)} />}
            </Container>
          )
        )}
      <AddButton type="button" onClick={handleAdd}>
        <AddIcon />
        <span>Ajouter un POI</span>
      </AddButton>
    </>
  )
}

export default PoisField
