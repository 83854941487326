import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router-dom"

import { ReactComponent as StatsIcon } from "images/stats.svg"
import { ReactComponent as ConsultIcon } from "images/consult.svg"
import { ReactComponent as EditIcon } from "images/edit.svg"
import { ReactComponent as DeleteIcon } from "images/delete.svg"
import Table, { Controls, Actions } from "Authenticated/components/Table"
import { SearchFilter, SelectFilter } from "components/filterInputs"
import { IconLink, LinkButton, IconButton, MarginButton } from "components/buttons"
import Plus from "images/Plus"
import { Colors, ROLES } from "helpers/constants"
import { getUserLabel, useUser } from "components/user"
import { dateFormat } from "helpers/date"
import { fulltextFilter } from "helpers/string"
import { useApi } from "helpers/hooks"
import api from "services/api"
import { useToast } from "components/toast"

const USER_COLUMNS = [
  "Date",
  "Nom",
  "Adresse e-mail",
  "Utilisation de l’application",
  "Groupes",
  "",
]
const ADMIN_COLUMNS = ["Date", "Nom", "Adresse e-mail", "Rôle", "État", ""]

const STATES = [
  { id: "active", label: "Actif" },
  { id: "inactive", label: "Inactif" },
]

const UsersList: React.FC<RouteComponentProps> = ({ history, ...props }) => {
  const { data: users, loading, error, reload } = useApi<IUser[]>(`users`)
  const { data: groups, loading: loadingGroup } = useApi<IUserGroup[]>(`groupes`)
  const toast = useToast()

  const [search, setSearch] = useState("")
  const [group, setGroup] = useState<number>()
  const [role, setRole] = useState<IUserRole>()
  const [state, setState] = useState<string>()
  const [usersToActivate, setUsersToActivate] = useState<number[]>([])
  const { admin } = useUser()

  const queryParameters = new URLSearchParams(window.location.search)
  const page = queryParameters.get("page")

  useEffect(() => {
    localStorage.removeItem("paginationUser")
  }, [])

  const updateUrl = (page: string) => {
    history.push(`/users/list?page=${page}`)
  }

  const handleSearchChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(target.value)
  }

  const handleGroup = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    setGroup(Number(target.value))
  }

  const handleRole = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    setRole(target.value as IUserRole)
  }

  const handleState = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    setState(target.value)
  }

  const handleDelete = (id: number) => () => {
    if (window.confirm("Êtes-vous sûr·e de vouloir supprimer cet utilisateur·rice ?")) {
      api
        .delete(`users/${id}`)
        .then(() => {
          reload()
          toast("L’utilisateur·rice a bien été supprimé")
        })
        .catch((error) => {
          alert(`Erreur : ${error.message}`)
        })
    }
  }

  const handleUserToActivate = (event: React.ChangeEvent<HTMLInputElement>) => {
    let id = parseInt(event.target.value)
    if (event.target.checked) {
      let uta = [...usersToActivate]
      uta.push(id)
      setUsersToActivate(uta)
    } else {
      let uta = [...usersToActivate]
      let idx = uta.indexOf(id)
      uta.splice(idx, 1)
      setUsersToActivate(uta)
    }
  }
  const handleActivateUsers = () => {
    let count = 0
    usersToActivate.forEach((value, index) => {
      api.put<IUser>(`users/${value}`, { isActive: true }).then(() => {
        count++
        if (count === usersToActivate.length) {
          setUsersToActivate([])
          reload(true)
          toast("Les utilisateurs·rices ont bien été activés")
        }
      })
    })
  }

  const handleExportUsers = () => {
    api.getCsv("users/export").then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.style.display = "none"
      a.href = url
      // the filename you want
      a.download = "artefac_users.csv"
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      // or you know, something with better UX...
      toast("Les utilisateurs·rices ont bien été exportés")
    })
  }

  const userMapper = (user: IUser) => ({
    row: [
      dateFormat(user.createdAt),
      getUserLabel(user),
      user.mail,
      user.whyApp,
      user.groups.map(({ groupName }) => groupName).join(", "),
      <Actions>
        <IconLink to={`/stats/students/${user.id}`}>
          <StatsIcon fill={Colors.blue} />
        </IconLink>
      </Actions>,
    ],
    data: user,
  })

  const adminMapper = (user: IUser) => ({
    row: [
      dateFormat(user.createdAt),
      getUserLabel(user),
      user.mail,
      user.roles.map((role) => ROLES[role]).join(", "),
      user.isActive ? "Actif·ve" : "Inactif·ve",
      <Actions>
        <div className="checkbox-wrapper-65">
          <label>
            <input
              type="checkbox"
              onChange={handleUserToActivate}
              value={user.id}
              checked={usersToActivate.indexOf(user.id) !== -1 ? true : false}
            />
            <span className="cbx">
              <svg width="12px" height="11px" viewBox="0 0 12 11">
                <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
              </svg>
            </span>
          </label>
        </div>
      </Actions>,
      <Actions>
        <IconLink
          to={`/users/${user.id}`}
          onClick={() => {
            if (page) localStorage.setItem("paginationUser", page)
          }}
        >
          <ConsultIcon />
        </IconLink>
        <IconLink
          to={`/users/${user.id}/edit`}
          onClick={() => {
            if (page) localStorage.setItem("paginationUser", page)
          }}
        >
          <EditIcon />
        </IconLink>
        <IconButton onClick={handleDelete(user.id)}>
          <DeleteIcon />
        </IconButton>
      </Actions>,
    ],
    data: user,
  })
  const rows =
    users &&
    users
      .filter((data) => {
        if (search && !fulltextFilter(search, [data.firstname, data.lastname])) {
          return false
        }
        if (group && !(data as IUser).groups.find(({ id }) => id === group)) {
          return false
        }
        if (role && !(data as IUser).roles.includes(role)) {
          return false
        }
        if (state && (data as IUser).isActive !== (state === "active")) {
          return false
        }
        return true
      })
      .map(admin ? adminMapper : userMapper)

  return (
    <>
      <Controls>
        <div>
          <SearchFilter
            value={search}
            placeholder="Rechercher un·e utilisateur·rice"
            onChange={handleSearchChange}
          />
          {!admin && (
            <SelectFilter
              label="Filtrer par groupe"
              value={group}
              onChange={handleGroup}
              loading={loadingGroup}
              list={
                groups &&
                groups.map(({ id, groupName }) => ({
                  id,
                  label: groupName,
                }))
              }
            />
          )}
          {admin && (
            <SelectFilter
              label="Filtrer par rôle"
              value={role}
              onChange={handleRole}
              list={Object.keys(ROLES).map((role) => ({
                id: role,
                label: ROLES[role as IUserRole],
              }))}
            />
          )}
          {admin && (
            <SelectFilter
              label="Filtrer par état"
              value={state}
              onChange={handleState}
              list={STATES}
            />
          )}
        </div>
        {admin && (
          <LinkButton to="/users/new">
            <Plus backgroundColor={Colors.white} color={Colors.yellow} width={20} />
            <span>Ajouter un·e utilisateur·rice</span>
          </LinkButton>
        )}
      </Controls>
      <Controls>
        <div>
          {admin && <MarginButton onClick={handleActivateUsers}>Activer la sélection</MarginButton>}
          {admin && <MarginButton onClick={handleExportUsers}>Exporter les données</MarginButton>}
        </div>
      </Controls>
      <Table
        columns={admin ? ADMIN_COLUMNS : USER_COLUMNS}
        rows={rows}
        loading={loading}
        error={error}
        update={updateUrl}
        pageUrl={page}
      />
    </>
  )
}

export default UsersList
