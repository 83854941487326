import React from "react"
import bugsnag from "@bugsnag/js"
import bugsnagReact from "@bugsnag/plugin-react"

export const bugsnagClient = bugsnag({
  apiKey: "e26f0349545dabf71d25a335aaad8cd1",
  notifyReleaseStages: ["production"],
  appVersion: process.env.REACT_APP_REVISION,
})

bugsnagClient.use(bugsnagReact, React)

export const ErrorBoundary = bugsnagClient.getPlugin("react")
