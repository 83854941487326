import React, { Fragment } from "react"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"

import { isNumber } from "helpers/string"

const Container = styled.div`
  color: white;
  font-weight: 600;
`
const Place = styled.span`
  font-weight: normal;
`

const LABELS: Record<string, string> = {
  users: "Utilisateur·rice·s",
  groups_new: "Nouveau groupe d'utilisateur·rice·s",
  users_new: "Nouvel·le utilisateur·rice",
  modules: "Modules",
  my_modules: "Mes modules",
  modules_new: "Nouveau module",
  steps: "Étapes",
  steps_new: "Nouvelle étape",
  resources: "Ressources",
  resources_new: "Nouvelle ressource",
  stats: "Statistiques",
  alerts: "Alertes",
  help: "Aide",
  list: "Liste",
  groups: "Groupes",
  profile: "Profil",
  new: "Nouveau",
  edit: "Modifier",
  students: "Utilisateur·rice·s",
  teachers: "Concepteur·rice·s",
}

const Breadcrumbs = withRouter(({ location }) => {
  const parts = location.pathname.split("/")
  return (
    <Container>
      <Link to="/">Dashboard</Link>
      {parts.map((part: string, index: number) => {
        if (!part || isNumber(part)) {
          return null
        }
        const path = parts.slice(0, index + 1).join("/")
        const key = part === "new" ? parts[index - 1] + "_new" : part
        const label = LABELS[key] || part
        let content
        if (index === parts.length - 1) {
          content = <Place>{label}</Place>
        } else {
          content = <Link to={path}>{label}</Link>
        }
        return (
          <Fragment key={path}>
            {" > "}
            {content}
          </Fragment>
        )
      })}
    </Container>
  )
})

export default Breadcrumbs
