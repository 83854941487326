import React from "react"
import { RouteComponentProps } from "react-router-dom"

import { Container, Paper, Placeholder } from "Authenticated/components/containers"
import { LinkButton } from "components/buttons"
import { getUserLabel } from "components/user"
import { useApi } from "helpers/hooks"
import { Title, FreeInput } from "components/form"

interface MatchParams {
  id: string
}

const Group: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const { id } = match.params
  const { data, loading, error } = useApi<IUserGroup>(`groupes/${id}`)

  const page = localStorage.getItem("paginationGroups")
  const linkBack = page ? `/users/groups?page=${page}` : `/users/groups`

  if (!data) {
    return (
      <Container title="Visualiser un groupe d’utilisateur·rice·s" back={linkBack}>
        <Placeholder loading={loading} error={error} />
      </Container>
    )
  }

  return (
    <Container title="Visualiser un groupe d’utilisateur·rice·s" back={linkBack}>
      <Paper>
        <Title>Nom du groupe</Title>
        <FreeInput readOnly value={data.groupName} />
        <Title>Liste des utilisateur·rice·s</Title>
        {data.users.map((user) => (
          <FreeInput key={user.id} readOnly value={getUserLabel(user)} />
        ))}
        <Title>Liste des modules pédagogiques</Title>
        {data.modules.map(({ id, label }) => (
          <FreeInput key={id} readOnly value={label} />
        ))}
        <p>&nbsp;</p>
        <LinkButton to={`/users/groups/${id}/edit`}>Modifier</LinkButton>
      </Paper>
    </Container>
  )
}

export default Group
