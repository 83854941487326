import React from "react"
import { RouteComponentProps, Switch, Route, Redirect } from "react-router-dom"
import styled from "styled-components"

import { Colors } from "helpers/constants"
import Welcome from "Anonymous/Welcome"
import Login from "Anonymous/Login"
import Register from "Anonymous/Register"
import RegisterConfirmation from "Anonymous/RegisterConfirmation"
import Help from "Anonymous/Help"
import LostPassword from "Anonymous/LostPassword"
import PasswordCode from "Anonymous/PasswordCode"
import ResetPassword from "Anonymous/ResetPassword"

const Container = styled.div`
  background: ${Colors.background};
  min-height: 100vh;
`

const Anonymous: React.FC<RouteComponentProps> = () => {
  return (
    <Container>
      <Switch>
        <Route exact path="/" component={Welcome} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/confirmation" component={RegisterConfirmation} />
        <Route path="/help" component={Help} />
        <Route path="/password" component={LostPassword} />
        <Route path="/user/:id/reset_password/:token" component={ResetPassword} />
        <Route path="/code/:id" component={PasswordCode} />
        <Redirect to="/" />
      </Switch>
    </Container>
  )
}

export default Anonymous
