import React from "react"
import styled from "styled-components"
import { NavLink } from "react-router-dom"

import { ReactComponent as UsersIcon } from "images/users.svg"
import { ReactComponent as ModulesIcon } from "images/modules.svg"
import { ReactComponent as ResourcesIcon } from "images/resources.svg"
import { ReactComponent as StatsIcon } from "images/stats.svg"
import { ReactComponent as HelpIcon } from "images/help.svg"
import { ReactComponent as ProfileIcon } from "images/profile.svg"
import { ReactComponent as ShuffleIcon } from "images/shuffle.svg"
import { Colors, Shadows } from "helpers/constants"
import { SmallLogo } from "Anonymous/common"
import { MODES, Mode, useMode } from "Authenticated/components/mode"
import { IconButton } from "components/buttons"
import { useUser } from "components/user"

const Top = styled.div`
  text-align: center;
`
const Container = styled.div`
  width: 250px;
  flex-shrink: 0;
  padding: 25px;
  background-color: ${Colors.white};
  box-shadow: ${Shadows.menu};
  z-index: 1;
  display: flex;
  flex-direction: column;
`
const Entries = styled.ul`
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 10px 0;
`
const Entry = styled.li`
  list-style: none;
  margin: 25px -32px 25px -15px;
  a {
    padding: 8px 15px;
    display: block;
    color: ${Colors.grey};
    svg {
      vertical-align: middle;
      margin-right: 25px;
    }
  }
  a.active {
    background: ${Colors.background};
    box-shadow: ${Shadows.paper}, 0 1px 9px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color: ${Colors.blue};
    font-weight: 500;
    svg {
      fill: ${Colors.blue};
      g > circle {
        stroke: ${Colors.blue};
      }
      g > path {
        fill: ${Colors.blue};
      }
    }
  }
`
const Bottom = styled.div`
  margin-top: 40px;
  border-top: 1px solid ${Colors.greenBlue};
  padding: 20px 5px;
  text-align: left;
`
const ModeButton = styled(IconButton)`
  color: ${Colors.greenBlue};
  font-weight: 500;
  font-size: 1.2rem;
  svg {
    vertical-align: -9px;
    margin-right: 9px;
  }
`

const Menu: React.FC = () => {
  const { admin } = useUser()
  const { mode, setMode } = useMode()

  const handleMode = () => {
    setMode(mode === Mode.beginner ? Mode.advanced : Mode.beginner)
  }

  return (
    <Container>
      <Top>
        <NavLink to="/">
          <SmallLogo />
        </NavLink>
      </Top>
      <Entries>
        <Entry className="usersEntry">
          <NavLink to="/users">
            <UsersIcon />
            Utilisateur·rice·s
          </NavLink>
        </Entry>
        <Entry className="modulesEntry">
          <NavLink to="/modules">
            <ModulesIcon />
            Modules
          </NavLink>
        </Entry>
        <Entry className="modulesEntry">
          <NavLink to="/my_modules">
            <ModulesIcon />
            Mes modules
          </NavLink>
        </Entry>
        <Entry className="resourcesEntry">
          <NavLink to="/resources">
            <ResourcesIcon />
            Ressources
          </NavLink>
        </Entry>
        <Entry className="statsEntry">
          <NavLink to="/stats">
            <StatsIcon />
            Statistiques
          </NavLink>
        </Entry>
        <Entry className="helpEntry">
          <NavLink to="/help">
            <HelpIcon />
            Aide
          </NavLink>
        </Entry>
        <Entry className="profileEntry">
          <NavLink to="/profile">
            <ProfileIcon />
            Profil personnel
          </NavLink>
        </Entry>
      </Entries>
      {admin ? null : (
        <Bottom>
          <ModeButton onClick={handleMode}>
            <ShuffleIcon />
            {MODES[mode]}
          </ModeButton>
        </Bottom>
      )}
    </Container>
  )
}

export default Menu
