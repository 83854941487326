import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router-dom"

import Plus from "images/Plus"
import { ReactComponent as ConsultIcon } from "images/consult.svg"
import { ReactComponent as EditIcon } from "images/edit.svg"
import { ReactComponent as DeleteIcon } from "images/delete.svg"
import Table, { Controls, Actions } from "Authenticated/components/Table"
import { LinkButton, IconLink, IconButton } from "components/buttons"
import { SearchFilter } from "components/filterInputs"
import { Colors } from "helpers/constants"
import { fulltextFilter } from "helpers/string"
import { useApi } from "helpers/hooks"
import api from "services/api"
import { useToast } from "components/toast"
import { dateFormat } from "helpers/date"

const COLUMNS = [
  "Date",
  "Nom du groupe",
  "Accès modules",
  "Nombre d’utilisateur·rice·s",
  "Catégories",
  "",
]

const UsersGroup: React.FC<RouteComponentProps> = ({ history }) => {
  const { data: groups, loading, error, reload } = useApi<IUserGroup[]>(`groupes`)
  const toast = useToast()

  const [search, setSearch] = useState("")

  const queryParameters = new URLSearchParams(window.location.search)
  const page = queryParameters.get("page")

  useEffect(() => {
    localStorage.removeItem("paginationGroups")
  }, [])

  const updateUrl = (page: string) => {
    history.push(`/users/groups?page=${page}`)
  }

  const handleSearchChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(target.value)
  }

  const handleDelete = (id: number) => () => {
    if (window.confirm("Êtes-vous sûr·e de vouloir supprimer ce groupe ?")) {
      api
        .delete(`groupes/${id}`)
        .then(() => {
          reload()
          toast("Le groupe a bien été supprimé")
        })
        .catch((error) => {
          alert(`Erreur : ${error.message}`)
        })
    }
  }

  const rows =
    groups &&
    groups
      .filter(({ groupName }: IUserGroup) => {
        if (search && !fulltextFilter(search, [groupName])) {
          return false
        }
        return true
      })
      .map((group: IUserGroup) => ({
        row: [
          dateFormat(group.createdAt),
          group.groupName,
          group.moduleCount,
          group.userCount,
          group.categories.map(({ label }) => label).join(", "),
          <Actions>
            <IconLink
              to={`/users/groups/${group.id}`}
              onClick={() => {
                if (page) localStorage.setItem("paginationGroups", page)
              }}
            >
              <ConsultIcon />
            </IconLink>
            <IconLink
              to={`/users/groups/${group.id}/edit`}
              onClick={() => {
                if (page) localStorage.setItem("paginationGroups", page)
              }}
            >
              <EditIcon />
            </IconLink>
            <IconButton onClick={handleDelete(group.id)}>
              <DeleteIcon />
            </IconButton>
          </Actions>,
        ],
        data: group,
      }))

  return (
    <>
      <Controls>
        <SearchFilter
          value={search}
          placeholder="Rechercher un groupe"
          onChange={handleSearchChange}
        />
        <LinkButton to="/users/groups/import" className="importGroup">
          <Plus backgroundColor={Colors.white} color={Colors.yellow} width={20} />
          <span>Importer des groupes</span>
        </LinkButton>
        <LinkButton to="/users/groups/new" className="addGroup">
          <Plus backgroundColor={Colors.white} color={Colors.yellow} width={20} />
          <span>Ajouter un groupe</span>
        </LinkButton>
      </Controls>
      <Table
        columns={COLUMNS}
        rows={rows}
        loading={loading}
        error={error}
        update={updateUrl}
        pageUrl={page}
      />
    </>
  )
}

export default UsersGroup
