import React, { useContext, useState, useEffect, useRef, useCallback } from "react"

import { useUser } from "components/user"
import api, { getRef } from "services/api"
import { bugsnagClient } from "helpers/bugsnag"

export enum Mode {
  beginner = "beginner",
  advanced = "advanced",
}

export const MODES = {
  beginner: "Mode débutant·e",
  advanced: "Mode avancé·e",
}

const VISIT_NOTIFY_FREQUENCY = 30000 // every 30 seconds

interface IModeContext {
  mode: Mode
  setMode: (mode: Mode) => void
  ping: () => void
}

const ModeContext = React.createContext<IModeContext>({} as IModeContext)

const getStoredMode = (): Mode => {
  return (localStorage.getItem("mode") || Mode.beginner) as Mode
}

const storeMode = (mode: Mode) => {
  localStorage.setItem("mode", mode)
}

interface IVisit {
  start: Date
  id: number
}

export const ModeProvider: React.FC = ({ children }) => {
  const [mode, setMode] = useState<Mode>(getStoredMode)
  const { user, admin } = useUser()
  const [visit, setVisit] = useState<IVisit>()
  const timer = useRef<number>()

  const setterWrapper = (mode: Mode) => {
    setMode(mode)
    storeMode(mode)
  }

  const userMode = admin ? Mode.advanced : mode

  // for usage statistics:

  const ping = useCallback(() => {
    const type = userMode === Mode.beginner ? "TYPE_BEGINNER" : "TYPE_ADVANCED"
    if (visit) {
      api
        .put(`sessions/${visit.id}`, {
          time: Math.round((Date.now() - visit.start.getTime()) / 1000),
          type,
        })
        .catch(bugsnagClient.notify)
    } else if (user) {
      const start = new Date()
      api
        .post("sessions", {
          date: start.toISOString(),
          time: 0,
          type,
          user: getRef("users", user.id),
        })
        .then((res) => {
          setVisit({
            start,
            id: res.id,
          })
        })
        .catch(bugsnagClient.notify)
    }
  }, [visit, user, userMode])

  useEffect(ping, [userMode])

  useEffect(() => {
    timer.current = setInterval(ping, VISIT_NOTIFY_FREQUENCY)
    return () => {
      clearInterval(timer.current)
    }
  }, [ping, userMode])

  return (
    <ModeContext.Provider value={{ mode: userMode, setMode: setterWrapper, ping }}>
      {children}
    </ModeContext.Provider>
  )
}

export const useMode = () => useContext(ModeContext)
