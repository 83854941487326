import React from "react"
import { RouteComponentProps } from "react-router-dom"

import { PageContainer, CloseButton, Content, SmallLogo, Title, Text } from "Anonymous/common"

const RegisterConfirmation: React.FC<RouteComponentProps> = () => {
  return (
    <PageContainer>
      <CloseButton />
      <Content>
        <SmallLogo />
        <Title>Votre demande de création de compte a bien été transmise</Title>
        <Text>
          Merci ! Votre demande de création de compte a bien été enregistrée. Celle-ci va faire
          l’objet d’une validation par notre équipe. Un e-mail de confirmation vous sera alors
          envoyé.
        </Text>
      </Content>
    </PageContainer>
  )
}

export default RegisterConfirmation
