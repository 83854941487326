import React from "react"
import styled from "styled-components"
import { useFieldArray } from "react-final-form-arrays"

import { Row, Space, FreeSelect, Caption } from "components/form"
import { Colors } from "helpers/constants"
import { Button } from "components/buttons"
import { ReactComponent as AddIcon } from "images/add.svg"

const Container = styled.div``

const Subtitle = styled.h2`
  font-weight: 200;
  font-size: 1.25rem;
  color: ${Colors.blue};
  text-transform: uppercase;
`
const AddButton = styled(Button)`
  margin: 25px 0 15px;
  svg {
    vertical-align: -4px;
  }
`

interface Props {
  name: string
  objects: IFileResource[]
  tags: IFileResource[]
}

export interface Tag {
  objectId: number
  tagId: number
  horizontal: boolean
}

const TagsField: React.FC<Props> = ({ name, objects, tags }) => {
  const { fields } = useFieldArray<Tag>(name)

  const handleChange = (index: number, key: string) => ({
    target: { value },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    fields.update(index, {
      ...fields.value[index],
      [key]: key === "horizontal" ? value === "true" : value,
    })
  }

  const handleAdd = () => {
    fields.push({
      objectId: 0,
      tagId: 0,
      horizontal: false,
    })
  }

  return (
    <>
      {fields.value &&
        fields.value.map(({ objectId, tagId, horizontal }: Tag, index) => (
          <Container key={index}>
            <Subtitle>Objet 3D N°{index + 1}</Subtitle>
            <Caption>
              Sélectionnez un objet 3D depuis depuis la bibliothèque des ressources.
            </Caption>
            <Row>
              <FreeSelect value={objectId} onChange={handleChange(index, "objectId")}>
                <option value="">Bibliothèque de ressources</option>
                {objects.map(({ id, label }) => (
                  <option key={id} value={id}>
                    {label}
                  </option>
                ))}
              </FreeSelect>
              <Space />
            </Row>
            <Caption>
              Puis sélectionnez de la même manière un tag visuel (forme, dessin ou image) qui lui
              sera associé pour le faire apparaître par déclenchement.
            </Caption>
            <Row>
              <FreeSelect value={tagId} onChange={handleChange(index, "tagId")}>
                <option value="">Bibliothèque de ressources</option>
                {tags.map(({ id, label }) => (
                  <option key={id} value={id}>
                    {label}
                  </option>
                ))}
              </FreeSelect>
              <FreeSelect
                value={horizontal ? "true" : "false"}
                onChange={handleChange(index, "horizontal")}
              >
                <option value="false">Usage du tag à la verticale</option>
                <option value="true">Usage du tag à l’horizontale</option>
              </FreeSelect>
            </Row>
          </Container>
        ))}
      <AddButton type="button" onClick={handleAdd}>
        <AddIcon />
        <span>Ajouter un objet 3D via tag</span>
      </AddButton>
    </>
  )
}

export default TagsField
