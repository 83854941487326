import React from "react"
import { RouteComponentProps } from "react-router-dom"
import styled from "styled-components"

import { Container, Paper, Placeholder } from "Authenticated/components/containers"
import { useApi } from "helpers/hooks"
import { Title } from "components/form"
import api from "services/api"
import { Button } from "components/buttons"
import { useToast } from "components/toast"
import ResourceFile from "Authenticated/components/Resource"

const Content = styled.div`
  margin-bottom: 30px;
`

interface MatchParams {
  id: string
}

const Resource: React.FC<RouteComponentProps<MatchParams>> = ({ match, history }) => {
  const { id } = match.params
  const { data, loading, error } = useApi<IResource>(`resources/${id}`)
  const toast = useToast()

  const page = localStorage.getItem("paginationResource")
  const linkBack = page ? `/resources?page=${page}` : `/resources`

  if (!data) {
    return (
      <Container title="Visualiser une ressource" back={linkBack}>
        <Placeholder loading={loading} error={error} />
      </Container>
    )
  }

  const handleDelete = () => {
    let message = "Êtes-vous sûr·e de vouloir supprimer cette ressource ?"
    if (data.hasStats) {
      message += "\nAttention : cela effacera aussi ses statistiques d’utilisation !"
    }
    if (window.confirm(message)) {
      api
        .delete(`resources/${id}`)
        .then(() => {
          history.replace(linkBack)
          toast("La ressource a bien été supprimée")
        })
        .catch((error) => {
          alert(`Erreur : ${error.message}`)
        })
    }
  }

  return (
    <Container title="Visualiser une ressource" back={linkBack}>
      <Paper>
        <Title>{data.label}</Title>
        <Content>
          <ResourceFile data={data} />
        </Content>
        {(data.isDeleteable == null ? true : data.isDeleteable) && (
          <Button variant="delete" onClick={handleDelete}>
            Supprimer
          </Button>
        )}
      </Paper>
    </Container>
  )
}

export default Resource
