import React from "react"
import styled from "styled-components"
import {
  LineChart as Chart,
  XAxis,
  ResponsiveContainer,
  YAxis,
  Line,
  CartesianGrid,
  Tooltip,
  LineChartProps,
} from "recharts"

import { Colors } from "helpers/constants"

const PERCENT_LABELS: Record<number, string> = {
  0: "Pas visualisé",
  50: "Visualisé à moitié",
  100: "Visualisé dans son ensemble",
}
const percentFormatter = (value: number) => PERCENT_LABELS[value]

interface Props {
  data: LineChartProps["data"]
  percent?: boolean
}

const StyledChart = styled(Chart)`
  font-size: 14px;
`

const LineChart: React.FC<Props> = ({ data, percent }) => (
  <ResponsiveContainer>
    <StyledChart
      data={data}
      margin={{ top: percent ? 40 : 30, bottom: 50, right: 10, left: percent ? 20 : 0 }}
    >
      <CartesianGrid stroke={Colors.lightGrey} />
      <XAxis dataKey="name" stroke={Colors.grey} tickMargin={15} />
      <YAxis
        stroke={Colors.grey}
        ticks={percent ? [0, 50, 100] : undefined}
        tickFormatter={percent ? percentFormatter : undefined}
        tickMargin={15}
      />
      <Line type="monotone" dataKey="primary" stroke={Colors.blue} strokeWidth={2} connectNulls />
      <Line
        type="monotone"
        dataKey="secondary"
        stroke={Colors.yellow}
        strokeWidth={2}
        connectNulls
      />
      <Tooltip separator="" formatter={(value) => [value + (percent ? " %" : "")]} />
    </StyledChart>
  </ResponsiveContainer>
)

export default LineChart
