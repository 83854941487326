import React, { useEffect } from "react"

import { Placeholder } from "Authenticated/components/containers"
import { useApi } from "helpers/hooks"

import {
  Container,
  Row,
  Cell,
  Content,
  Title,
  Value,
  Indicator,
  Label,
  Widget,
  Subtitle,
} from "Authenticated/components/Widget"
import { ReactComponent as UsersIcon } from "images/users.svg"
import LineChart from "Authenticated/components/LineChart"
import { pluralize } from "helpers/string"

interface IStepStats {
  step: IStep
  progression: number // 0..1
}
interface IModuleStats {
  startedModulePerStudent: number // number of views
  timePassedPerStudent: number // seconds
  progressionPerStudent: number // 0..1
  quizzStarted: number // number of quiz
  goodResponses: number // number of correct quiz answers
  badResponses: number // number of incorrect quiz answers
  progressionPerStepPerStudent: IStepStats[]
}
interface IStudentModuleStats extends IModuleStats {
  nbForLastConsultation: number
}

interface Props {
  id: number
  userId?: number
  setParticipants: (num?: number) => void
}

const ModuleStats: React.FC<Props> = ({ id, userId, setParticipants }) => {
  const average = useApi<IModuleStats>(`stats_modules/${id}`)
  const student = useApi<IStudentModuleStats>(
    userId ? `stats_modules/${id}?student=${userId}` : undefined
  )

  useEffect(() => {
    setParticipants(student.data ? student.data.nbForLastConsultation : undefined)
  }, [student.data, setParticipants])

  if (!average.data || (userId && !student.data)) {
    return (
      <Placeholder
        loading={average.loading || student.loading}
        error={average.error || student.error}
      />
    )
  }

  const primary = student.data || average.data
  const secondary = student.data && average.data

  const chartData = average.data.progressionPerStepPerStudent.map(
    ({ step, progression }, index) => {
      const studentValue = student.data?.progressionPerStepPerStudent.find(
        (data) => data.step.id === step.id
      )
      let primary
      let secondary
      if (student.data) {
        if (studentValue) {
          primary = Math.round(studentValue.progression * 100)
        }
        secondary = Math.round(progression * 100)
      } else {
        primary = Math.round(progression * 100)
      }

      return {
        name: `Étape ${index + 1}`,
        primary,
        secondary,
      }
    }
  )

  const primaryViews = Math.round(primary.startedModulePerStudent)
  const secondaryViews = secondary && Math.round(secondary.startedModulePerStudent)
  const primaryMinutes = Math.round(primary.timePassedPerStudent / 60)
  const secondaryMinutes = secondary && Math.round(secondary.timePassedPerStudent / 60)

  return (
    <Container>
      <Row>
        <Widget
          title="Nombre de consultations par module"
          subtitle={
            secondary && `${secondaryViews} ${pluralize("consultation", secondaryViews)} en moyenne`
          }
          value={primaryViews}
          label={pluralize("Consultation", primaryViews)}
        />
        <Widget
          title="Temps passé sur le module par l'étudiant·e"
          subtitle={
            secondary && `${secondaryMinutes} ${pluralize("minute", secondaryMinutes)} en moyenne`
          }
          value={primaryMinutes}
          label={pluralize("Minute", primaryMinutes)}
        />
        <Widget
          title="Progression de l’étudiant·e dans le module"
          subtitle={
            secondary && `${Math.round(secondary.progressionPerStudent * 100)} % en moyenne`
          }
          value={Math.round(primary.progressionPerStudent * 100)}
          label="%"
        />
      </Row>
      <Row>
        <Widget
          big
          title="Progression de l’étudiant·e dans les médias"
          subtitle={secondary && "Moyenne des utilisateur·rice·s"}
        >
          <LineChart data={chartData} percent />
        </Widget>
        <Cell>
          <Content>
            <Title>Résultats de l’étudiant·e au quiz</Title>
            {secondary && (
              <Subtitle>
                <UsersIcon />
                {secondary.goodResponses}{" "}
                {pluralize("réponse", secondary.goodResponses)}{" "}
                {pluralize("juste", secondary.goodResponses)}{" "}
                et{" "}
                {secondary.badResponses}{" "}
                {pluralize("réponse", secondary.badResponses)}{" "}
                {pluralize("fausse", secondary.badResponses)}{" "}
                en moyenne
              </Subtitle>
            )}
            <Value>
              <Indicator>{primary.goodResponses}</Indicator>
              <Label>
                {pluralize("Réponse", primary.goodResponses)}{" "}
                {pluralize("juste", primary.goodResponses)}
              </Label>
              <Indicator>{primary.badResponses}</Indicator>
              <Label>
                {pluralize("Réponse", primary.badResponses)}{" "}
                {pluralize("fausse", primary.badResponses)}
              </Label>
            </Value>
          </Content>
          <Content>
            <Title>Résultats de l’étudiant·e au quiz</Title>
            {secondary && (
              <Subtitle>
                <UsersIcon />
                {secondary.quizzStarted} quiz commencés en moyenne
              </Subtitle>
            )}
            <Value>
              <Indicator>{primary.quizzStarted}</Indicator>
              Quiz
            </Value>
          </Content>
        </Cell>
      </Row>
    </Container>
  )
}

export default ModuleStats
