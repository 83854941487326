import React from "react"
import { RouteComponentProps } from "react-router-dom"
import { AnyObject } from "final-form"

import { Container, Paper } from "Authenticated/components/containers"
import { PROFILES } from "helpers/constants"
import { useUser, useSession } from "components/user"
import { Form, Title, Row, TextInput, Select } from "components/form"
import { pick } from "helpers/object"
import api from "services/api"
import { useToast } from "components/toast"

const Profile: React.FC<RouteComponentProps> = ({ history }) => {
  const toast = useToast()
  const { setSession } = useSession()
  const session = useUser()
  const { user } = session
  const initialValues = pick(user, ["firstname", "lastname", "mail", "profile", "school", "whyApp"])

  const handleSubmit = async (values: AnyObject) => {
    if (values.password && values.password !== values.confirmPassword) {
      throw new Error("Les mots de passe ne correspondent pas")
    }

    const newUser = await api.put<IUser>(`users/${user.id}`, values)

    if (values.mail !== user.mail) {
      let { password } = values
      if (!password) {
        password = prompt(
          "Vous avez changé votre adresse e-mail : veuillez taper votre mot de passe pour rester connecté·e"
        )
      }
      try {
        const newSession = await api.post<ISession>("login_check", {
          mail: values.mail,
          password,
        })
        setSession(newSession)
      } catch (error) {
        setSession(null)
        history.push("/login")
        if (error.code === 401) {
          toast(
            "Votre profil a bien été modifié, mais vous avez été déconnecté·e pour mot de passe incorrect"
          )
        } else {
          alert(`Erreur : ${error.message}`)
        }
        return
      }
    } else {
      setSession({
        ...session,
        user: newUser,
      })
    }

    history.push("/profile")
    toast("Votre profil a bien été modifié")
  }

  return (
    <Container title="Profil personnel" back="/profile">
      <Paper>
        <Form
          initialValues={initialValues}
          submitLabel="Modifier"
          submitMessage="Profil modifié"
          onSubmit={handleSubmit}
        >
          <Title>Vos informations personnelles</Title>
          <Row>
            <TextInput required name="lastname" placeholder="Nom" />
            <TextInput required name="firstname" placeholder="Prénom" />
          </Row>
          <Row>
            <TextInput required type="email" name="mail" placeholder="Adresse e-mail" />
          </Row>
          <Row>
            <TextInput
              type="password"
              name="password"
              placeholder="Créer un nouveau mot de passe"
            />
            <TextInput
              type="password"
              name="confirmPassword"
              placeholder="Confirmation du mot de passe"
            />
          </Row>
          <Row>
            <Select required name="profile" placeholder="Choisissez un profil">
              {Object.keys(PROFILES).map((key) => (
                <option key={key} value={key}>
                  {PROFILES[key as IUserProfile]}
                </option>
              ))}
            </Select>
            <TextInput name="school" placeholder="Nom de l’établissement scolaire" />
          </Row>
          <Row>
            <TextInput
              multiline
              name="whyApp"
              placeholder="Pourquoi souhaitez-vous avoir votre espace concepteur·rice ?"
            />
          </Row>
        </Form>
      </Paper>
    </Container>
  )
}

export default Profile
