import React from "react"
import { RouteComponentProps } from "react-router-dom"

import { Paper } from "Authenticated/components/containers"
import { Title, Subtitle } from "./components"

const HelpUsers: React.FC<RouteComponentProps> = () => {
  return (
    <Paper>
      <Title>Groupes d’utilisateur·rice·s</Title>
      <ul>
        <li>Vous avez accès à la liste de vos groupes utilisateur·rice·s.</li>
        <li>
          Vous pouvez rechercher un groupe utilisateur·rice·s en saisissant le nom du groupe dans la
          barre de recherche prévue à cet effet.
        </li>
      </ul>
      <Subtitle>Visualiser un groupe</Subtitle>
      <p>
        Pour visualiser un groupe utilisateur·rice·s, cliquez sur l’icône{" "}
        <strong>bleue “Document”</strong> associée au groupe de votre choix. Vous avez l’aperçu du
        détail : le nom du groupe, la liste des utilisateur·rice·s associés à ce groupe ainsi que
        les modules auxquels ils ont accès.
      </p>
      <p>
        Vous pouvez cliquer sur le bouton <strong>“Modifier”</strong> pour modifier ce groupe ou
        cliquer sur le lien <strong>“Retour”</strong> situé en haut à droite de votre écran pour
        revenir à la liste.
      </p>
      <Subtitle>Modifier un groupe</Subtitle>
      <p>
        Pour modifier un groupe, cliquez sur l’icône noire “Crayon” associée au groupe de votre
        choix. Vous pouvez modifier l’ensemble des données :
      </p>
      <ul>
        <li>Modifier le nom du groupe,</li>
        <li>
          Supprimer les utilisateur·rice·s en cliquant sur l’icône rouge <strong>“Poubelle”</strong>
          ,
        </li>
        <li>
          Ajouter un·e utilisateur·rice en saisissant son Prénom ou Nom de famille et en
          sélectionnant son profil dans la liste déroulante,
        </li>
        <li>
          Supprimer les modules associés à ce groupe en cliquant sur l’icône rouge{" "}
          <strong>“Poubelle”</strong>,
        </li>
        <li>
          Ajouter un module en saisissant le titre du module et en le sélectionnant dans la liste
          déroulante.
        </li>
      </ul>
      <p>
        Cliquez ensuite sur le bouton <strong>“Enregistrer”</strong> pour modifier les données ou
        cliquez sur le lien <strong>“Retour”</strong> situé en haut à droite de votre écran pour
        revenir à la liste.
      </p>
      <Subtitle>Supprimer un groupe</Subtitle>
      <p>
        Pour supprimer un groupe, cliquez sur l’icône rouge <strong>“Poubelle”</strong>, associée au
        groupe de votre choix. Une fenêtre Pop-Up de confirmation va s’afficher : cliquez ensuite
        sur <strong>“OK”</strong> pour supprimer le groupe ou sur <strong>“Annuler”</strong> pour le
        conserver.
      </p>
      <Subtitle>Ajouter un groupe</Subtitle>
      <p>
        Pour ajouter un groupe, cliquez sur le bouton <strong>“Ajouter un groupe”</strong>.
        Complétez ensuite les informations demandées :
      </p>
      <ul>
        <li>Le nom du groupe,</li>
        <li>
          Ajouter un ou plusieurs utilisateur·rice·s en saisissant leur Prénom ou Nom de famille et
          en sélectionnant leur profil dans la liste déroulante,
        </li>
        <li>
          Ajouter un ou plusieurs modules pédagogiques en saisissant le titre des modules et en les
          sélectionnant dans la liste déroulante.
        </li>
      </ul>
      <p>
        Cliquez ensuite sur le bouton <strong>“Enregistrer”</strong> pour créer votre groupe ou
        cliquez sur le lien <strong>“Retour”</strong> situé en haut à droite de votre écran pour
        revenir à la liste.
      </p>
      <Title>Liste des utilisateur·rice·s</Title>
      <ul>
        <li>Vous pouvez gérer la liste des utilisateur·rice·s,</li>
        <li>
          Vous pouvez rechercher un·e utilisateur·rice en saisissant son Prénom ou son Nom de
          famille dans la barre de recherche prévue à cet effet,
        </li>
        <li>Vous pouvez filtrer les utilisateur·rice·s par groupe.</li>
      </ul>
    </Paper>
  )
}

export default HelpUsers
