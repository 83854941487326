import React from "react"
import styled from "styled-components"

import { Paper } from "Authenticated/components/containers"
import { Colors } from "helpers/constants"
import { ReactComponent as UsersIcon } from "images/users.svg"

export const Container = styled.div``

export const Row = styled.div`
  display: flex;
  margin: 15px -15px -15px;
`
export const Cell = styled.div<{ big?: boolean }>`
  flex: ${(props) => (props.big ? 2 : 1)};
`
export const Content = styled(Paper)<{ big?: boolean }>`
  margin: 15px;
  height: ${(props) => (props.big ? 390 : 180)}px;
  :not(:first-of-type) {
    margin-top: 30px;
  }
`

export const Title = styled.h2``
export const Subtitle = styled.div`
  color: ${Colors.yellow};
  margin: -10px 0;
  font-size: 0.85em;
  svg {
    fill: ${Colors.yellow};
    width: 20px;
    height: 20px;
    vertical-align: -4px;
    margin-right: 8px;
  }
`
export const Value = styled.p`
  color: ${Colors.blue};
  text-align: center;
  font-size: 1.5rem;
  font-weight: 200;
  white-space: nowrap;
`
export const Indicator = styled.span`
  font-size: 2em;
  vertical-align: -5px;
  font-weight: 500;
  margin: 0 8px;
`
export const Label = styled.span`
  width: 70px;
  text-align: left;
  display: inline-block;
  vertical-align: -4px;
  font-size: 1rem;
  white-space: normal;
`

interface WidgetProps {
  big?: boolean
  title: string
  subtitle?: string
  value?: number
  label?: string
}

export const Widget: React.FC<WidgetProps> = ({ children, big, title, subtitle, value, label }) => (
  <Cell big={big}>
    <Content big={big}>
      <Title>{title}</Title>
      {subtitle && (
        <Subtitle>
          <UsersIcon />
          {subtitle}
        </Subtitle>
      )}
      {value != null && (
        <Value>
          <Indicator>{value}</Indicator>
          {label}
        </Value>
      )}
      {children}
    </Content>
  </Cell>
)

export const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
  span {
    flex: 1 1 50%;
    color: ${Colors.blue};
    margin: 3px 0;
  }
  img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin-right: 8px;
  }
`
