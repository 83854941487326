import React from "react"
import styled from "styled-components"
import { Link, RouteComponentProps } from "react-router-dom"
import { AnyObject } from "final-form"

import { SidesContainer, Left, Logo, Title, Right, Help, Actions, Paper } from "Anonymous/common"
import { Form, Row, TextInput } from "components/form"
import { LinkButton } from "components/buttons"
import api from "services/api"
import { useToast } from "components/toast"

export const Narrow = styled.div`
  margin: 0 auto 20px;
  max-width: 335px;
`
export const GoBack = styled.div`
  text-align: right;
  font-size: 12px;
`

export const Disclaimer = styled.p`
  text-align: left;
  font-size: 15px;
`

interface LostPwdResponse {
  id: number
}

const LostPassword: React.FC<RouteComponentProps> = ({ history }) => {
  const toast = useToast()

  const handleSubmit = async (values: AnyObject) => {
    try {
      const response = await api.post<LostPwdResponse>("user_reset_passwords", values)
      toast("Un email vous a été envoyé afin de redéfinir votre mot de passe")
      history.push(`/code/${response.id}`)
    } catch (error) {
      toast(error.message)
    }
  }

  return (
    <SidesContainer>
      <Left>
        <Logo />
        <Title>Bienvenue dans l’environnement numérique de conception pédagogique ARTEfac.</Title>
        <Actions>
          <LinkButton to="/register">Pas encore de compte ? Créer mon compte</LinkButton>
        </Actions>
      </Left>
      <Right>
        <Paper>
          <Narrow>
            <Form submitLabel="Valider" onSubmit={handleSubmit}>
              <Title>
                Vous avez oublié votre <br />
                mot de passe ?
              </Title>
              <Disclaimer>
                Saisissez ci-dessous l'adresse e-mail utilisée lors de votre création de compte. Un
                lien va vous être envoyé pour réinitialiser votre mot de passe.
              </Disclaimer>
              <Row>
                <TextInput
                  required
                  type="email"
                  name="mail"
                  placeholder="Saisissez votre adresse e-mail"
                />
              </Row>
            </Form>
            <GoBack>
              <Link to="/login">Vous avez déjà un compte? Cliquez ici pour vous connecter</Link>
            </GoBack>
          </Narrow>
        </Paper>
        <Help to="/help">Besoin d’aide ?</Help>
      </Right>
    </SidesContainer>
  )
}

export default LostPassword
