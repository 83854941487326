import React, { useState } from "react"
import styled from "styled-components"
import { NavLink, Switch, Route, Redirect, RouteComponentProps } from "react-router-dom"

import { Container } from "Authenticated/components/containers"
import { Tabs, Tab } from "Authenticated/components/tabs"
import { SelectFilter } from "components/filterInputs"
import { getUserLabel, useUser } from "components/user"
import { useApi } from "helpers/hooks"
import api from "services/api"

import ModuleStats from "./Module"
import GlobalStats from "./Global"
import AdminStats from "./Admin"
import { Button } from "components/buttons"

import { useToast } from "components/toast"

const Filters = styled.div`
  display: flex;
  margin: -15px;
`

const ExportButtonContainer = styled.div`
  display: flex;
  align-items: center;
`

const Filter = styled(SelectFilter)`
  width: auto;
  flex: 1;
  margin: 15px;
`
const Placeholder = styled.div`
  padding: 10px;
  flex: 1;
  margin: 15px;
`

const sortByFirstName = (left: IUser, right: IUser) =>
  getUserLabel(left).toLowerCase() > getUserLabel(right).toLowerCase() ? 1 : -1

const sortByModuleName = (left: IModule, right: IModule) =>
  left.label.toLowerCase() > right.label.toLowerCase() ? 1 : -1

const Stats: React.FC<RouteComponentProps> = ({ location, history }) => {
  const { admin } = useUser()
  const toast = useToast()
  const { data: users, loading: loadingUsers } = useApi<IUser[]>(`users`)
  const { data: modules, loading: loadingModules } = useApi<IModule[]>(`modules_light`)
  const [moduleId, setModule] = useState<number>()

  const pathparts = location.pathname.split("/")
  const userType = pathparts[2]
  const userId = pathparts[3] ? Number(pathparts[3]) : undefined

  const role: IUserRole = userType === "students" ? "ROLE_STUDENT" : "ROLE_TEACHER"

  const [participants, setParticipants] = useState<number>()
  const filteredUsers = users && users.filter(({ roles }) => roles.includes(role))
  const label = role === "ROLE_STUDENT" ? "utilisateur·rice" : "concepteur·rice"

  let code: string | undefined
  if (userId && moduleId && role === "ROLE_STUDENT") {
    if (participants) {
      const num = participants > 1 ? `en groupe (${participants} personnes)` : "seul·e"
      code = `Dernière consultation ${num}`
    }
  } else if (!userId && filteredUsers) {
    code = `${filteredUsers.length} ${label}·s`
  }

  const handleUser = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    history.replace(`/stats/${userType}/${target.value}`)
  }

  const handleModule = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    setModule(Number(target.value))
  }

  const handleExportStat = () => {
    api.getCsv(`stats_modules/${moduleId}/export`).then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.style.display = "none"
      a.href = url
      // the filename you want
      a.download = `statistique_module_${moduleId}.csv`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      // or you know, something with better UX...
      toast("Les données ont bien été exportés")
    })
  }

  return (
    <Container title={userId ? "Statistiques individuelles" : "Statistiques globales"} code={code}>
      {admin && (
        <Tabs>
          <Tab path="/stats/teachers">
            <NavLink to="/stats/teachers">Concepteur·rice·s</NavLink>
          </Tab>
          <Tab path="/stats/students">
            <NavLink to="/stats/students">Utilisateur·rice·s</NavLink>
          </Tab>
        </Tabs>
      )}
      <Filters>
        <Filter
          label={`Filtrer par ${label}`}
          value={userId}
          onChange={handleUser}
          loading={loadingUsers}
          list={
            filteredUsers &&
            filteredUsers.sort(sortByFirstName).map((user) => ({
              id: user.id,
              label: getUserLabel(user),
            }))
          }
          className="statsSearch"
        />
        <Filter
          label="Filtrer par module"
          value={moduleId}
          onChange={handleModule}
          loading={loadingModules}
          list={modules && modules.filter(({ hasStats }) => hasStats).sort(sortByModuleName)}
        />
        <Placeholder />
        {admin && moduleId ? (
          <ExportButtonContainer>
            <Button onClick={handleExportStat}>Exporter les données</Button>
          </ExportButtonContainer>
        ) : (
          <></>
        )}
      </Filters>
      <Switch>
        {admin && (
          <Route
            path="/stats/teachers"
            render={() => <AdminStats userId={userId} moduleId={moduleId} />}
          />
        )}
        <Route
          path="/stats/students"
          render={() =>
            moduleId ? (
              <ModuleStats id={moduleId} userId={userId} setParticipants={setParticipants} />
            ) : (
              <GlobalStats userId={userId} />
            )
          }
        />
        <Redirect to="/stats/students" />
      </Switch>
    </Container>
  )
}

export default Stats
