import React, { useEffect, useState } from "react"
import { Link, RouteComponentProps } from "react-router-dom"
import { AnyObject, FORM_ERROR } from "final-form"

import Loader from "images/Loader"
import { SidesContainer, Left, Logo, Title, Right, Help, Actions, Paper } from "Anonymous/common"
import { Form, Row, TextInput } from "components/form"
import { LinkButton } from "components/buttons"
import api from "services/api"
import { ErrorMessage } from "Authenticated/components/containers"
import { useToast } from "components/toast"
import { Disclaimer, GoBack, Narrow } from "./LostPassword"

interface ResetPasswordProps {
  id: string
  token: string
}

const ResetPassword: React.FC<RouteComponentProps<ResetPasswordProps>> = ({ match, history }) => {
  const { id, token } = match.params
  const toast = useToast()
  const [valid, setValid] = useState(true)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    api
      .put(`user_reset_passwords/${id}`, {
        token,
      })
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setValid(false)
      })
  })

  const handleSubmit = async (values: AnyObject) => {
    const { password, confirm } = values
    if (password !== confirm) {
      return { [FORM_ERROR]: `Les deux mots de passe ne sont pas identiques` }
    }

    try {
      await api.put(`user_reset_passwords/${id}`, {
        plainPassword: password,
        token,
      })
      toast("Votre mot de passe a été mis à jour")
      history.push(`/login`)
    } catch (error) {
      toast(error.message)
    }
  }

  return (
    <SidesContainer>
      <Left>
        <Logo />
        <Title>Bienvenue dans l’environnement numérique de conception pédagogique ARTEfac.</Title>
        <Actions>
          <LinkButton to="/register">Pas encore de compte ? Créer mon compte</LinkButton>
        </Actions>
      </Left>
      <Right>
        <Paper>
          <Narrow>
            {loading ? (
              <Narrow>
                <Loader color="blue" />
              </Narrow>
            ) : valid ? (
              <Form submitLabel="Valider" onSubmit={handleSubmit}>
                <Title>
                  Vous avez oublié votre <br />
                  mot de passe ?
                </Title>
                <Disclaimer>
                  Saisissez ci-dessous votre nouveau mot de passe. Celui-ci vous permettra d'accéder
                  à votre espace utilisateur.
                </Disclaimer>
                <Row>
                  <TextInput
                    required
                    type="password"
                    name="password"
                    placeholder="Créer un nouveau mot de passe"
                  />
                </Row>
                <Row>
                  <TextInput
                    required
                    type="password"
                    name="confirm"
                    placeholder="Confirmez le nouveau mot de passe"
                  />
                </Row>
              </Form>
            ) : (
              <ErrorMessage>Votre lien a expiré...</ErrorMessage>
            )}

            <GoBack>
              <Link to="/login">Vous avez déjà un compte? Cliquez ici pour vous connecter</Link>
            </GoBack>
          </Narrow>
        </Paper>
        <Help to="/help">Besoin d’aide ?</Help>
      </Right>
    </SidesContainer>
  )
}

export default ResetPassword
