import React from "react"
import { Link, RouteComponentProps } from "react-router-dom"
import { AnyObject } from "final-form"

import { SidesContainer, Left, Logo, Title, Right, Help, Actions, Paper } from "Anonymous/common"
import { Form, Row, TextInput } from "components/form"
import { LinkButton } from "components/buttons"
import api from "services/api"
import { useToast } from "components/toast"
import { Disclaimer, GoBack, Narrow } from "./LostPassword"

interface PasswordCodeProps {
  id: string
}

const PasswordCode: React.FC<RouteComponentProps<PasswordCodeProps>> = ({ match, history }) => {
  const { id } = match.params
  const toast = useToast()

  const handleSubmit = async (values: AnyObject) => {
    try {
      await api.put<IAuthResponse>(`user_reset_passwords/${id}`, {
        token: values.token,
      })
      history.push(`/user/${id}/reset_password/${values.token}`)
    } catch (error) {
      toast(error.message)
    }
  }

  return (
    <SidesContainer>
      <Left>
        <Logo />
        <Title>Bienvenue dans l’environnement numérique de conception pédagogique ARTEfac.</Title>
        <Actions>
          <LinkButton to="/register">Pas encore de compte ? Créer mon compte</LinkButton>
        </Actions>
      </Left>
      <Right>
        <Paper>
          <Narrow>
            <Form submitLabel="Valider" onSubmit={handleSubmit}>
              <Title>
                Vous avez oublié votre <br />
                mot de passe ?
              </Title>
              <Disclaimer>
                Saisissez ci-dessous le code unique mentionné dans l’e-mail que nous vous avons
                adressé pour réinitialiser votre mot de passe.
              </Disclaimer>
              <Row>
                <TextInput
                  required
                  type="text"
                  name="token"
                  placeholder="Saisissez le code unique"
                />
              </Row>
            </Form>
            <GoBack>
              <Link to="/login">Vous avez déjà un compte? Cliquez ici pour vous connecter</Link>
            </GoBack>
          </Narrow>
        </Paper>
        <Help to="/help">Besoin d’aide ?</Help>
      </Right>
    </SidesContainer>
  )
}

export default PasswordCode
