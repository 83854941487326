import React from "react"

import { Placeholder } from "Authenticated/components/containers"
import { useApi } from "helpers/hooks"

import {
  Container,
  Row,
  Cell,
  Content,
  Title,
  Value,
  Indicator,
  Label,
  Widget,
  Subtitle,
} from "Authenticated/components/Widget"
import { ReactComponent as UsersIcon } from "images/users.svg"
import LineChart from "Authenticated/components/LineChart"
import { MONTHS } from "helpers/constants"
import { pluralize } from "helpers/string"

interface IMonthStats {
  year: string // 20xx
  month: string // month (1..12)
  value: string // number of modules viewed
}
interface IGlobalStats {
  minutesPassedPerModulePerStudent: number // seconds!
  progressionPerModulePerStudent: number // 0..1
  quizzStarted: number // number of quiz
  goodResponses: number // number of correct quiz answers
  badResponses: number // number of incorrect quiz answers
  moduleViewedPerStudentPerMonth: IMonthStats[]
}

interface Props {
  userId?: number
}

const GlobalStats: React.FC<Props> = ({ userId }) => {
  const average = useApi<IGlobalStats>(`stats_teachers/get`)
  const student = useApi<IGlobalStats>(userId ? `stats_teachers/get?student=${userId}` : undefined)

  if (!average.data || (userId && !student.data)) {
    return (
      <Placeholder
        loading={average.loading || student.loading}
        error={average.error || student.error}
      />
    )
  }

  const primary = student.data || average.data
  const secondary = student.data && average.data

  const chartData = average.data.moduleViewedPerStudentPerMonth.map(({ year, month, value }) => {
    const studentValue = student.data?.moduleViewedPerStudentPerMonth.find(
      (data) => data.year === year && data.month === month
    )
    let primary
    let secondary
    if (student.data) {
      if (studentValue) {
        primary = Number(studentValue.value)
      }
      secondary = Number(value)
    } else {
      primary = Number(value)
    }

    return {
      name: MONTHS[month] +" "+ year,
      primary,
      secondary,
    }
  })

  const suffix = secondary ? "" : "pour tou·te·s les étudiant·e·s"

  const primaryMinutes = Math.round(primary.minutesPassedPerModulePerStudent / 60)
  const secondaryMinutes = secondary && Math.round(secondary.minutesPassedPerModulePerStudent / 60)

  return (
    <Container>
      <Row>
        <Widget
          title={`Temps moyen passé par module ${suffix}`}
          subtitle={
            secondary && `${secondaryMinutes} ${pluralize("minute", secondaryMinutes)} en moyenne`
          }
          value={primaryMinutes}
          label={pluralize("Minute", primaryMinutes)}
        />
        <Widget
          title={`Progression moyenne dans un module ${suffix}`}
          subtitle={
            secondary &&
            `${Math.round(secondary.progressionPerModulePerStudent * 100)} % en moyenne`
          }
          value={Math.round(primary.progressionPerModulePerStudent * 100)}
          label="%"
        />
        <Widget
          title={`Nombre de quiz commencés en moyenne ${suffix}`}
          subtitle={
            secondary &&
            `${secondary.quizzStarted} quiz ${pluralize(
              "commencé",
              secondary.quizzStarted
            )} en moyenne`
          }
          value={primary.quizzStarted}
          label="Quiz"
        />
      </Row>
      <Row>
        <Widget
          big
          title={`Nombre de modules consultés ${suffix}`}
          subtitle={secondary && "Moyenne des utilisateur·rice·s"}
        >
          <LineChart data={chartData} />
        </Widget>
        <Cell>
          <Content>
            <Title>Résultats aux quiz cumulés {suffix}</Title>
            {secondary && (
              <Subtitle>
                <UsersIcon />
                {secondary.goodResponses}{" "}
                {pluralize("réponse", secondary.goodResponses)}{" "}
                {pluralize("juste", secondary.goodResponses)}{" "}
                et{" "}
                {secondary.badResponses}{" "}
                {pluralize("réponse", secondary.badResponses)}{" "}
                {pluralize("fausse", secondary.badResponses)}{" "}
                en moyenne
              </Subtitle>
            )}
            <Value>
              <Indicator>{primary.goodResponses}</Indicator>
              <Label>
                {pluralize("Réponse", primary.goodResponses)}{" "}
                {pluralize("juste", primary.goodResponses)}
              </Label>
              <Indicator>{primary.badResponses}</Indicator>
              <Label>
                {pluralize("Réponse", primary.badResponses)}{" "}
                {pluralize("fausse", primary.badResponses)}
              </Label>
            </Value>
          </Content>
        </Cell>
      </Row>
    </Container>
  )
}

export default GlobalStats
