import React from "react"
import { RouteComponentProps } from "react-router-dom"
import { AnyObject } from "final-form"

import { Container, Paper, Placeholder } from "Authenticated/components/containers"
import { Form, Title, Caption, Row, TextInput } from "components/form"
import ListField, { Item } from "components/form/ListField"
import api, { getRef } from "services/api"
import { getUserLabel } from "components/user"
import { useApi } from "helpers/hooks"
import { pick } from "helpers/object"
import { useToast } from "components/toast"
import FileField from "components/form/FileField"

const userMapper = (user: IUser): Item => ({
  id: user.id,
  name: getUserLabel(user),
})
const moduleMapper = ({ id, label }: IModule): Item => ({ id, name: label })

const ImportGroup: React.FC<RouteComponentProps> = ({ history }) => {
  const toast = useToast()

  const page = localStorage.getItem("paginationGroups")
  const linkBack = page ? `/users/groups?page=${page}` : `/users/groups`

  const handleSubmit = async (values: AnyObject) => {
    const file: File = values.file
    const payload = new FormData()
    payload.append("file", file)
    await api.post("groupes/import", payload)
    history.push(linkBack)
    toast("Les groupes ont bien été importés.")
  }

  return (
    <Container title={"Importer des groupes d’utilisateur·rice·s"} back={linkBack}>
      <Paper>
        <Form submitLabel="Importer" onSubmit={handleSubmit}>
          <Title>Choisisser le fichier a importer</Title>
          <FileField required name="file" />
          <Caption>
            Les règles suivantes doivent etre respectées afin que l'import s'execute correctement:
          </Caption>
          <Caption>· Le fichier doit être au format CSV.</Caption>
          <Caption>
            · Le lignes du fichier doivent suivre la structure suivante: Nom du groupe,nom,prenom
          </Caption>
          <Caption>
            · Si le fichier contient une ligne de titre pour les colonnes , celles-ci doivent etre:
            Nom du groupe,nom,prenom
          </Caption>

          <Caption>· Le séparateur doit etre une virgule.</Caption>
          <Caption>· Les utilisateurs listés dans le fichier doivent être déjà existants.</Caption>
        </Form>
      </Paper>
    </Container>
  )
}

export default ImportGroup
