import React from "react"
import { RouteComponentProps } from "react-router-dom"

import { Paper } from "Authenticated/components/containers"
import { Title } from "./components"

const HelpMisc: React.FC<RouteComponentProps> = () => {
  return (
    <Paper>
      <Title>Profil personnel</Title>
      <p>
        Vous avez accès à l’ensemble de vos informations depuis l’onglet{" "}
        <strong>“Profil personnel”</strong>. Vous avez la possibilité de modifier ces informations
        en cliquant sur le bouton <strong>“Modifier”</strong>, vous pouvez ensuite modifier
        l’ensemble des champs.
      </p>
      <p>
        Cliquez ensuite sur le bouton <strong>“Modifier”</strong> pour enregistrer vos modifications
        ou cliquez sur le lien <strong>“Retour”</strong> situé en haut à droite de votre écran pour
        revenir à la liste.
      </p>
    </Paper>
  )
}

export default HelpMisc
