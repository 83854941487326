import React from "react"

import { Colors } from "helpers/constants"

interface Props {
  bgColor?: string
}

const ArIcon: React.FC<Props> = ({ bgColor, ...props }) => (
  <svg viewBox="0 0 77 77" {...props}>
    <circle
      stroke="#FFFEFE"
      fill={bgColor || Colors.blue}
      fillRule="nonzero"
      cx="37.5"
      cy="37.5"
      r="37.5"
    />
    <g fillRule="nonzero" fill="none">
      <path
        d="M36.206 16.114l16.648 9.67-16.648 9.67-16.65-9.67 16.65-9.67zm-17.459 11.08l16.65 9.672-.002 19.78-16.646-9.677-.002-19.775zm34.916 19.782l-16.65 9.67.002-19.78 16.648-9.671v19.781z"
        fill="#324A5E"
      />
      <path
        d="M66.785 19.96l-4.208 2.444C57.287 13.77 47.8 8 37 8c-10.802 0-20.288 5.77-25.577 14.404L7.215 19.96a.807.807 0 0 0-1.106.298.817.817 0 0 0 .296 1.113l4.204 2.442A30.157 30.157 0 0 0 6.98 38.2c0 16.38 13.03 29.755 29.21 30.189v3.976c0 .45.362.814.81.814.447 0 .81-.364.81-.814v-3.976C53.988 67.957 67.02 54.581 67.02 38.2a30.156 30.156 0 0 0-3.63-14.387l4.204-2.442a.817.817 0 0 0 .296-1.113.807.807 0 0 0-1.106-.298zM37 66.77c-15.66 0-28.402-12.816-28.402-28.57S21.34 9.63 37 9.63C52.66 9.63 65.4 22.445 65.4 38.2S52.661 66.77 37 66.77z"
        fill="#FF5B61"
      />
      <path
        d="M67.91 58.66a.667.667 0 0 1-.591.34H42.282a2.29 2.29 0 0 1-.678-.102A2.265 2.265 0 0 1 40 56.742V46.444c0-.245.201-.444.448-.444h26.87c.358 0 .533.238.591.34a.652.652 0 0 1-.008.677l-2.727 4.498a1.894 1.894 0 0 0 0 1.97l2.727 4.498a.653.653 0 0 1 .008.676z"
        stroke="#FBB900"
        fill="#FBB900"
      />
      <text
        fontFamily="Klavika-Regular, Klavika"
        fontSize="11"
        fill="#1D1D1B"
        transform="translate(40 46)"
      >
        <tspan x="6.603" y="10">
          AR
        </tspan>
      </text>
    </g>
  </svg>
)

export default ArIcon
