import styled from "styled-components"

export const H1 = styled.h1`
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: 30px;
`
export const H2 = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 20px;
  margin-top: 30px;
`
