import React from "react"
import styled, { css } from "styled-components"
import { Link, withRouter } from "react-router-dom"

import { ReactComponent as SvgLogo } from "images/logo.svg"
import { Colors } from "helpers/constants"
import { ReactComponent as CloseIcon } from "images/close.svg"

export const SidesContainer = styled.div`
  min-height: 100vh;
  display: flex;
`
export const Left = styled.div`
  flex: 1;
  padding-top: 80px;
  text-align: center;
  background: ${Colors.white};
`
export const Logo = styled(SvgLogo)`
  width: 120px;
  height: 155px;
  margin-bottom: 30px;
`
export const SmallLogo = styled(SvgLogo)`
  width: 80px;
  height: 105px;
`
export const Title = styled.h1`
  max-width: 380px;
  margin: 1em auto 25px;
  text-align: center;
`
export const Text = styled.p`
  font-size: 1.2rem;
  margin-bottom: 50px;
`
export const FormattedText = styled(Text)`
  white-space: pre-line;
`
export const Right = styled.div`
  flex: 1;
  position: relative;
  padding-bottom: 40px; /* space for help link */
`
export const paper = css`
  border-radius: 5px;
  box-shadow: 2px 4px 15px 0 rgba(0, 0, 0, 0.1);
`
export const Paper = styled.div`
  margin: 80px 44px;
  background: ${Colors.white};
  text-align: center;
  padding: 20px 40px;
  ${paper}
`
export const Help = styled(Link)`
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
`
export const Actions = styled.div`
  margin-top: 50px;
`
export const PageContainer = styled.div`
  padding-top: 15vh;
  padding-bottom: 40px;
`
export const Content = styled.div`
  background: white;
  flex: 1;
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  padding: 30px 100px;
  ${paper}
`

const TopRightButton = styled.button`
  position: absolute;
  right: 30px;
  top: 30px;
`
export const CloseButton = withRouter(({ history }) => {
  const handleClick = () => {
    history.goBack()
  }
  return (
    <TopRightButton type="button" onClick={handleClick}>
      <CloseIcon />
    </TopRightButton>
  )
})
