export const fulltextFilter = (text: string, attributes: string[]) => {
  text = text.trim()
  if (!text) {
    return false
  }
  return attributes.some((attribute) => attribute.toLowerCase().includes(text.trim().toLowerCase()))
}

export const isNumber = (input: string) => !isNaN(+input)

export const getFileExt = (filename: string): string | null => {
  const matches = filename.match(/\.([a-zA-Z0-9]{2,4})$/i)
  return matches && matches[1].toLowerCase()
}

export const pluralize = (text: string, value?: number | string) =>
  `${text}${Number(value) > 1 ? "s" : ""}`
