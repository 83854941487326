import React from "react"
import { RouteComponentProps } from "react-router-dom"

import { Paper } from "Authenticated/components/containers"
import { Title } from "./components"

const HelpAlerts: React.FC<RouteComponentProps> = () => {
  return (
    <Paper>
      <Title>Alertes</Title>
      <p>
        Des alertes sont envoyées et affichées dans le Back Office. Elles sont visibles depuis
        l’icône <strong>“Cloche”</strong> située dans le menu supérieur de l’écran. Vous recevrez
        des alertes personnalisées lorsqu’un·e utilisateur·rice complétera un module dont il est
        l’auteur et lorsqu’un concepteur partagera l’un de ses modules.
      </p>
      <p>
        Le nombre de nouvelles alertes est affiché sur l'icône <strong>“Cloche”</strong> : vous
        pouvez cliquer sur une alerte pour être redirigé directement sur le module en question.
      </p>
    </Paper>
  )
}

export default HelpAlerts
