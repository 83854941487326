import React from "react"
import { RouteComponentProps } from "react-router-dom"

import { getUserLabel, useUser } from "components/user"
import { Container, Paper } from "Authenticated/components/containers"
import { UserIcon, UserName, Content, Field, Label, Value } from "Authenticated/components/user"
import { LinkButton } from "components/buttons"
import { PROFILES } from "helpers/constants"

const Profile: React.FC<RouteComponentProps> = () => {
  const { user } = useUser()

  return (
    <Container title="Profil personnel">
      <Paper center>
        <UserIcon />
        <UserName>{getUserLabel(user)}</UserName>
        <Content>
          <Field>
            <Label>Votre adresse e-mail</Label>
            <Value>{user.mail}</Value>
          </Field>
          <Field>
            <Label>Votre profil</Label>
            <Value>{PROFILES[user.profile] || user.profile}</Value>
          </Field>
          <Field>
            <Label>Votre établissement</Label>
            <Value>{user.school}</Value>
          </Field>
          <Field fullwidth>
            <Label>Utilisation de la plateforme ARTEfac</Label>
            <Value>{user.whyApp || "-"}</Value>
          </Field>
        </Content>
        <LinkButton to="/profile/edit">Modifier</LinkButton>
      </Paper>
    </Container>
  )
}

export default Profile
