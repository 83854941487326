import React, { ReactNode } from "react"
import styled, { css } from "styled-components"
import { Link } from "react-router-dom"

import { Colors, Shadows } from "helpers/constants"
import Loader from "images/Loader"

const buttonCss = css<{ variant?: string }>`
  padding: ${(props) => (props.variant === "white" ? 14 : 15)}px 50px;
  border-radius: 5px;
  box-shadow: ${Shadows.paper};
  background-color: ${(props) => Colors[props.variant || "button"]};
  color: ${(props) => (props.variant === "white" ? Colors.blue : Colors.white)};
  border: ${(props) => (props.variant === "white" ? `1px solid ${Colors.blue}` : "none")};
  svg:not(:last-child) {
    margin: 0 10px 0 -10px;
  }
`

export const Button = styled.button`
  ${buttonCss}
`
export const LinkButton = styled(Link)`
  ${buttonCss}
  display: inline-flex;
`
export const ExternalLinkButton = styled.a`
  ${buttonCss}
  display: inline-flex;
`

export const IconButton = styled.button`
  position: relative;
  svg {
    vertical-align: middle;
  }
`
export const IconLink = styled(Link)`
  svg {
    vertical-align: middle;
  }
`

const CenteredButton = styled(Button)`
  display: block;
  margin: 20px auto 10px;
  min-width: 335px;
`
export const MarginButton = styled(Button)`
  margin-right: 20px;
`

interface SubmitProps {
  children: ReactNode
  loading?: boolean
  variant?: string
  onClick?: React.DOMAttributes<HTMLButtonElement>["onClick"]
}

export const SubmitButton: React.FC<SubmitProps> = ({ children, loading, ...props }) => (
  <CenteredButton type="submit" disabled={loading} {...props}>
    {loading ? <Loader color={props.variant === "white" ? Colors.blue : Colors.white} /> : children}
  </CenteredButton>
)
