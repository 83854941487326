import React, { useContext, useState } from "react"

let token: string | null = null

export const getToken = (): string | null => token

export const getStoredSession = (): ISession | null => {
  const data = localStorage.getItem("session")

  if (data) {
    const session: ISession = JSON.parse(data)
    token = session.token
    return session
  }

  return null
}

export const storeSession = (session: ISession | null): void => {
  if (session) {
    token = session.token
    localStorage.setItem("session", JSON.stringify(session))
  } else {
    token = null
    localStorage.removeItem("session")
  }
}

interface ISessionContext {
  session: ISession | null
  setSession: (session: ISession | null) => void
}

const SessionContext = React.createContext<ISessionContext>({} as ISessionContext)

export const useSession = () => useContext(SessionContext) as ISessionContext

export const useUser = () => useContext(SessionContext).session as ISession

export const SessionProvider: React.FC = ({ children }) => {
  const [session, setSession] = useState<ISession | null>(getStoredSession)

  const setterWrapper = (session: ISession | null) => {
    let error
    if (session) {
      const { isActive, roles } = session.user
      if (!isActive) {
        error = "Votre compte est désactivé"
      }
      session.admin = roles.includes("ROLE_ADMIN")
      if (!session.admin && !roles.includes("ROLE_TEACHER")) {
        error = "Cet espace est réservé aux enseignant·e·s et administrateur·rice·s"
      }
      if (error) {
        session = null
      }
    }
    storeSession(session)
    setSession(session)
    if (error) {
      throw new Error(error)
    }
  }

  return (
    <SessionContext.Provider value={{ session, setSession: setterWrapper }}>
      {children}
    </SessionContext.Provider>
  )
}

export const getUserLabel = (user?: IUser | IAuthor, fallback = ""): string =>
  user ? `${user.firstname} ${user.lastname}` : fallback
