import React, { useState, useRef, RefObject, useEffect } from "react"
import styled from "styled-components"
import { useLocation, useHistory } from "react-router-dom"
import useOnclickOutside from "react-cool-onclickoutside"
import { ReactComponent as Notif } from "images/notif.svg"
import { IconButton } from "components/buttons"
import { useApi } from "helpers/hooks"
import { Colors, NOTIFICATIONS } from "helpers/constants"
import { dateFormat } from "helpers/date"
import api from "services/api"

const NotifIcon = styled(Notif)`
  margin-left: 30px;
`
const Oval = styled.div`
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.blue};
  color: ${Colors.blue};
  border-radius: 20px;
  background-color: ${Colors.white};
  font-size: 11px;
  position: absolute;
  bottom: -6px;
  right: -8px;
`

const Container = styled.div``
const NotifContainer = styled.div`
  position: absolute;
  top: 50px;
  z-index: 9;
  width: 250px;
  background: ${Colors.white};
  border-radius: 4px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  left: -100px;
  text-align: left;
`
const NotifEntry = styled.div`
  padding: 16px;
  border-bottom: 1px solid #e8e5e5;

  &:hover {
    background-color: #efefef;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`
const NotifDate = styled.div`
  color: ${Colors.blue};
  font-size: 11px;
`
const NotifBody = styled.div`
  font-size: 13px;
`
const Strong = styled.span`
  font-weight: 600;
`

const getBody = (notif: INotification) => {
  const definition = NOTIFICATIONS[notif.entityType]
  return (
    <div>
      <Strong>{definition.title}</Strong> : {definition[notif.notificationType]}
    </div>
  )
}

const getRoute = (notif: INotification) =>
  notif.entityType === "user" ? `/users/${notif.entityId}` : `/modules/${notif.entityId}`

const Notifications: React.FC = () => {
  const [open, setOpen] = useState(false)
  const { data, reload } = useApi<INotification[]>(`notifications`)
  const notifsRef = useRef() as RefObject<HTMLDivElement>
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    reload()
  }, [location, reload])

  useOnclickOutside(notifsRef, () => {
    if (open) {
      setOpen(false)
      api.delete("notifications").then(reload)
    }
  })

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleNotifClick = (notif: INotification) => () => {
    api.delete("notifications").then(reload)
    history.push(getRoute(notif))
  }

  return (
    <Container ref={notifsRef}>
      <IconButton onClick={handleOpen}>
        <NotifIcon />
        {data && !open && data.length > 0 && <Oval>{data.length}</Oval>}
        {open && (
          <NotifContainer>
            {data?.map((notif, key) => (
              <NotifEntry key={key} onClick={handleNotifClick(notif)}>
                <NotifDate>{dateFormat(notif.date)}</NotifDate>
                <NotifBody>{getBody(notif)}</NotifBody>
              </NotifEntry>
            ))}
          </NotifContainer>
        )}
      </IconButton>
    </Container>
  )
}

export default Notifications
