import React from "react"
import { RouteComponentProps } from "react-router-dom"

import { Paper } from "Authenticated/components/containers"
import { Title, Warning, Subtitle } from "./components"

const HelpModules: React.FC<RouteComponentProps> = () => {
  return (
    <Paper>
      <Title>Consulter les étapes d’un module</Title>
      <p>
        Pour consulter les étapes d’un module, cliquez sur l’icône <strong>bleue “Document”</strong>{" "}
        associée au module de votre choix. Vous avez l’aperçu des étapes du module, pour faire
        défiler ces étapes cliquez sur les flèches prévues à cet effet.
      </p>
      <p>
        <strong>Vous pouvez : </strong>
      </p>
      <ul>
        <li>
          Cliquer sur le bouton <strong>“Modifier”</strong> pour modifier l’étape d’un module,{" "}
        </li>
        <li>
          Cliquer sur le bouton <strong>“Supprimer”</strong> pour supprimer l’étape d’un module,
        </li>
        <li>
          Cliquer sur le bouton <strong>“Nouvelle étape”</strong> pour ajouter une nouvelle étape au
          module,
        </li>
        <li>
          Cliquer sur <strong>“Publier le module”</strong> ou <strong>“Dépublier le module”</strong>{" "}
          pour le rendre visible pour les utilisateur·rice·s avec qui le module est partagé
          (Concepteur·rice·s, groupes d’utilisateur·rice·s),
        </li>
        <li>
          Cliquer sur le lien <strong>“Retour”</strong> situé en haut à droite de votre écran pour
          revenir à la liste.
        </li>
      </ul>
      <Title>Modifier un module</Title>
      <p>
        Pour modifier un module, cliquez sur l’icône noire “Crayon” associée au module de votre
        choix.
      </p>
      <p>
        <strong>Vous pouvez :</strong>
      </p>
      <ul>
        <li>
          <strong>Modifier</strong> le nom du module,
        </li>
        <li>
          <strong>Choisir</strong> une catégorie à laquelle l’associer,
        </li>
        <li>
          <strong>Ajouter</strong> une description au module,
        </li>
        <li>
          <strong>Définir</strong> les droits d’accès en choisissant de le partager avec les autres
          concepteur·rice·s et/ou avec les utilisateur·rice·s,
        </li>
        <li>
          <strong>Ajouter</strong> une adresse e-mail de contact,
        </li>
        <li>
          <strong>Enregistrer</strong> les modifications et revenir à l’écran de synthèse grâce au
          bouton prévu à cet effet,
        </li>
        <li>
          Ou alors <strong>Enregistrer</strong> et <strong>créer</strong> une nouvelle étape grâce
          au bouton prévu à cet effet.
        </li>
      </ul>
      <p>
        Pour chacune des étapes, vous pouvez modifier les items renseignés, le numéro de l’étape
        pour modifier sa position ainsi que les ressources associées à cette étape.
        <br />
        <strong>
          <u>Attention :</u>
        </strong>{" "}
        il n’est pas possible de modifier le type de ressource. Pour modifier le type de ressource
        il faudra supprimer l’étape en cliquant sur le bouton “Supprimer cette étape” et créer une
        nouvelle étape en cliquant sur le bouton “Nouvelle étape”.
      </p>
      <Warning>
        <u>Attention :</u> vous ne pouvez pas modifier ou supprimer un module créé par un autre
        concepteur, vous pouvez en revanche le dupliquer pour modifier les informations de votre
        choix, vérifiez le champ “Concepteur.rice.s” dans la liste des ressources.
      </Warning>
      <Title>Dupliquer un module</Title>
      <p>
        Pour dupliquer un module, cliquez sur l’icône noire <strong>“Document”</strong> associée au
        module de votre choix.
      </p>
      <Title>Supprimer un module</Title>
      <p>
        Pour supprimer un module, cliquez sur l’icône rouge <strong>“Poubelle”</strong> associée au
        module de votre choix. Une fenêtre Pop-Up de confirmation va s’afficher, cliquez ensuite sur{" "}
        <strong>“OK”</strong> pour supprimer le module ou sur <strong>“Annuler”</strong> pour le
        conserver.
      </p>
      <Warning>
        <u>Attention :</u> vous ne pouvez pas modifier ou supprimer un module créé par un autre
        concepteur. Vous pouvez en revanche le dupliquer pour modifier les informations de votre
        choix. Pensez donc à vérifier le champ “Concepteur.rice.s” dans la liste des ressources.
      </Warning>
      <Title>Ajouter un module</Title>
      <p>
        Pour ajouter un module, cliquez sur le bouton <strong>“Ajouter un module”</strong>.
        <br />
        Pour chaque module, des paramètres sont à définir :{" "}
      </p>
      <ul>
        <li>Le nom du module,</li>
        <li>La catégorie à laquelle vous souhaitez l’associer,</li>
        <li>La description du module,</li>
        <li>Les droits d’accès : </li>
        <ul>
          <li>
            “Partager avec les concepteur.rice.s” : permet de partager le module avec l’ensemble des
            concepteur·rice·s ayant accès au Back Office,
          </li>
          <li>
            “Partager avec un groupe d’élèves” : permet de partager le module avec le groupe
            d’élèves de son choix.
          </li>
        </ul>
        <li>
          Ajouter une adresse e-mail de contact : cette adresse sera afficher dans l’application
          pour permettre à l’utilisateur·rice de prendre contact avec la personne référencée en cas
          de besoin.
        </li>
      </ul>
      <p>
        Une fois les paramètres enregistrés, le module est créé. Pour compléter le module, cliquez
        sur <strong>“Nouvelle étape”</strong> et ajoutez les ressources de votre choix. Pour chaque
        nouvelle étape, ajoutez un titre, choisissez un type de ressource, complétez les
        informations associées à la ressource et ajoutez un numéro d’étape pour définir
        l’emplacement de cette étape.
      </p>
      <p>
        <strong>Pour enregistrer vos étapes, vous pouvez cliquer sur le bouton :</strong>
      </p>
      <ul>
        <li>
          <strong>“Enregistrer et accéder à l’écran de synthèse”</strong> pour visualiser l’ensemble
          des étapes créées,
        </li>
        <li>
          <strong>“Enregistrer et créer une nouvelle étape”</strong> pour créer directement une
          nouvelle étape.
        </li>
      </ul>
      <Subtitle>Ressource simple</Subtitle>
      <p>
        Ce type de ressource permet d’ajouter uniquement une description simple sous forme de pavé
        texte.
      </p>
      <Subtitle>Ressource document PDF, vidéo, image, son</Subtitle>
      <p>
        Ce type de ressource permet d’ajouter un document au format .pdf, une image, une vidéo ou
        encore un fichier audio.
      </p>
      <ul>
        <li>
          Vous pouvez ajouter une ressource depuis la bibliothèque de ressources du Back Office en
          la sélectionnant dans la liste déroulante <strong>“Ma bibliothèque de ressources”</strong>
        </li>
        <li>
          Vous pouvez choisir une ressource depuis votre poste de travail en cliquant sur{" "}
          <strong>“Choisir un fichier”</strong>.
        </li>
      </ul>
      <p>
        Après avoir ajouté la ressource, vous avez la possibilité d’ajouter une description à votre
        étape, celle-ci apparaîtra sous votre ressource.
      </p>
      <Subtitle>Ressource image 360°</Subtitle>
      <p>Ce type de ressource permet d’ajouter une image en 360°.</p>
      <ul>
        <li>
          Vous pouvez ajouter une ressource depuis la bibliothèque de ressources du Back Office en
          la sélectionnant dans la liste déroulante <strong>“Ma bibliothèque de ressources”</strong>
        </li>
        <li>
          Vous pouvez choisir une ressource depuis votre poste de travail en cliquant sur{" "}
          <strong>“Choisir un fichier”</strong>.
        </li>
      </ul>
      <Subtitle>Objet 3D</Subtitle>
      <p>
        Ce type de ressource permet d’ajouter un objet 3D qui visible dans l’application en réalité
        augmentée. Il sera déclenché sans tag uniquement à l’aide d’un environnement détaillé et
        contrasté.
      </p>
      <ul>
        <li>
          Vous pouvez ajouter une ressource depuis la bibliothèque de ressources du Back Office en
          la sélectionnant dans la liste déroulante <strong>“Ma bibliothèque de ressources”</strong>
        </li>
        <li>
          Vous pouvez choisir une ressource depuis votre poste de travail en cliquant sur{" "}
          <strong>“Choisir un fichier”</strong>.
        </li>
      </ul>
      <Subtitle>Objets 3D déclenchés via tags</Subtitle>
      <p>
        Ce type de ressource permet d’ajouter un ou plusieurs objet(s) 3D associé(s) au tag de votre
        choix. L’objet 3D sera alors déclenché à l’aide du tag. Pour chaque objet 3D :
      </p>
      <ul>
        <li>
          Ajoutez un objet 3D depuis la bibliothèque de ressources du Back Office en le
          sélectionnant dans la liste déroulante <strong>“Ma bibliothèque de ressources”</strong>,
        </li>
        <li>
          Ajoutez un tag depuis la bibliothèque de ressources du Back Office en le sélectionnant
          dans la liste déroulante <strong>“Ma bibliothèque de ressources”</strong>. Il peut s’agir
          d’un QR Code, Flash Code ou d’une simple image,
        </li>
        <li>
          Choisissez l’usage du tag : à la verticale ou à l’horizontale. Ce choix fera varier la
          façon dont l’objet 3D apparaît à l’écran.
        </li>
        <li>
          Vous pouvez ajouter le nombre d’objet 3D de votre choix pour créer des scénarios en
          cliquant sur bouton <strong>“Ajouter un objet 3D via tag”</strong>.
        </li>
      </ul>
      <Subtitle>Scène 3D</Subtitle>
      <p>
        Ce type de ressource permet d’ajouter un scénario créé par l’équipe technique. Ces scénarios
        sont ajoutés au Back Office et partagés par défaut avec l’ensemble des concepteur·rice·s.
      </p>
      <p>
        Ajoutez le scénario de votre choix depuis la bibliothèque de ressources du Back Office en le
        sélectionnant dans la liste déroulante <strong>“Ma bibliothèque de ressources”</strong>.
      </p>
      <Subtitle>Points d’Intérêt Géographiques (POI)</Subtitle>
      <p>
        Ce type de ressource permet d’ajouter des points d'intérêts associés à une localisation. Les
        POI sont affichés et déclenchés à un emplacement précis.
      </p>
      <p>
        <strong>Pour créer une étape POI :</strong>
      </p>
      <ul>
        <li>
          Ajoutez une localisation pour définir l’emplacement de l’utilisateur·rice : la longitude,
          la latitude et un rayon,
        </li>
        <li>Ajoutez un POI :</li>
        <ul>
          <li>Saisissez le label (ou étiquette),</li>
          <li>
            Complétez ensuite la description ou choisissez un objet 3D à faire apparaître dans la
            liste déroulante <strong>“Ma bibliothèque de ressources”</strong>,
          </li>
          <li>
            Ajoutez un pictogramme dans la liste déroulante{" "}
            <strong>“Ma bibliothèque de ressources”</strong> qui servira de point de repère visuel
            au POI,
          </li>
          <li>
            Ajoutez une localisation pour le POI : latitude et longitude. L’application calculera
            alors la distance entre les deux localisations renseignées. Cette distance apparaîtra
            sous le pictogramme du POI afin d’informer l’utilisateur·rice.
          </li>
        </ul>
        <li>
          Vous pouvez ajouter le nombre de POI de votre choix pour créer des scénarios en cliquant
          sur le bouton <strong>“Ajouter un POI”</strong>.
        </li>
      </ul>
      <Subtitle>Quiz</Subtitle>
      <p>Ce type de ressource permet d’ajouter un quiz à votre étape.</p>
      <p>
        <strong>Pour créer le quiz :</strong>
      </p>
      <ul>
        <li>Saisissez un question, </li>
        <li>Choisissez le type de réponse : choix unique ou choix multiples,</li>
        <li>
          Choisissez le type de contenu pour le choix des réponses : contenu image ou contenu texte.
        </li>
      </ul>
      <p>
        Pour les contenus texte, saisissez le choix des réponses dans les champs prévus à cet effet.
        Définissez ensuite si cette réponse est juste ou fausse en cliquant sur la coche à droite de
        chaque ligne. Chaque clic permet un changement d’état : Réponse Juste / Réponse Fausse. Si
        la réponse est fausse saisissez un commentaire : celui-ci apparaîtra si l’étudiant·e répond
        faux au quiz.
      </p>
      <p>
        Pour les contenus image, choisissez une image dans la liste déroulante{" "}
        <strong>“Ma bibliothèque de ressources”</strong>, définissez ensuite si cette réponse est
        juste ou fausse en cliquant sur la coche à droite de chaque ligne. Chaque clic permet un
        changement d’état : Réponse Juste / Réponse Fausse. Si la réponse est fausse saisissez un
        commentaire, celui-ci apparaîtra si l’étudiant·e répond faux au quiz.
      </p>
      <Title>Publier / dépublier un module</Title>
      <p>
        Cette fonctionnalité permet de rendre un module visible ou non pour les utilisateur·rice·s
        avec qui le module est partagé (concepteur·rice·s, groupes d’utilisateur·rice·s).
      </p>
      <p>
        Pour Publier ou dépublier un module, cliquez sur l’icône noire <strong>“Crayon”</strong>{" "}
        associée au module de votre choix. Vous pouvez ensuite cliquer sur le bouton{" "}
        <strong>“Publier un module”</strong> pour le rendre visible ou sur le bouton{" "}
        <strong>“Dépublier un module”</strong> pour ne plus le faire apparaître.
      </p>
    </Paper>
  )
}

export default HelpModules
