import React from "react"
import styled from "styled-components"
import DatePicker, { registerLocale } from "react-datepicker"
import { ReactComponent as CalendarIcon } from "images/calendar.svg"
import "react-datepicker/dist/react-datepicker.css"
import fr from "date-fns/locale/fr"
import { Colors, Shadows } from "helpers/constants"
registerLocale("fr", fr)

type DateFilterProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>

interface FilterProps extends DateFilterProps {
  dateStart: Date
  handleSelectStart(data: any): void
  handleChangeStart(data: any): void
  dateEnd: Date
  handleSelectEnd(data: any): void
  handleChangeEnd(data: any): void
}

const DateFilter: React.FC<FilterProps> = ({
  className,
  dateStart,
  handleSelectStart,
  handleChangeStart,
  dateEnd,
  handleSelectEnd,
  handleChangeEnd,
  ...props
}) => (
  <div className={className}>
    <CalendarIcon />
    <DatePicker
      selected={dateStart}
      onSelect={handleSelectStart} //when day is clicked
      onChange={handleChangeStart} //only when value has changed
      locale="fr"
      dateFormat={"dd/MM/yyyy"}
    />
    <div className="between">-</div>
    <DatePicker
      selected={dateEnd}
      onSelect={handleSelectEnd} //when day is clicked
      onChange={handleChangeEnd} //only when value has changed
      locale="fr"
      dateFormat={"dd/MM/yyyy"}
    />
  </div>
)
export default styled(DateFilter)`
  background-color: ${Colors.white};
  display: inline-block;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  box-shadow: ${Shadows.paper};
  margin-right: 20px;
  height: 50px;
  white-space: nowrap;
  width: 270px;
  svg {
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 10px;
    stroke: ${Colors.button};
    fill: ${Colors.button};
    width: 22px;
  }
  .between {
    display: inline-block;
    font-size: 15px;
    color: ${Colors.button};
    font-weight: 500;
    height: 30px;
    vertical-align: middle;
    padding: 1px 2px;
    line-height: 26px;
  }
  .react-datepicker__input-container input {
    border: none;
    height: 30px;
    outline: none;
    vertical-align: middle;
    font-size: 15px;
    font-weight: 500;
    width: 100px;
    text-align: center;
    color: ${Colors.button};
    &::placeholder {
      color: ${Colors.button};
    }
  }
  .react-datepicker__tab-loop {
    display: inline-block;
  }
`
