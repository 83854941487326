import React, { ReactNode } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import styled from "styled-components"

import { Colors } from "helpers/constants"

export const Tabs = styled.div`
  display: flex;
  margin-bottom: 50px;
`

export const Bar = styled.div`
  height: 5px;
  border-radius: 6.5px;
`

const TabContainer = styled.div<{ active?: boolean }>`
  a {
    display: block;
    padding: 10px 50px;
    font-weight: ${(props) => (props.active ? "500" : "normal")};
    color: ${(props) => (props.active ? Colors.yellow : Colors.text)};
    white-space: nowrap;
  }
  ${Bar} {
    background-color: ${(props) => (props.active ? Colors.yellow : Colors.white)};
  }
`

interface TabProps extends RouteComponentProps {
  children: ReactNode
  path: string
}

export const Tab = withRouter(({ path, location, children }: TabProps) => {
  return (
    <TabContainer active={location.pathname.startsWith(path)}>
      {children}
      <Bar />
    </TabContainer>
  )
})
