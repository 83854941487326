import React from "react"
import { RouteComponentProps } from "react-router-dom"
import { AnyObject } from "final-form"

import { Container, Paper, Placeholder } from "Authenticated/components/containers"
import { Form, Title, Caption, Row, TextInput } from "components/form"
import ListField, { Item } from "components/form/ListField"
import api, { getRef } from "services/api"
import { getUserLabel } from "components/user"
import { useApi } from "helpers/hooks"
import { pick } from "helpers/object"
import { useToast } from "components/toast"

interface MatchParams {
  id: string
}

const userMapper = (user: IUser): Item => ({
  id: user.id,
  name: getUserLabel(user),
})
const moduleMapper = ({ id, label }: IModule): Item => ({ id, name: label })

const EditGroup: React.FC<RouteComponentProps<MatchParams>> = ({ match, history }) => {
  const { id } = match.params
  const { data, loading, error } = useApi<IUserGroup>(id && `groupes/${id}`)
  const toast = useToast()

  const page = localStorage.getItem("paginationGroups")
  const linkBack = page ? `/users/groups?page=${page}` : `/users/groups`

  const users = useApi<IUser[]>(`users`)
  const modules = useApi<any[]>(`modules`)

  if (loading || error) {
    return (
      <Container title="Modifier un groupe d’utilisateur·rice·s">
        <Placeholder loading={loading} error={error} />
      </Container>
    )
  }

  const initialValues = pick(data, ["groupName"])
  if (data) {
    initialValues.users = data.users.map(userMapper)
    initialValues.modules = data.modules.map(moduleMapper)
  } else {
    initialValues.users = []
    initialValues.modules = []
  }

  const userList = users.data ? users.data.map(userMapper) : []
  const filteredModulesList = modules.data
    ? modules.data.filter(function (md) {
        return md.isArchived === false
      })
    : null
  const moduleList = filteredModulesList ? filteredModulesList.map(moduleMapper) : []

  const handleSubmit = async (values: AnyObject) => {
    const payload = {
      groupName: values.groupName,
      users: values.users.map(({ id }: Item) => getRef("users", id)),
      modules: values.modules.map(({ id }: Item) => getRef("modules", id)),
    }
    if (id) {
      await api.put<IUserGroup>(`groupes/${id}`, payload)
    } else {
      await api.post<IUserGroup>("groupes", payload)
    }
    history.push(linkBack)
    toast(id ? "Le groupe a bien été modifié" : "Le groupe a bien été créé")
  }

  return (
    <Container
      title={id ? "Modifier un groupe d’utilisateur·rice·s" : "Nouveau groupe d’utilisateur·rice·s"}
      back={linkBack}
    >
      <Paper>
        <Form initialValues={initialValues} submitLabel="Enregistrer" onSubmit={handleSubmit}>
          <Title>{id ? "Modifier" : "Ajouter"} le nom du groupe</Title>
          <Row>
            <TextInput required name="groupName" placeholder="Saisissez ici le nom du groupe" />
          </Row>
          <Title>Ajouter des utilisateur·rice·s</Title>
          <Caption>
            Saisissez le nom d’un·e utilisateur·rice pour l’ajouter au nouveau groupe.
          </Caption>
          <ListField
            name="users"
            data={userList}
            placeholder="Saisissez ici le nom de l’utilisateur·rice"
            loading={users.loading}
            error={users.error}
          />
          <Title>Droits d’accès aux modules pédagogiques</Title>
          <Caption>Saisissez le nom d’un module pour l’ajouter au nouveau groupe.</Caption>
          <ListField
            name="modules"
            data={moduleList}
            placeholder="Saisissez ici le nom du module"
            loading={modules.loading}
            error={modules.error}
          />
        </Form>
      </Paper>
    </Container>
  )
}

export default EditGroup
