import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { RouteComponentProps, Link } from "react-router-dom"
import { parse } from "query-string"

import LOGO_AMU from "images/logos/amu.png"
import LOGO_AMIDEX from "images/logos/amidex.png"
import LOGO_AVENIR from "images/logos/avenir.png"
import { Colors } from "helpers/constants"
import api, { SSO_ENDPOINT } from "services/api"
import { useSession } from "components/user"
import { useToast } from "components/toast"
import { LinkButton, ExternalLinkButton } from "components/buttons"
import { Logo } from "Anonymous/common"
import Loader from "images/Loader"

const ENDPOINT = `https://${window.location.host}`
const SSO_URL = `${SSO_ENDPOINT}/login?service=${encodeURIComponent(ENDPOINT)}`

const Container = styled.div`
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
const Content = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Text = styled.p`
  font-size: 1.2rem;
  max-width: 550px;
  margin: 1.2em auto;
`
const Actions = styled.div`
  margin-top: 30px;
  a {
    margin: 0 10px;
  }
`
const Bottom = styled.div``
const Partners = styled.div`
  margin: 20px;
`
const PartnerLogo = styled.img`
  vertical-align: middle;
  margin: 10px;
`
const Links = styled.div`
  border-top: 1px solid ${Colors.lightGrey};
  font-size: 0.8rem;
  padding: 30px;
  margin-top: 10px;
`
const ActionsLoader = styled(Loader)`
  margin: 50px 0 20px;
`

const Welcome: React.FC<RouteComponentProps> = ({ location, history }) => {
  // SSO callback is handled below:
  const [loading, setLoading] = useState(false)
  const { setSession } = useSession()
  const toast = useToast()

  useEffect(() => {
    const { ticket } = parse(location.search)
    if (ticket) {
      setLoading(true)
      api
        .post<IAuthResponse>(`login_cas`, {
          service: ENDPOINT,
          ticket,
        })
        .then((session) => {
          setSession({
            user: session.data,
            token: session.token,
            refresh_token: session.refresh_token,
            sso: true,
          })
        })
        .catch((err) => {
          toast(`Erreur : ${err.message}`)
          history.replace("/") // remove query string
        })
        .then(() => {
          setLoading(false)
        })
    }
  }, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps

  let actions
  if (loading) {
    actions = <ActionsLoader color={Colors.text} />
  } else {
    actions = (
      <Actions>
        <ExternalLinkButton href={SSO_URL}>Je me connecte avec mon adresse AMU</ExternalLinkButton>
        <LinkButton to="/login">Je ne dispose pas d’une adresse AMU</LinkButton>
      </Actions>
    )
  }

  return (
    <Container>
      <Content>
        <Logo />
        <h1>Bienvenue sur la plateforme de l’Université d’Aix-Marseille</h1>
        <Text>
          Depuis cet environnement numérique, vous pourrez concevoir des scénarii pédagogiques
          incluant différents médias y compris la Réalité Augmentée pour un usage avec vos
          élèves/étudiant·e·s.
        </Text>
        {actions}
      </Content>
      <Bottom>
        <Partners>
          <PartnerLogo src={LOGO_AMU} style={{ height: 38 }} />
          <PartnerLogo src={LOGO_AMIDEX} style={{ height: 30 }} />
          <PartnerLogo src={LOGO_AVENIR} style={{ height: 60 }} />
        </Partners>
        <Links>
          <Link to="/help">Besoin d’aide ?</Link> –{" "}
          <a href="/CGU_010923.pdf" target="_blank">
            Conditions Générales d’Utilisation
          </a>{" "}
          –{" "}
          <a href="/RGPD_290520.pdf" target="_blank">
            Traitement des données
          </a>
        </Links>
      </Bottom>
    </Container>
  )
}

export default Welcome
