import { format } from "date-fns"

export const dateFormat = (date: string | number | Date) => {
  if (!date) {
    return ""
  }
  if (typeof date === "string") {
    date = new Date(date)
  }
  return format(date, "dd/MM/yy")
}
