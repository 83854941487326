import React, { useState } from "react"
import { useFieldArray } from "react-final-form-arrays"
import styled from "styled-components"

import { ReactComponent as DeleteIcon } from "images/delete.svg"
import { IconButton } from "components/buttons"
import { Colors, Shadows } from "helpers/constants"
import { fulltextFilter } from "helpers/string"
import { FreeInput, Row } from "components/form"

const Autocomplete = styled.div`
  position: relative;
  margin-right: 50px;
`
const Suggestions = styled.div`
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.separator};
  border-radius: 3px;
  padding: 5px;
  box-shadow: ${Shadows.paper};
  z-index: 1;
  max-height: 163px;
  overflow-y: scroll;
`
const Suggestion = styled.button`
  display: block;
  padding: 5px;
  text-align: left;
  width: 100%;
`

export interface Item {
  id: number
  name: string
}

interface Props {
  name: string
  data: Item[]
  placeholder?: string
  loading?: boolean
  error?: Error
}

const ListField: React.FC<Props> = ({ name, data, placeholder, loading, error }) => {
  const [text, setText] = useState("")
  const [focus, setFocus] = useState(false)
  const [suggestions, setSuggestions] = useState<Item[]>([])

  const handleFocus = () => {
    setFocus(true)
  }

  const handleBlur = () => {
    setFocus(false)
  }

  if (loading) {
    placeholder = "Chargement..."
  } else if (error) {
    placeholder = `Erreur : ${error.message}`
  }

  const { fields } = useFieldArray<Item>(name)

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setText(value)
    setSuggestions(
      data
        ? data.filter(({ id, name }) => {
            if (fields.value && fields.value.find((item) => id === item.id)) {
              return false
            }
            return fulltextFilter(value, [name])
          })
        : []
    )
  }

  const handleSuggestion = (item: Item) => () => {
    fields.push(item)
    setText("")
    setSuggestions([])
  }

  return (
    <>
      {fields.value &&
        fields.value.map(({ id, name }, index) => (
          <Row key={id}>
            <FreeInput readOnly value={name} />
            <IconButton type="button" onClick={() => fields.remove(index)}>
              <DeleteIcon />
            </IconButton>
          </Row>
        ))}
      <Autocomplete>
        <FreeInput
          placeholder={placeholder}
          value={text}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {focus && suggestions.length > 0 && (
          <Suggestions>
            {suggestions.map(({ id, name }) => (
              <Suggestion key={id} type="button" onMouseDown={handleSuggestion({ id, name })}>
                {name}
              </Suggestion>
            ))}
          </Suggestions>
        )}
      </Autocomplete>
    </>
  )
}

export default ListField
