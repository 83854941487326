import React from "react"

interface Props {
  backgroundColor: string
  color: string
  width: number
}

const Plus: React.FC<Props> = ({ backgroundColor, color, width }) => (
  <svg width={width} height={width} viewBox="0 0 30 30">
    <g fill="none" fillRule="evenodd">
      <circle fill={backgroundColor} cx="15" cy="15" r="15" />
      <path
        d="M15.21 7.71v15M22.71 15.21h-15"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export default Plus
