import React from "react"
import { hot } from "react-hot-loader"

import { SessionProvider } from "components/user"
import { ToastProvider } from "components/toast"
import App from "./App"
import styled from "styled-components"
import { Colors } from "helpers/constants"
import { ErrorBoundary } from "helpers/bugsnag"

const ErrorContainer = styled.div`
  padding: 40px;
  h1 {
    color: ${Colors.error};
    font-size: 1.5rem;
  }
  h2 {
    color: ${Colors.text};
    font-size: 1.2rem;
  }
  pre {
    margin-top: 40px;
    color: ${Colors.grey2};
  }
`

// https://github.com/bugsnag/bugsnag-js/blob/next/packages/plugin-react/src/index.js#L9-L12
interface FallbackProps {
  error: Error
  info?: any
}

const ErrorComponent: React.FC<FallbackProps> = ({ error }) => (
  <ErrorContainer>
    <h1>
      <span aria-hidden>🐞</span>
      <br />
      Une erreur est survenue.
    </h1>
    <h2>Essayez de recharger la page.</h2>
    <pre>{error.stack || String(error)}</pre>
  </ErrorContainer>
)

const Root: React.FC = () => (
  <ErrorBoundary FallbackComponent={ErrorComponent}>
    <ToastProvider>
      <SessionProvider>
        <App />
      </SessionProvider>
    </ToastProvider>
  </ErrorBoundary>
)

export default hot(module)(Root)
