import React from "react"
import { RouteComponentProps } from "react-router-dom"

import { Paper } from "Authenticated/components/containers"
import { Title, Subtitle, Warning } from "./components"

const HelpResources: React.FC<RouteComponentProps> = () => {
  return (
    <Paper>
      <Title>Ressources</Title>
      <ul>
        <li>Vous avez accès à la liste des ressources, </li>
        <li>Vous pouvez rechercher une ressource en saisissant son titre,</li>
        <li>Vous pouvez filtrer les ressources par type ou par catégorie.</li>
      </ul>
      <Subtitle>Visualiser une ressource</Subtitle>
      <p>
        Pour visualiser une ressource, cliquez sur l’icône <strong>bleue “Document”</strong>{" "}
        associée à la ressource de votre choix. Vous avez l’aperçu de la ressource : pour une
        ressource simple vous pouvez enregistrer cette ressource en faisant un clique droit{" "}
        <strong>“enregistrer l’image sous”</strong>. Pour les objets 3D vous pouvez cliquer
        directement sur le lien <strong>“Télécharger le fichier”</strong>.
      </p>
      <p>
        Vous pouvez cliquer sur le bouton <strong>“Supprimer”</strong> pour supprimer cette
        ressource ou cliquer sur le lien <strong>“Retour”</strong> situé en haut à droite de votre
        écran pour revenir à la liste.
      </p>
      <Subtitle>Supprimer une ressource</Subtitle>
      <p>
        Pour supprimer une ressource, cliquez sur l’icône rouge <strong>“Poubelle”</strong> associée
        à la ressource de votre choix. Une fenêtre Pop-Up de confirmation va s’afficher, cliquez
        ensuite sur <strong>“OK”</strong> pour supprimer la ressource ou sur{" "}
        <strong>“Annuler”</strong> pour la conserver.
      </p>
      <Warning>
        <u>Attention :</u> vous ne pouvez pas supprimer une ressource déjà utilisée dans un module,
        cela affecterait le bon fonctionnement de ce module. Pensez donc à vérifier le champ
        “Présence module” dans la liste des ressources.
      </Warning>
      <Subtitle>Ajouter une ressource</Subtitle>
      <p>
        Pour ajouter une ressource, cliquez sur le bouton <strong>“Ajouter une ressource”</strong>.
        Complétez ensuite les informations demandées :
      </p>
      <ul>
        <li>Saisissez le titre de la ressource,</li>
        <li>
          Sélectionnez le type de ressource : image, image 360°, PDF, audio, vidéo, objet 3D, picto
          POI,
        </li>
        <li>
          Cliquez sur <strong>“Choisir un fichier”</strong> pour importer le fichier de votre choix
          depuis votre poste de travail.
        </li>
      </ul>
      <p>
        Cliquez ensuite sur le bouton <strong>“Enregistrer”</strong> pour ajouter la ressource ou
        cliquez sur le lien <strong>“Retour”</strong> situé en haut à droite de votre écran pour
        revenir à la liste.
      </p>
    </Paper>
  )
}

export default HelpResources
