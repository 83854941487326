import React from "react"
import { RouteComponentProps } from "react-router-dom"
import styled from "styled-components"

import { H1, H2 } from "components/titles"
import { Bar } from "Authenticated/components/tabs"
import { Colors } from "helpers/constants"
import { Button } from "components/buttons"
import { Mode, useMode } from "Authenticated/components/mode"
import { useTour } from "./components/tour"

const Container = styled.div`
  margin: 90px 0;
  text-align: center;
`

const Row = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
`
const Block = styled.div<{ active?: boolean }>`
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  ${Bar} {
    background-color: ${(props) => (props.active ? Colors.yellow : Colors.white)};
  }
  ${Button} {
    background-color: ${(props) => (props.active ? Colors.yellow : Colors.grey2)};
  }
  ${H2} {
    font-weight: ${(props) => (props.active ? "500" : "normal")};
  }
  min-width: 380px;
  width: 25%;
`
const Tab = styled.div``
const Content = styled.div`
  padding: 30px 20px;
  font-size: 13px;
  text-align: justify;
  letter-spacing: -0.16px;
  line-height: 20px;
  height: 130px;
`

const Home: React.FC<RouteComponentProps> = ({ history }) => {
  const { mode, setMode } = useMode()
  const { start } = useTour()

  const handleMode = (newMode: Mode) => () => {
    if (newMode === Mode.beginner) {
      start()
    } else if (newMode === Mode.advanced) {
      history.push("/modules")
    }
    setMode(newMode)
  }

  return (
    <Container>
      <H1>Faites votre choix !</H1>
      <Row>
        <Block active={mode === Mode.beginner}>
          <Tab>
            <H2>Mode débutant·e</H2>
            <Bar />
          </Tab>
          <Content>
            En choisissant ce mode, vous serez accompagné·e pas à pas lors de la création de votre
            scénario pédagogique. Vous pourrez passer au Mode Avancé·e lorsque vous vous sentirez
            plus aguerri·e.
          </Content>
          <Button onClick={handleMode(Mode.beginner)}>Je choisis ce mode</Button>
        </Block>
        <Block active={mode === Mode.advanced}>
          <Tab>
            <H2>Mode Avancé·e</H2>
            <Bar />
          </Tab>
          <Content>
            Le Mode Avancé·e vous permet d'accéder à des fonctionnalités supplémentaires comme la
            création d'exercices par exemple.
          </Content>
          <Button onClick={handleMode(Mode.advanced)}>Je choisis ce mode</Button>
        </Block>
      </Row>
    </Container>
  )
}

export default Home
