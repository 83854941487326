import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router-dom"

import Table, { Controls, Actions } from "Authenticated/components/Table"
import { LinkButton, IconLink, IconButton } from "components/buttons"
import { SearchFilter, SelectFilter } from "components/filterInputs"
import { Colors } from "helpers/constants"
import { Container } from "Authenticated/components/containers"
import Plus from "images/Plus"
import { ReactComponent as ConsultIcon } from "images/consult.svg"
import { ReactComponent as EditIcon } from "images/edit.svg"
import { ReactComponent as DeleteIcon } from "images/delete.svg"
import { ReactComponent as DuplicateIcon } from "images/duplicate.svg"
import { dateFormat } from "helpers/date"
import { fulltextFilter } from "helpers/string"
import { useApi } from "helpers/hooks"
import api from "services/api"
import { useToast } from "components/toast"
import { getUserLabel, useUser } from "components/user"
import DateFilter from "components/dateInputs"
import { startOfYear } from "date-fns"

const COLUMNS = [
  "Date",
  "État",
  "Titre du module",
  "Code du module",
  "Concepteur·rice",
  "Partage concepteur·rice",
  "Catégorie",
  "",
]
const LARGE_COLUMNS = {
  2: true,
}

const Modules: React.FC<RouteComponentProps> = ({ history }) => {
  const { data: modules, loading, error, reload } = useApi<any[]>(`modules_light`)
  const { data: categories, loading: loadingCategories } = useApi<ICategory[]>(`categories`)
  const { admin, user } = useUser()
  const toast = useToast()

  const [search, setSearch] = useState("")
  const [searchConcepteur, setSearchConcepteur] = useState("")
  const [endDate, setEndDate] = useState(new Date())
  const [startDate, setStartDate] = useState(new Date("01/01/2019"))
  const [category, setCategory] = useState<number>()

  const queryParameters = new URLSearchParams(window.location.search)
  const page = queryParameters.get("page")
  useEffect(() => {
    localStorage.removeItem("paginationModule")
  }, [])

  const updateUrl = (page: string) => {
    history.push(`/modules?page=${page}`)
  }

  const handleSearchChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(target.value)
  }

  const handleSearchConcepteurChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchConcepteur(target.value)
  }

  const handleCategory = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(Number(target.value))
  }

  const handleChangeStartDate = (date: any) => {
    setStartDate(date)
  }

  const handleChangeEndDate = (date: any) => {
    setEndDate(date)
  }

  const handleDuplicate =
    ({ id }: IModule) =>
    () => {
      api
        .post(`modules/${id}/clone`, {})
        .then((newModule) => {
          toast("Le module a bien été dupliqué")
          history.push(`modules/${newModule.id}`)
        })
        .catch((error) => {
          alert(`Erreur : ${error.message}`)
        })
    }

  const handleDelete =
    ({ id, hasStats }: IModule) =>
    () => {
      let message = "Êtes-vous sûr·e de vouloir supprimer ce module ?"
      if (hasStats) {
        message += "\nAttention : cela effacera aussi ses statistiques d’utilisation !"
      }
      if (window.confirm(message)) {
        api
          .put<IModule>(`modules/${id}`, {
            isArchived: true,
          })
          .then(() => {
            reload()
            toast("Le module a bien été supprimé")
          })
          .catch((error) => {
            alert(`Erreur : ${error.message}`)
          })
      }
    }

  const rows =
    modules &&
    modules
      .filter((data: IModule) => {
        if (data.isArchived) {
          return false // archived = deleted
        }
        let createdDate = new Date(data.createdAt)
        if (!(createdDate > startDate && createdDate < endDate)) {
          return false
        }
        if (search && !fulltextFilter(search, [data.label, data.accessCode])) {
          return false
        }
        if (data.author) {
          if (
            searchConcepteur &&
            !fulltextFilter(searchConcepteur, [
              data.author.firstname,
              data.author.lastname,
              data.author.firstname + " " + data.author.lastname,
            ])
          ) {
            return false
          }
        }
        if (category && data.category.id !== category) {
          return false
        }
        return true
      })
      .map((module: IModule) => {
        const editable = admin || module.author?.id === user.id

        return {
          row: [
            dateFormat(module.createdAt),
            module.isPublished ? "Publié" : "Dépublié",
            module.label,
            module.accessCode,
            getUserLabel(module.author, "Auteur supprimé"),
            module.visibility ? "Public" : "Privé",
            module.category.label,
            <Actions>
              <IconLink
                to={`/modules/${module.id}`}
                onClick={() => {
                  if (page) {
                    localStorage.setItem("paginationModule", page)
                    localStorage.setItem("from", "modules")
                  }
                }}
              >
                <ConsultIcon />
              </IconLink>
              {editable && (
                <IconLink
                  to={`/modules/${module.id}/edit`}
                  onClick={() => {
                    if (page) {
                      localStorage.setItem("paginationModule", page)
                      localStorage.setItem("from", "modules")
                    }
                  }}
                >
                  <EditIcon />
                </IconLink>
              )}
              <IconButton onClick={handleDuplicate(module)}>
                <DuplicateIcon />
              </IconButton>
              {editable && (
                <IconButton onClick={handleDelete(module)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Actions>,
          ],
          data: module,
        }
      })
  console.log("DATE => ", startDate)
  return (
    <Container title="Modules pédagogiques">
      <Controls>
        <div>
          <SearchFilter
            value={search}
            placeholder="Rechercher un module"
            onChange={handleSearchChange}
          />
          <SelectFilter
            label="Filtrer par catégorie"
            value={category}
            onChange={handleCategory}
            loading={loadingCategories}
            list={categories}
          />
          <SearchFilter
            value={searchConcepteur}
            placeholder="Rechercher un concepteur"
            onChange={handleSearchConcepteurChange}
          />
          <DateFilter
            dateStart={startDate}
            handleSelectStart={(date) => {
              handleChangeStartDate(date)
            }}
            handleChangeStart={(date) => {
              handleChangeStartDate(date)
            }}
            dateEnd={endDate}
            handleSelectEnd={(date) => {
              handleChangeEndDate(date)
            }}
            handleChangeEnd={(date) => {
              handleChangeEndDate(date)
            }}
          />
        </div>
      </Controls>
      <LinkButton to="/modules/new" className="addModule">
        <Plus backgroundColor={Colors.white} color={Colors.yellow} width={20} />
        <span>Ajouter un module</span>
      </LinkButton>
      <Controls></Controls>
      <Table
        columns={COLUMNS}
        rows={rows}
        largeColumns={LARGE_COLUMNS}
        loading={loading}
        error={error}
        update={updateUrl}
        pageUrl={page}
      />
    </Container>
  )
}

export default Modules
