import HttpError from "standard-http-error"
import ERROR_CODES from "standard-http-error/codes"

import { getToken, getStoredSession, storeSession } from "components/user"

const ENDPOINT = process.env.NODE_ENV === "production" ? "" : "https://artefac.dev.univ-amu.fr"
// const ENDPOINT = process.env.NODE_ENV === "production" ? "" : "http://api.artefac.local"
export const SSO_ENDPOINT = "https://ident.univ-amu.fr/cas"

type Payload = Record<string, any>

const request = async <T>(
  method: string,
  path: string,
  params?: Payload,
  format?: string,
  abortController?: AbortController
): Promise<T> => {
  const headers = new Headers()
  if(format === "csv") {
    // headers.set("Accept", "text/csv")
  }
  else {
    headers.set("Accept", "application/json")
  }

  const init: RequestInit = {
    method,
    signal: abortController && abortController.signal,
  }

  const token = getToken()
  if (token && path !== "token/refresh") {
    headers.set("Authorization", `Bearer ${token}`)
  }

  if (params) {
    if (params instanceof FormData) {
      init.body = params
    } else {
      headers.set("Content-Type", "application/json")
      init.body = JSON.stringify(params)
    }
  }

  init.headers = headers

  const response = await fetch(`${ENDPOINT}/api/${path}`, init)

  const statusText: string = response.statusText || ERROR_CODES[response.status]

  // TODO: use different status code
  if (response.status === 401 && path !== "login_check" && path !== "token/refresh") {
    // Unauthorized (e.g. expired token)
    const session = getStoredSession()
    if (session) {
      const payload = { refresh_token: session.refresh_token }
      const res = await request<IAuthResponse>("POST", "token/refresh", payload)
      storeSession({
        token: res.token,
        refresh_token: res.refresh_token,
        user: res.data,
        admin: res.data.roles.includes("ROLE_ADMIN"),
        sso: session.sso,
      })
      return request<T>(method, path, params)
    }
  }

  if (response.status >= 500) {
    throw new HttpError(response.status, statusText)
  }

  let data: any
  if(format === "csv") {
    try {
      data = await response.blob()
    } catch (err) {
      console.warn("[API] Response is not valid csv")
    }
  }
  else {

    try {
      data = await response.json()
    } catch (err) {
      console.warn("[API] Response is not valid JSON")
    }
  }
  if (response.status >= 400) {
    const message = data && (data.message || data.originalMessage || data.detail)
    throw new HttpError(response.status, message || statusText)
  }
  return data
}

export default {
  get: <T = any>(path: string, abortController?: AbortController) =>
    request<T>("GET", path, undefined, undefined, abortController),
  post: <T = any>(path: string, params: Payload, abortController?: AbortController) =>
    request<T>("POST", path, params, undefined,abortController),
  put: <T = any>(path: string, params: Payload, abortController?: AbortController) =>
    request<T>("PUT", path, params, undefined,abortController),
  delete: <T = any>(path: string, abortController?: AbortController) =>
    request<T>("DELETE", path, undefined, undefined, abortController),
  getCsv: <T = any>(path: string, abortController?: AbortController) =>
    request<T>("GET", path, undefined, 'csv', abortController),
}

export const getRef = (type: string, id?: number | string): string | null =>
  id ? `api/${type}/${id}` : null

export const getUrl = (path: string) => `${ENDPOINT}/${path}`
