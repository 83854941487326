import React from "react"

import { Colors } from "helpers/constants"

interface Props {
  bgColor?: string
}

const AudioIcon: React.FC<Props> = ({ bgColor, ...props }) => (
  <svg viewBox="0 0 77 77" {...props}>
    <circle
      stroke="#FFFEFE"
      fill={bgColor || Colors.blue}
      fillRule="nonzero"
      cx="37.5"
      cy="37.5"
      r="37.5"
    />
    <g fill="none" fillRule="nonzero">
      <path
        d="M58.465 39.449H54.92v-7.323c0-9.626-7.795-17.421-17.421-17.421s-17.362 7.854-17.362 17.421v7.205h-3.544v-7.205c0-11.516 9.39-20.906 20.906-20.906 11.516 0 20.906 9.39 20.906 20.906v7.323h.059z"
        fill="#324A5E"
      />
      <path
        d="M61.654 39.803h-2.48v12.343h2.48a2.52 2.52 0 0 0 2.539-2.54v-7.204c.059-1.418-1.122-2.599-2.54-2.599z"
        fill="#FFD05B"
      />
      <path
        d="M56.93 36.969h-.355c-1.477 0-2.717 1.24-2.717 2.716v12.579c0 1.476 1.24 2.716 2.717 2.716h.354c1.477 0 2.717-1.24 2.717-2.716V39.685c-.06-1.476-1.24-2.716-2.717-2.716z"
        fill="#FFF"
      />
      <path
        d="M13.346 39.803h2.48v12.343h-2.48a2.52 2.52 0 0 1-2.539-2.54v-7.204c-.059-1.418 1.122-2.599 2.54-2.599z"
        fill="#FFD05B"
      />
      <path
        fill="#FFF"
        d="M18.07 36.969h.355c1.477 0 2.717 1.24 2.717 2.716v12.579c0 1.476-1.24 2.716-2.717 2.716h-.354c-1.477 0-2.717-1.24-2.717-2.716V39.685c.06-1.476 1.24-2.716 2.717-2.716zM47.008 12.815c-2.835-1.417-6.024-2.185-9.449-2.185s-6.555.768-9.449 2.185a.62.62 0 0 0-.295.531v4.075c0 .414.472.709.827.473 2.598-1.595 5.61-2.54 8.917-2.54 3.248 0 6.319.945 8.917 2.54.355.236.827-.06.827-.473v-4.075a.62.62 0 0 0-.295-.531z"
      />
    </g>
  </svg>
)

export default AudioIcon
