import React from "react"

import { Colors } from "helpers/constants"

interface Props {
  bgColor?: string
}

const QuizIcon: React.FC<Props> = ({ bgColor, ...props }) => (
  <svg viewBox="0 0 77 77" {...props}>
    <circle
      stroke="#FFFEFE"
      fill={bgColor || Colors.blue}
      fillRule="nonzero"
      cx="37.5"
      cy="37.5"
      r="37.5"
    />
    <g fillRule="nonzero" fill="none">
      <path
        d="M55.453 13.11H27.402v7.796c0 .767-.65 1.417-1.418 1.417H18.13v38.15c0 .767.65 1.417 1.417 1.417h35.906c.767 0 1.417-.65 1.417-1.418V14.528c0-.768-.65-1.418-1.417-1.418z"
        fill="#FFF"
      />
      <path
        d="M18.13 22.323h7.795c.768 0 1.418-.65 1.418-1.417V13.11l-9.213 9.213z"
        fill="#E6E9EE"
      />
      <path
        d="M28.583 27.46l-.532.532-.531.532c-.177.177-.355.354-.473.59-.177.177-.295.414-.472.59-.118.178-.295.414-.414.591-.118.177-.236.414-.413.65-.118.236-.236.413-.354.65-.118.236-.237.413-.355.65-.059.176-.118.294-.177.472-.059-.119-.177-.178-.236-.296-.295-.354-.65-.708-1.004-1.063l-.354-.354-.532.236c1.949 1.772 2.185 3.72 2.185 3.72s1.595-4.37 4.075-5.787v-2.008c-.118.06-.295.178-.413.296z"
        fill="#FF7058"
      />
      <path
        d="M28.583 37.854l-.532.532-.531.531c-.177.177-.355.355-.473.59-.177.178-.295.414-.472.591-.118.178-.295.414-.414.591-.118.177-.236.413-.413.65-.118.236-.236.413-.354.65-.118.235-.237.413-.355.649-.059.177-.118.295-.177.472-.059-.118-.177-.177-.236-.295-.295-.354-.65-.709-1.004-1.063l-.354-.354-.532.177c1.949 1.771 2.185 3.72 2.185 3.72s1.595-4.37 4.075-5.787V37.5c-.118.118-.295.236-.413.354z"
        fill="#84DBFF"
      />
      <path
        d="M28.583 48.189l-.532.531-.531.532c-.177.177-.355.354-.473.59-.177.178-.295.414-.472.591-.118.177-.295.413-.414.59-.118.178-.236.414-.413.65-.118.236-.236.414-.354.65-.118.236-.237.413-.355.65-.059.177-.118.295-.177.472-.059-.118-.177-.177-.236-.295-.295-.355-.65-.71-1.004-1.063l-.354-.355-.532.237c1.949 1.771 2.185 3.72 2.185 3.72s1.595-4.37 4.075-5.787v-2.008c-.118.059-.295.177-.413.295z"
        fill="#4CDBC4"
      />
      <path
        fill="#E6E9EE"
        d="M33.544 27.107h19.134v1.535H33.544zM33.544 30.237h11.929v1.535h-11.93zM33.544 38.209h19.134v1.535H33.544zM33.544 41.339h11.929v1.535h-11.93zM33.544 49.31h19.134v1.536H33.544zM33.544 52.44h11.929v1.536h-11.93z"
      />
    </g>
  </svg>
)

export default QuizIcon
