import React, { useState, useContext } from "react"
import ReactJoyride, {
  CallBackProps,
  Step,
  ACTIONS,
  STATUS,
  EVENTS,
  Placement,
} from "react-joyride"
import styled from "styled-components"
import { useHistory } from "react-router-dom"

import { Colors } from "helpers/constants"

interface AppStep {
  path: string
  text: string
  target: string
  placement: Placement
}

const APPSTEPS: AppStep[] = [
  {
    path: "/users/groups",
    text:
      "Accédez à l’ensemble des utilisateur·rice·s qui disposeront de l’application ARTEfac et créez vos propres groupes.",
    target: ".usersEntry",
    placement: "right",
  },
  {
    path: "/users/groups",
    text:
      "Cliquez ici pour créer un nouveau groupe d’utilisateur·rice·s et lui donner accès aux modules pédagogiques de votre choix.",
    target: ".addGroup",
    placement: "bottom",
  },
  {
    path: "/modules",
    text:
      "Accédez à toutes les fonctionnalités pour concevoir vos modules pédagogiques et retrouvez l’ensemble des modules déjà créés.",
    target: ".modulesEntry",
    placement: "right",
  },
  {
    path: "/modules",
    text:
      "Cliquez ici pour commencer la conception d’un nouveau module pédagogique, étape par étape, en y ajoutant par la suite les ressources multimédia associées.",
    target: ".addModule",
    placement: "bottom",
  },
  {
    path: "/resources",
    text:
      "Retrouvez l’ensemble des ressources déjà utilisées dans votre bibliothèque ou ajoutez-en de nouvelles afin d’enrichir vos modules pédagogiques.",
    target: ".resourcesEntry",
    placement: "right",
  },
  {
    path: "/resources",
    text: "Cliquez ici pour ajouter une nouvelle ressource afin d’enrichir votre bibliothèque.",
    target: ".addResource",
    placement: "bottom",
  },
  {
    path: "/stats",
    text:
      "Retrouvez les statistiques globales de vos groupes d’étudiant·e·s concernant les modules consultés. Vous pouvez aussi filtrer les résultats pour obtenir les statistiques par utilisateur·rice individuel, par groupe ou par module.",
    target: ".statsEntry",
    placement: "right",
  },
  {
    path: "/stats",
    text:
      "Cliquez ici pour sélectionner un·e utilisateur·rice afin de consulter ses statistiques individuelles.",
    target: ".statsSearch",
    placement: "bottom",
  },
  {
    path: "/help",
    text:
      "Retrouvez des indications descriptives sur chaque onglet de votre espace concepteur afin de faciliter votre prise en main. A tout moment, vous pourrez retrouver et relire les précisions concernant chaque fonctionnalité.",
    target: ".helpEntry",
    placement: "right",
  },
  {
    path: "/profile",
    text: "Retrouvez l’ensemble de vos données personnelles et modifiez-les.",
    target: ".profileEntry",
    placement: "right",
  },
]

const StepContent = styled.div`
  text-align: left;
  margin-bottom: -15px;
`

interface ITourContext {
  start: () => void
}

const TourContext = React.createContext<ITourContext>({} as ITourContext)

export const TourProvider: React.FC = ({ children }) => {
  const [running, setRunning] = useState(false)
  const [index, setIndex] = useState(0)
  const history = useHistory()

  const handleJoyride = ({ action, index, type, status }: CallBackProps) => {
    // console.log(action, index, type, status)

    if (
      status === STATUS.FINISHED ||
      status === STATUS.SKIPPED ||
      status === STATUS.PAUSED ||
      action === ACTIONS.CLOSE
    ) {
      setRunning(false)
      setIndex(0)
      if (status === STATUS.FINISHED) {
        history.push("/help")
      }
      return
    }

    if (
      ((action === ACTIONS.START || action === ACTIONS.NEXT) && type === EVENTS.STEP_BEFORE) ||
      (action === ACTIONS.PREV && type === EVENTS.STEP_AFTER)
    ) {
      const { path } = APPSTEPS[action === ACTIONS.PREV ? index - 1 : index]
      const prev = APPSTEPS[action === ACTIONS.PREV ? index : index - 1]
      if (path && (!prev || path !== prev.path)) {
        history.push(path)
      }
    }

    if (type === EVENTS.STEP_AFTER) {
      setIndex(action === ACTIONS.PREV ? index - 1 : index + 1)
    }
  }

  const start = () => {
    setRunning(true)
  }

  const STEPS: Step[] = APPSTEPS.map(({ text, target, placement }) => ({
    content: <StepContent>{text}</StepContent>,
    target,
    placement,
    disableBeacon: true,
  }))

  return (
    <TourContext.Provider value={{ start }}>
      {children}
      <ReactJoyride
        steps={STEPS}
        run={running}
        continuous
        stepIndex={index}
        disableScrolling
        callback={handleJoyride}
        styles={{
          options: {
            primaryColor: Colors.button,
            textColor: Colors.text,
          },
        }}
        floaterProps={{
          disableAnimation: true,
        }}
        locale={{
          back: "Précédent",
          next: "Suivant",
          close: "Fermer",
          last: "Terminer",
          skip: "Passer",
        }}
      />
    </TourContext.Provider>
  )
}

export const useTour = () => useContext(TourContext)
