import React from "react"
import { NavLink, RouteComponentProps, Switch, Route, Redirect } from "react-router-dom"
import styled from "styled-components"

import { Container } from "Authenticated/components/containers"
import { HELP_EMAIL_ADDRESS, Colors } from "helpers/constants"
import { Tabs, Tab } from "Authenticated/components/tabs"

import HelpUsers from "./Users"
import HelpModules from "./Modules"
import HelpResources from "./Resources"
import HelpStats from "./Stats"
import HelpAlerts from "./Alerts"
import HelpProfile from "./Profile"
import { Button } from "components/buttons"
import { useTour } from "Authenticated/components/tour"

const Caption = styled.p`
  color: ${Colors.blue};
  margin: -25px 0 25px;
`
const TourButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
`
const Link = styled(NavLink)`
  font-size: 0.8em;
  font-weight: normal;
`
const Content = styled.div`
  p {
    text-align: justify;
  }
`

const Help: React.FC<RouteComponentProps> = () => {
  const { start } = useTour()

  return (
    <Container title="Aide à l’utilisation du Back Office">
      <Caption>
        Pour toute question d’ordre technique, merci d’envoyer un e-mail à l’adresse suivante :{" "}
        <a href={`mailto:${HELP_EMAIL_ADDRESS}`}>{HELP_EMAIL_ADDRESS}</a>
      </Caption>
      <TourButton onClick={start}>Revoir le tutoriel</TourButton>
      <Tabs>
        <Tab path="/help/users">
          <Link to="/help/users">Utilisateur·rice·s</Link>
        </Tab>
        <Tab path="/help/modules">
          <Link to="/help/modules">Modules pédagogiques</Link>
        </Tab>
        <Tab path="/help/resources">
          <Link to="/help/resources">Ressources</Link>
        </Tab>
        <Tab path="/help/stats">
          <Link to="/help/stats">Statistiques</Link>
        </Tab>
        <Tab path="/help/profile">
          <Link to="/help/profile">Profil personnel</Link>
        </Tab>
        <Tab path="/help/alerts">
          <Link to="/help/alerts">Alertes</Link>
        </Tab>
      </Tabs>
      <Content>
        <Switch>
          <Route path="/help/users" component={HelpUsers} />
          <Route path="/help/modules" component={HelpModules} />
          <Route path="/help/resources" component={HelpResources} />
          <Route path="/help/stats" component={HelpStats} />
          <Route path="/help/profile" component={HelpProfile} />
          <Route path="/help/alerts" component={HelpAlerts} />
          <Redirect to="/help/users" />
        </Switch>
      </Content>
    </Container>
  )
}

export default Help
