import React from "react"
import { RouteComponentProps } from "react-router-dom"

import { PageContainer, Content, SmallLogo, FormattedText } from "Anonymous/common"
import { useApi } from "helpers/hooks"

const Tos: React.FC<RouteComponentProps> = () => {
  const { data } = useApi<ITos>("cgus/4")

  return (
    <PageContainer>
      <Content>
        <SmallLogo />
        <FormattedText>{data ? data.content : "Chargement…"}</FormattedText>
      </Content>
    </PageContainer>
  )
}

export default Tos
