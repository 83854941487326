import { useState, useEffect, useCallback } from "react"
import HttpError from "standard-http-error"

import api from "services/api"

export const useApi = <T>(path?: string) => {
  const [data, setData] = useState<T>()
  const [loading, setLoading] = useState(!!path)
  const [error, setError] = useState<HttpError>()

  const load = (reload = false) => {
    if (!path) {
      setData(undefined)
      setLoading(false)
      setError(undefined)
      return
    }

    // https://www.caniuse.com/#search=AbortController
    const abortController =
      typeof AbortController !== "undefined" ? new AbortController() : undefined

    if (!reload) {
      setData(undefined)
      setLoading(true)
    }
    setError(undefined)
    let fetching = true

    api
      .get<T>(path, abortController)
      .then((data) => {
        setData(data)
        setLoading(false)
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          setError(error)
          setLoading(false)
        } // else do not perform state updates because the abort means the calling component got unmounted
      })
      .finally(() => {
        fetching = false
      })

    if (abortController) {
      return () => {
        if (fetching) {
          abortController.abort()
        }
      }
    }
  }

  useEffect(load, [path])

  const reload = useCallback(
    (clear = false): void => {
      load(!clear)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [path]
  )

  return { data, loading, error, reload }
}
