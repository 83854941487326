import styled from "styled-components"

import { Colors } from "helpers/constants"

export const Title = styled.h1`
  color: ${Colors.blue};
  margin: 20px 0;
`

export const Subtitle = styled.h2`
  color: ${Colors.yellow};
  font-weight: normal; /* 100 is not readable */
  text-transform: uppercase;
`

export const Warning = styled.p`
  color: ${Colors.red};
  font-weight: 500;
`
