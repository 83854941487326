import React from "react"
import styled from "styled-components"
import { useFieldArray } from "react-final-form-arrays"

import { ReactComponent as CheckIcon } from "images/checkbox.svg"
import { IconButton } from "components/buttons"
import { FreeInput, Row, Space, FreeSelect } from "components/form"
import { Colors } from "helpers/constants"
import Condition from "components/form/Condition"

const Container = styled.div`
  position: relative;
`
const CorrectButton = styled(IconButton)<{ correct: boolean }>`
  position: absolute;
  top: 10px;
  right: -156px;
  width: 150px;
  text-align: left;
  padding: 10px;
  svg {
    fill: ${(props) => (props.correct ? Colors.success : Colors.error)};
  }
  span {
    margin-left: 5px;
    font-size: 0.85em;
    color: ${(props) => (props.correct ? Colors.success : Colors.error)};
  }
`

export interface Answer {
  id?: number
  value: string
  imageId: string
  comment: string
  correct: boolean
}

interface Props {
  name: string
  images: IFileResource[]
}

const AnswersField: React.FC<Props> = ({ name, images }) => {
  const { fields } = useFieldArray<Answer>(name)

  const handleChange = (index: number, key: string) => ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    fields.update(index, {
      ...fields.value[index],
      [key]: value,
    })
    if (index === fields.value.length - 1) {
      fields.push({
        value: "",
        imageId: "",
        comment: "",
        correct: false,
      })
    }
  }

  const handleCorrect = (index: number) => () => {
    fields.update(index, {
      ...fields.value[index],
      correct: !fields.value[index].correct,
    })
  }

  return (
    <>
      {fields.value &&
        fields.value.map(({ value, imageId, comment, correct }: Answer, index) => (
          <Container key={index}>
            <Row>
              <Condition when="answersType" is="text">
                <FreeInput
                  placeholder="Saisissez ici une réponse"
                  value={value}
                  onChange={handleChange(index, "value")}
                />
              </Condition>
              <Condition when="answersType" is="image">
                <FreeSelect value={imageId} onChange={handleChange(index, "imageId")}>
                  <option value="">Bibliothèque de ressources</option>
                  {images.map(({ id, label }) => (
                    <option key={id} value={id}>
                      {label}
                    </option>
                  ))}
                </FreeSelect>
              </Condition>
              {correct ? (
                <Space />
              ) : (
                <FreeInput
                  placeholder="Saisissez ici un commentaire"
                  value={comment}
                  onChange={handleChange(index, "comment")}
                />
              )}
            </Row>
            {value || imageId ? (
              <CorrectButton correct={correct} type="button" onClick={handleCorrect(index)}>
                <CheckIcon />
                <span>{correct ? "Réponse juste" : "Réponse fausse"}</span>
              </CorrectButton>
            ) : null}
          </Container>
        ))}
    </>
  )
}

export default AnswersField
