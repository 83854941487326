import React from "react"
import { useField } from "react-final-form"

import SimpleMap from "Authenticated/components/Map"

const MapField: React.FC = () => {
  const {
    input: { value: lat },
  } = useField<string>("lat", { subscription: { value: true } })
  const {
    input: { value: lng },
  } = useField<string>("lng", { subscription: { value: true } })
  const {
    input: { value: radius },
  } = useField<string>("radius", { subscription: { value: true } })

  if (!lat || !lng || !radius) {
    return null
  }

  return <SimpleMap lat={Number(lat)} lng={Number(lng)} radius={Number(radius)} />
}

export default MapField
