import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router-dom"

import Table, { Controls, Actions } from "Authenticated/components/Table"
import { LinkButton, IconLink, IconButton } from "components/buttons"
import { SearchFilter, SelectFilter } from "components/filterInputs"
import { Colors, RESOURCE_TYPES } from "helpers/constants"
import { Container } from "Authenticated/components/containers"
import Plus from "images/Plus"
import { ReactComponent as ConsultIcon } from "images/consult.svg"
import { ReactComponent as DeleteIcon } from "images/delete.svg"
import { dateFormat } from "helpers/date"
import { fulltextFilter } from "helpers/string"
import { useApi } from "helpers/hooks"
import api from "services/api"
import { useToast } from "components/toast"
import { getUserLabel } from "components/user"

const COLUMNS = [
  "Date",
  "Type de ressource",
  "Titre de la ressource",
  "Concepteur·rice",
  "Présence module",
  "Catégories",
  "",
]
const LARGE_COLUMNS = {
  2: true,
}

const Resources: React.FC<RouteComponentProps> = ({ history }) => {
  const { data: resources, loading, error, reload } = useApi<IResource[]>(`resources`)
  const { data: categories, loading: loadingCategories } = useApi<ICategory[]>(`categories`)
  const toast = useToast()

  const [search, setSearch] = useState("")
  const [type, setType] = useState<IResourceType>()
  const [category, setCategory] = useState<number>()

  const queryParameters = new URLSearchParams(window.location.search)
  const page = queryParameters.get("page")

  useEffect(() => {
    localStorage.removeItem("paginationResource")
  }, [])

  const updateUrl = (page: string) => {
    history.push(`/resources?page=${page}`)
  }

  const handleSearchChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(target.value)
  }

  const handleType = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    setType(target.value as IResourceType)
  }

  const handleCategory = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(Number(target.value))
  }

  const handleDelete =
    ({ id, used, hasStats }: IResource) =>
    () => {
      if (used) {
        alert(
          "Vous ne pouvez pas supprimer cette ressource car elle est utilisée dans un ou plusieurs modules."
        )
        return
      }
      let message = "Êtes-vous sûr·e de vouloir supprimer cette ressource ?"
      if (hasStats) {
        message += "\nAttention : cela effacera aussi ses statistiques d’utilisation !"
      }
      if (window.confirm(message)) {
        api
          .delete(`resources/${id}`)
          .then(() => {
            reload()
            toast("La ressource a bien été supprimée")
          })
          .catch((error) => {
            alert(`Erreur : ${error.message}`)
          })
      }
    }
  const rows =
    resources &&
    resources
      .filter((data) => {
        if (search && !fulltextFilter(search, [data.label])) {
          return false
        }
        if (category && !data.categories.find(({ id }) => id === category)) {
          return false
        }
        if (type && data.type !== type) {
          return false
        }
        return true
      })
      .map((resource) => {
        console.log("res: ", resource)
        return {
          row: [
            dateFormat(resource.createdAt),
            RESOURCE_TYPES[resource.type] || resource.type,
            resource.label,
            getUserLabel(resource.author, "Auteur supprimé"),
            resource.used ? "Utilisé" : "Non utilisé",
            resource.categories.map(({ label }) => label).join(", "),
            resource.type !== "scene" && (
              <>
                <Actions>
                  <IconLink
                    to={`/resources/${resource.id}`}
                    onClick={() => {
                      if (page) localStorage.setItem("paginationResource", page)
                    }}
                  >
                    <ConsultIcon />
                  </IconLink>
                  {(resource.isDeleteable == null ? true : resource.isDeleteable) && (
                    <IconButton onClick={handleDelete(resource)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Actions>
              </>
            ),
          ],
          data: resource,
        }
      })
      .reverse()

  return (
    <Container title="Bibliothèque de ressources">
      <Controls>
        <div>
          <SearchFilter
            value={search}
            placeholder="Rechercher une ressource"
            onChange={handleSearchChange}
          />
          <SelectFilter
            label="Filtrer par type"
            value={type}
            onChange={handleType}
            list={Object.keys(RESOURCE_TYPES).map((type) => ({
              id: type,
              label: RESOURCE_TYPES[type as IResourceType],
            }))}
          />
          <SelectFilter
            label="Filtrer par catégorie"
            value={category}
            onChange={handleCategory}
            loading={loadingCategories}
            list={categories}
          />
        </div>
        <LinkButton to="/resources/new" className="addResource">
          <Plus backgroundColor={Colors.white} color={Colors.yellow} width={20} />
          <span>Ajouter une ressource</span>
        </LinkButton>
      </Controls>
      <Table
        columns={COLUMNS}
        rows={rows}
        largeColumns={LARGE_COLUMNS}
        loading={loading}
        error={error}
        update={updateUrl}
        pageUrl={page}
      />
    </Container>
  )
}

export default Resources
