import styled from "styled-components"

import { Colors } from "helpers/constants"
import { ReactComponent as ProfileIcon } from "images/profile.svg"

export const UserIcon = styled(ProfileIcon)`
  margin-top: 30px;
  width: 90px;
  height: 90px;
  g {
    fill: ${Colors.background};
  }
  g > circle {
    stroke: ${Colors.blue};
  }
  g > path {
    fill: ${Colors.blue};
  }
`
export const UserName = styled.h1`
  color: ${Colors.blue};
  font-size: 1.5rem;
`
export const Content = styled.div`
  margin: 40px auto 60px;
  max-width: 600px;
`
export const Field = styled.div<{ fullwidth?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.fullwidth ? "column" : "row")};
  text-align: left;
  padding: ${(props) => (props.fullwidth ? "20px 0 5px" : "5px 0")};
  border-bottom: 1px solid ${Colors.separator};
`
export const Label = styled.div`
  font-weight: 500;
  flex: 1;
  padding: 5px 0;
`
export const Value = styled.div`
  flex: 1;
  padding: 5px 0;
`
