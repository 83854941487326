import React from "react"
import styled from "styled-components"
import { RouteComponentProps } from "react-router-dom"

import { ReactComponent as MailIcon } from "images/mail.svg"
import { Colors, HELP_EMAIL_ADDRESS } from "helpers/constants"
import { PageContainer, CloseButton, Content, SmallLogo, Title, Text } from "Anonymous/common"

const Contact = styled.p`
  margin: 30px 50px;
  border-top: 1px solid ${Colors.lightGrey};
  padding-top: 20px;
  margin-bottom: 50px;
`
const Link = styled.a`
  font-weight: 200;
  svg {
    vertical-align: middle;
    margin-right: 20px;
  }
`

const Help: React.FC<RouteComponentProps> = ({ history }) => {
  return (
    <PageContainer>
      <CloseButton />
      <Content>
        <SmallLogo />
        <Title>Besoin d’aide ? Contactez-nous !</Title>
        <Text>L’adresse contact est à utiliser pour toutes questions d’ordre technique.</Text>
        <Contact>
          <Link href={"mailto:" + HELP_EMAIL_ADDRESS}>
            <MailIcon />
            {HELP_EMAIL_ADDRESS}
          </Link>
        </Contact>
      </Content>
    </PageContainer>
  )
}

export default Help
