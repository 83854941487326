import React, { ReactNode } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"

import { H1 } from "components/titles"
import { Colors, Shadows } from "helpers/constants"
import Loader from "images/Loader"

export const Content = styled.div`
  margin-top: 50px;
`

const Wrapper = styled.div`
  position: relative;
`
const BackLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
`
const Code = styled.span`
  font-weight: 200;
  font-size: 0.8em;
  margin-left: 25px;
`

interface ContainerProps {
  title?: string
  code?: string
  back?: string
  children: ReactNode
}
export const Container = ({ title, code, back, children }: ContainerProps) => (
  <Wrapper>
    {title && (
      <H1>
        {title}
        {code && <Code>{code}</Code>}
      </H1>
    )}
    {back && <BackLink to={back}>Retour</BackLink>}
    {children}
  </Wrapper>
)

export const Paper = styled.div<{ center?: boolean }>`
  background: ${Colors.white};
  text-align: ${(props) => (props.center ? "center" : "left")};
  padding: 10px 30px 30px;
  border-radius: 5px;
  box-shadow: ${Shadows.paper};
`

export const ErrorMessage = styled.p`
  color: ${Colors.error};
`

const Empty = styled.div`
  padding: 150px 50px;
  text-align: center;
`

interface PlaceholderProps {
  loading?: boolean
  error?: Error
}

export const Placeholder: React.FC<PlaceholderProps> = ({ loading, error }) => (
  <Empty>
    {loading && <Loader color={Colors.text} />}
    {error && <ErrorMessage>Erreur : {error.message}</ErrorMessage>}
  </Empty>
)
