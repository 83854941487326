import React, { FC } from "react"
import styled, { css } from "styled-components"
import {
  Form as ReactFinalForm,
  FormProps,
  Field,
  FieldProps,
  FieldRenderProps,
} from "react-final-form"
import { AnyObject, FormApi, FORM_ERROR, Config } from "final-form"
import arrayMutators from "final-form-arrays"

import { Colors } from "helpers/constants"
import { SubmitButton } from "components/buttons"

export const Title = styled.h2`
  color: ${Colors.yellow};
  font-size: 1.25rem;
`
export const Caption = styled.p`
  font-size: 0.9rem;
  color: ${Colors.grey2};
`

export const CaptionRed = styled.p`
  font-size: 0.9rem;
  color: ${Colors.red};
`

const StyledForm = styled.form`
  max-width: 650px;
`

const result = css`
  padding: 10px;
  text-align: center;
`
const SubmitSuccess = styled.div`
  color: ${Colors.success};
  ${result}
`
const SubmitError = styled.div`
  color: ${Colors.error};
  ${result}
`

interface Props {
  submitLabel: string
  submitMessage?: string
  className?: string
  onSubmit: Config["onSubmit"]
}

export const Form: FC<Props & FormProps> = ({
  submitLabel,
  submitMessage,
  className,
  children,
  onSubmit,
  ...props
}) => {
  const submitWrapper = (values: AnyObject, form: FormApi<AnyObject>) => {
    const result = onSubmit(values, form)
    if (result && result.catch) {
      return result
        .catch((error: Error) => ({ [FORM_ERROR]: `Erreur : ${error.message}` }))
        .then((errors?: AnyObject) => {
          if (errors && errors[FORM_ERROR]) {
            window.scroll(0, document.body.scrollHeight) // make sure we see the error // TODO: improve UX
          }
          return errors
        })
    }
    return result
  }

  return (
    <ReactFinalForm
      onSubmit={submitWrapper}
      mutators={{ ...arrayMutators }}
      keepDirtyOnReinitialize
      {...props}
    >
      {({ handleSubmit, submitting, submitSucceeded, dirtySinceLastSubmit, submitError }) => (
        <StyledForm onSubmit={handleSubmit} className={className}>
          {children}
          <SubmitButton loading={submitting}>{submitLabel}</SubmitButton>
          {submitSucceeded && submitMessage && <SubmitSuccess>{submitMessage}</SubmitSuccess>}
          <SubmitError>{!dirtySinceLastSubmit && submitError}</SubmitError>
        </StyledForm>
      )}
    </ReactFinalForm>
  )
}

export const Row = styled.div`
  display: flex;
`

type InputProps = FieldProps<string, FieldRenderProps<string>> & {
  multiline?: boolean
}

const indent = (props: InputProps): string => {
  if (props.component === "textarea" || props.type === "file") {
    return "0"
  }
  if (props.component === "select") {
    return "8px; height: 42px" // TODO: see if this hack is OK in other browsers
  }
  return "15px"
}
const padding = (props: InputProps): string => {
  if (props.component === "textarea") {
    return "10px 15px"
  }
  if (props.type === "file") {
    return "9px 15px"
  }
  return "10px 0"
}

const fieldCss = css`
  flex: 1 1 50%;
  width: 100%;
  display: inline-block;
  border: 1px solid ${Colors.separator};
  border-radius: 3px;
  margin: 10px 20px 10px 0;
  padding: ${padding};
  text-indent: ${indent};
  &:last-child {
    margin-right: 0;
  }
`

const StyledField = styled(Field)`
  ${fieldCss}
`

export const TextInput: FC<InputProps> = ({ multiline, ...props }) => (
  <StyledField
    component={multiline ? "textarea" : "input"}
    type={multiline ? null : "text"}
    rows={multiline ? 4 : null}
    {...props}
  />
)

type SelectProps = FieldProps<string, FieldRenderProps<string>> & {
  placeholder?: string
  loading?: boolean
  error?: Error
}

export const Select: FC<SelectProps> = ({ placeholder, children, loading, error, ...props }) => {
  if (loading) {
    placeholder = "Chargement..."
  } else if (error) {
    placeholder = `Erreur : ${error.message}`
  }

  return (
    <StyledField component="select" {...props}>
      {placeholder && <option value="">{placeholder}</option>}
      {children}
    </StyledField>
  )
}

const Label = styled.label`
  display: block;
  margin: 10px 0;
`

export const Checkbox: FC<FieldProps<string, FieldRenderProps<string>>> = ({
  className,
  children,
  ...props
}) => (
  <Label className={className}>
    <Field component="input" type="checkbox" {...props} /> {children}
  </Label>
)

export const Space = styled.div`
  flex: 1 1 50%;
`

export const FreeInput = styled.input`
  ${fieldCss}
`

export const Textarea = styled.textarea`
  ${fieldCss}
`

export const FreeSelect = styled.select`
  ${fieldCss}
`
