import React, { useState, useRef } from "react"
import { RouteComponentProps } from "react-router-dom"
import { AnyObject, FormApi } from "final-form"

import { Container, Paper, Placeholder } from "Authenticated/components/containers"
import { Form, Title, Caption, Row, TextInput, Select } from "components/form"
import Condition from "components/form/Condition"
import api, { getRef } from "services/api"
import { useApi } from "helpers/hooks"
import { pick } from "helpers/object"
import { useToast } from "components/toast"
import {
  STEP_ENDPOINTS,
  STEP_TYPES,
  MAX_UPLOAD_SIZE,
  MIME_TYPES,
  FILE_RESOURCE_TYPES,
  MODE_TYPES,
} from "helpers/constants"
import { getFileExt } from "helpers/string"
import AnswersField, { Answer } from "components/form/AnswersField"
import FileField from "components/form/FileField"
import { useMode } from "Authenticated/components/mode"
import { SubmitButton } from "components/buttons"
import TagsField, { Tag } from "components/form/TagsField"
import MapField from "components/form/MapField"
import PoisField, { Poi } from "components/form/PoisField"

const SPECIFIC_FIELDS: Record<IStepType, string[]> = {
  simple: ["description"],
  file: ["description"],
  file360: [],
  scenario: ["scenarioId"],
  object3D: [],
  tagScene: [],
  gps: ["lat", "lng", "radius"],
  scene: ["sceneId"],
  exercice: ["question"],
}

const FILE_KEYS: Partial<Record<IStepType, string>> = {
  file: "file",
  file360: "file",
  object3D: "object3D",
  scene: "resource",
  scenario: "resource",
}

interface MatchParams {
  moduleId: string
  stepId?: string
}

const uploadResource = async (file: File, type: IResourceType) => {
  const form = new FormData()
  form.append("file", file)
  form.append("type", type)
  form.append("label", file.name)
  const resource = await api.post<IResource>("resource_files", form)
  return resource
}

const EditStep: React.FC<RouteComponentProps<MatchParams>> = ({ match, history }) => {
  const { moduleId, stepId } = match.params
  const module = useApi<IModule>(stepId && `modules/${moduleId}`)
  const resources = useApi<IResource[]>(`resources`)
  const { mode } = useMode()
  const toast = useToast()
  const [submitting, setSubmitting] = useState(false)
  const alternate = useRef(false)
  const back = `/modules/${moduleId}`

  let step: IStep | undefined
  if (stepId && module.data) {
    step = module.data.steps.find(({ id }) => id === Number(stepId))
  }

  if (stepId && (module.loading || module.error)) {
    return (
      <Container title="Modifier un module pédagogique" back={back}>
        <Placeholder loading={module.loading} error={module.error} />
      </Container>
    )
  }

  const fields: string[] = ["label", "type", "weight"]
  if (step) {
    fields.push(...SPECIFIC_FIELDS[step.type])
  }

  const initialValues = pick(step, fields as (keyof IStep)[])
  if (step) {
    switch (step.type) {
      case "file":
      case "file360":
        initialValues.resourceId = step.file.id
        break
      case "object3D":
        initialValues.resourceId = step.object3D.id
        break
      case "exercice":
        initialValues.answersType = step.responses[0] && step.responses[0].file ? "image" : "text"
        initialValues.responses = [
          ...step.responses.map(({ id, value, file, comment, correct }) => ({
            id,
            value: value || "",
            imageId: file ? file.id : "",
            comment: comment || "",
            correct: Boolean(correct),
          })),
          { value: "", imageId: "", comment: "", correct: false },
        ]
        break
      case "tagScene":
        initialValues.tagObject3Ds = step.tagObject3Ds.map(({ object3D, tag, isHorizontal }) => ({
          objectId: object3D.id,
          tagId: tag.id,
          horizontal: isHorizontal,
        }))
        break
      case "gps":
        initialValues.pois = step.pois.map(
          ({ lat, lng, object3D, label, description, picto, fileImage, is3DObject }) => ({
            lat,
            lng,
            label,
            is3DObject,
            pictoId: picto.id,
            // either object:
            objectId: object3D && object3D.id,
            // or image + description:
            imageId: fileImage && fileImage.id,
            description: description || "",
          })
        )
        break
    }
  } else {
    initialValues.multiple = "false"
    initialValues.answersType = "text"
    initialValues.responses = [{ value: "", imageId: "", comment: "", correct: false }]
    initialValues.tagObject3Ds = [{ objectId: "", tagId: "", horizontal: false }]
    initialValues.pois = [
      {
        lat: "",
        lng: "",
        label: "",
        is3DObject: false,
        description: "",
      },
    ]
    initialValues.radius = "" // meters
    initialValues.lat = ""
    initialValues.lng = ""
  }

  const handleSubmit = async (values: AnyObject, form: FormApi<AnyObject>) => {
    const payload: Record<string, any> = {
      label: values.label,
      module: getRef("modules", moduleId),
      weight: Number(values.weight),
    }

    const stepType = values.type as IStepType

    if (stepType === "simple" || stepType === "file") {
      payload.description = values.description
    }

    if (!values.resourceId && FILE_KEYS[stepType]) {
      const file: File = values.file
      if (!file) {
        throw new Error("Vous devez sélectionner un fichier ou une ressource")
      }
      let type: IResourceType | undefined
      switch (stepType) {
        case "file":
          type = FILE_RESOURCE_TYPES.find((resourceType) =>
            MIME_TYPES[resourceType].includes(file.type)
          )
          break
        case "file360":
          type = "360"
          break
        case "object3D":
          const ext = getFileExt(file.name)
          if (ext === "glb") {
            type = "object3D"
          }
          break
      }
      if (!type) {
        throw new Error("Type de fichier invalide")
      }
      const resource: IResource = await uploadResource(file, type)
      values.resourceId = resource.id
    }

    if (values.resourceId) {
      payload[FILE_KEYS[stepType] as string] = getRef(
        stepType === "scene" ? "resource_scenes" : "resource_files",
        values.resourceId
      )
    }

    if (stepType === "exercice") {
      payload.question = values.question
      payload.multiple = values.multiple === "true"
      payload.responses = (values.responses as Answer[])
        .map(({ id, value, imageId, comment, correct }) => {
          if (values.answersType === "text") {
            return { "@id": "/responses/" + id, value, comment, correct }
          } else {
            return {
              "@id": "/responses/" + id,
              file: getRef("resource_files", imageId),
              comment,
              correct,
            }
          }
        })
        .filter(({ value, file }) => value || file)
    }

    if (stepType === "tagScene") {
      payload.tagObject3Ds = (values.tagObject3Ds as Tag[])
        .filter(({ tagId, objectId }, index) => {
          if (tagId && !objectId) {
            throw new Error(`Vous devez sélectionner un objet 3D pour l’objet N°${index + 1}`)
          }
          if (objectId && !tagId) {
            throw new Error(`Vous devez sélectionner un tag pour l’objet N°${index + 1}`)
          }
          return objectId && tagId
        })
        .map(({ tagId, objectId, horizontal }) => ({
          tag: getRef("resource_files", tagId),
          object3D: getRef("resource_files", objectId),
          isHorizontal: horizontal,
        }))
    }

    if (stepType === "gps") {
      payload.lat = values.lat
      payload.lng = values.lng
      payload.radius = Number(values.radius)
      payload.pois = (values.pois as Poi[]).map(
        ({ lat, lng, label, is3DObject, pictoId, objectId, imageId, description }) => {
          const poi: Record<string, any> = {
            label,
            lat,
            lng,
            is3DObject,
            picto: getRef("resource_files", pictoId),
          }
          if (is3DObject) {
            poi.object3D = getRef("resource_files", objectId)
          } else {
            poi.fileImage = getRef("resource_files", imageId)
            poi.description = description
          }
          return poi
        }
      )
    }

    setSubmitting(true)

    const endpoint = STEP_ENDPOINTS[values.type as IStepType]
    if (stepId) {
      await api.put<IStep>(`${endpoint}/${stepId}`, payload)
      setSubmitting(false)
      history.push(`/modules/${moduleId}`)
    } else {
      await api.post<IStep>(endpoint, payload)
      setSubmitting(false)
      if (alternate.current) {
        history.push(`/modules/${moduleId}`)
      } else {
        setTimeout(() => {
          // https://github.com/final-form/react-final-form/issues/670
          // https://github.com/final-form/final-form/issues/151#issuecomment-425867172
          form.setConfig("keepDirtyOnReinitialize", false)
          form.reset()
          form.setConfig("keepDirtyOnReinitialize", true)
          alternate.current = false
        }, 100)
      }
    }
    toast(stepId ? "L’étape a bien été modifiée" : "L’étape a bien été été créée")
  }

  const ResourceRow: React.FC<{ types: IResourceType[] }> = ({ types }) => (
    <>
      <Condition when="file" isEmpty>
        <Row>
          <Select
            name="resourceId"
            placeholder="Ma bibliothèque de ressources"
            loading={resources.loading}
            error={resources.error}
          >
            {resources.data &&
              resources.data
                .filter(({ type }) => types.includes(type))
                .map(({ id, label, type }) => (
                  <option key={id} value={id}>
                    {label}
                    {types.length > 1 && ` (${type})`}
                  </option>
                ))}
          </Select>
        </Row>
      </Condition>
      <Condition when="resourceId" isEmpty>
        <Row>
          <FileField
            name="file"
            accept={types.map((type) => MIME_TYPES[type]).toString() || null}
          />
        </Row>
      </Condition>
    </>
  )

  const handleAlternate = () => {
    alternate.current = true
  }

  const filteredResources = (resourceType: IResourceType): IResource[] =>
    resources.data ? resources.data.filter(({ type }) => type === resourceType) : []

  const objects3d = filteredResources("object3D") as IFileResource[]

  return (
    <Container title="Modifier un module pédagogique" back={back}>
      <Paper>
        <Form
          initialValues={initialValues}
          submitLabel="Enregistrer et créer une nouvelle étape"
          onSubmit={handleSubmit}
        >
          <Title>Ajouter un titre à cette étape</Title>
          <Row>
            <TextInput required name="label" placeholder="Saisissez ici le nom de l’étape" />
          </Row>
          <Title>Choisir un type de ressource</Title>
          <Row>
            <Select required name="type" placeholder="Sélectionnez le type" disabled={!!step}>
              {MODE_TYPES[mode].map((type) => (
                <option key={type} value={type}>
                  {STEP_TYPES[type]}
                </option>
              ))}
            </Select>
          </Row>

          <Condition when="type" is="simple">
            <Title>Ajouter une description</Title>
            <Row>
              <TextInput multiline name="description" placeholder="Saisissez ici la description" />
            </Row>
          </Condition>

          <Condition when="type" is="file">
            <Title>Ajouter un fichier</Title>
            <Caption>
              Sélectionnez un fichier depuis votre poste de travail ou depuis la bibliothèque de
              ressources.
              <br />
              Formats autorisés : PDF, JPG, PNG, MP4, MP3.
              <br />
              Taille maximale autorisée : {MAX_UPLOAD_SIZE} Mo.
            </Caption>
            <ResourceRow types={["image", "audio", "video", "pdf"]} />
            <Title>Ajouter une description</Title>
            <Row>
              <TextInput multiline name="description" placeholder="Saisissez ici la description" />
            </Row>
          </Condition>

          <Condition when="type" is="file360">
            <Title>Ajouter un fichier</Title>
            <Caption>
              Sélectionnez un fichier depuis votre poste de travail ou depuis la bibliothèque de
              ressources.
              <br />
              Taille maximale autorisée : {MAX_UPLOAD_SIZE} Mo.
            </Caption>
            <ResourceRow types={["360"]} />
          </Condition>

          <Condition when="type" is="object3D">
            <Title>Ajouter un fichier</Title>
            <Caption>
              Sélectionnez un fichier depuis votre poste de travail ou depuis la bibliothèque de
              ressources.
              <br />
              Format autorisé : GLB.
              <br />
              Taille maximale autorisée : {MAX_UPLOAD_SIZE} Mo.
            </Caption>
            <ResourceRow types={["object3D"]} />
          </Condition>

          <Condition when="type" is="tagScene">
            <Title>Ajouter un objet 3D via tag</Title>
            <TagsField
              name="tagObject3Ds"
              objects={objects3d}
              tags={filteredResources("tag") as IFileResource[]}
            />
          </Condition>

          <Condition when="type" is="gps">
            <Title>Ajouter une localisation</Title>
            <Caption>
              Indiquez les coordonnées GPS du lieu de référence en précisant la Latitude, la
              Longitude et le Rayon pour la distance maximale à observer. Ces indications vous
              serviront de base pour faire apparaître les points d’intérêt désirés en réalité
              augmentée.
            </Caption>
            <Row>
              <TextInput name="lat" type="number" step="any" placeholder="Latitude (décimale)" />
              <TextInput name="lng" type="number" step="any" placeholder="Longitude (décimale)" />
              <TextInput
                name="radius"
                type="number"
                min={0}
                step={1}
                placeholder="Rayon (en mètres)"
              />
            </Row>
            <MapField />
            <Title>Ajouter des points d’intérêt</Title>
            <PoisField
              name="pois"
              objects={objects3d}
              images={filteredResources("image") as IFileResource[]}
              pictos={filteredResources("picto_poi") as IFileResource[]}
            />
          </Condition>

          <Condition when="type" is="scene">
            <Title>Ajouter un fichier</Title>
            <Caption>Sélectionnez un fichier depuis la bibliothèque de ressources.</Caption>
            <Row>
              <Select
                required
                name="resourceId"
                placeholder="Ma bibliothèque de ressources"
                loading={resources.loading}
                error={resources.error}
              >
                {filteredResources("scene").map(({ id, label }) => (
                  <option key={id} value={id}>
                    {label}
                  </option>
                ))}
              </Select>
            </Row>
          </Condition>

          <Condition when="type" is="scenario">
            <Title>Ajouter un fichier</Title>
            <Caption>Sélectionnez un fichier depuis la bibliothèque de ressources.</Caption>
            <Row>
              <Select
                required
                name="resourceId"
                placeholder="Ma bibliothèque de ressources"
                loading={resources.loading}
                error={resources.error}
              >
                {filteredResources("scenario").map(({ id, label }) => (
                  <option key={id} value={id}>
                    {label}
                  </option>
                ))}
              </Select>
            </Row>
          </Condition>

          <Condition when="type" is="exercice">
            <Title>Ajouter un quiz</Title>
            <Caption>
              Saisissez votre première question dans le pavé texte ci-après. Puis sélectionnez les
              paramètres de la question. Renseignez les bonnes et mauvaises réponses. Vous pouvez
              indiquer un commentaire pour chaque mauvaise réponse. Cliquez sur la case "Réponse
              fausse" pour changer son état et la faire passer à l’état de "Réponse juste".
              ATTENTION : Pour rajouter des questions au quiz, vous devez à chaque fois constituer
              une nouvelle étape dans le module. 1 question au quiz = 1 étape.
            </Caption>
            <Row>
              <TextInput name="question" placeholder="Saisissez ici votre question" />
            </Row>
            <Row>
              <Select name="multiple">
                <option value="false">Question à choix unique</option>
                <option value="true">Question à choix multiples</option>
              </Select>
              <Select name="answersType">
                <option value="text">Contenu texte</option>
                <option value="image">Contenu images</option>
              </Select>
            </Row>
            <AnswersField name="responses" images={filteredResources("image") as IFileResource[]} />
          </Condition>

          <Title>Définir le numéro de l’étape</Title>
          <Caption>Choisissez l’emplacement de cette étape</Caption>
          <Row>
            <TextInput
              required
              name="weight"
              type="number"
              min={1}
              step={1}
              placeholder="Saisissez ici le numéro de l’étape"
            />
          </Row>
          <SubmitButton loading={submitting} variant="white" onClick={handleAlternate}>
            Enregistrer et accéder à l’écran de synthèse
          </SubmitButton>
        </Form>
      </Paper>
    </Container>
  )
}

export default EditStep
