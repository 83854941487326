import React from "react"
import { RouteComponentProps } from "react-router-dom"
import styled from "styled-components"

import { Container, Paper, Placeholder } from "Authenticated/components/containers"
import { UserIcon, UserName, Content, Field, Label, Value } from "Authenticated/components/user"
import { LinkButton } from "components/buttons"
import { ROLES, Colors, PROFILES } from "helpers/constants"
import { getUserLabel } from "components/user"
import { useApi } from "helpers/hooks"

const Status = styled.div<{ active: boolean }>`
  text-align: left;
  color: ${(props) => (props.active ? Colors.success : Colors.error)};
  margin-top: 15px;
  font-weight: 500;
  font-size: 1.2rem;
`

interface MatchParams {
  id: string
}

const User: React.FC<RouteComponentProps<MatchParams>> = ({ match, history }) => {
  const { id } = match.params
  const { data, loading, error } = useApi<IUser>(`users/${id}`)

  if (!data) {
    return (
      <Container title="Profil utilisateur·rice" back="/users/list">
        <Placeholder loading={loading} error={error} />
      </Container>
    )
  }

  const isStudent = !data.roles.find((role) => role === "ROLE_ADMIN" || role === "ROLE_TEACHER")
  const page = localStorage.getItem("paginationUser")
  const linkBack = page ? `/users/list?page=${page}` : `/users/list`
  return (
    <Container title="Profil utilisateur·rice" back={linkBack}>
      <Paper center>
        <Status active={data.isActive}>Compte {data.isActive ? "actif" : "inactif"}</Status>
        <UserIcon />
        <UserName>{getUserLabel(data)}</UserName>
        <Content>
          <Field>
            <Label>Rôle</Label>
            <Value>
              {data.roles && data.roles.map((role: string) => ROLES[role as IUserRole]).join(", ")}
            </Value>
          </Field>
          <Field>
            <Label>Adresse e-mail</Label>
            <Value>{data.mail}</Value>
          </Field>
          {!isStudent && (
            <>
              <Field>
                <Label>Profil</Label>
                <Value>{PROFILES[data.profile] || data.profile}</Value>
              </Field>
              <Field>
                <Label>Établissement</Label>
                <Value>{data.school}</Value>
              </Field>
            </>
          )}
          <Field fullwidth>
            <Label>Utilisation de la plateforme ARTEfac</Label>
            <Value>{data.whyApp || "-"}</Value>
          </Field>
        </Content>
        <LinkButton to={`/users/${id}/edit`}>Modifier</LinkButton>
      </Paper>
    </Container>
  )
}

export default User
