import React from "react"
import { RouteComponentProps } from "react-router-dom"

import { Paper } from "Authenticated/components/containers"
import { Title } from "./components"

const HelpStats: React.FC<RouteComponentProps> = () => {
  return (
    <Paper>
      <Title>Statistiques</Title>
      <p>Vous avez accès aux statistiques de l’ensemble des utilisateur·rice·s :</p>
      <ul>
        <li>Le temps moyen passé par module,</li>
        <li>La progression moyenne dans un module,</li>
        <li>Le nombre de quiz commencés en moyenne,</li>
        <li>Les résultats aux quiz cumulés pour tou·te·s les étudiant·e·s,</li>
        <li>Le nombre de modules consultés sur l’année.</li>
      </ul>
      <p>
        Vous pouvez visualiser les statistiques d’un·e utilisateur·rice en saisissant son nom et son
        prénom dans la barre de recherche. Vous pouvez filtrer les statistiques de cet·te étudiant·e
        sur un module souhaité en choisissant le module dans le menu déroulant.
      </p>
    </Paper>
  )
}

export default HelpStats
