import React from "react"
import styled from "styled-components"
import { RouteComponentProps } from "react-router-dom"
import { AnyObject } from "final-form"

import { Container, Paper, Placeholder } from "Authenticated/components/containers"
import { Form, Title, Row, TextInput, Select, Space } from "components/form"
import Condition from "components/form/Condition"
import { ROLES, PROFILES, Colors } from "helpers/constants"
import api from "services/api"
import { useApi } from "helpers/hooks"
import { pick } from "helpers/object"
import { useToast } from "components/toast"

const StateSelect = styled(Select)`
  background-color: ${Colors.blue};
  color: ${Colors.white};
`

interface MatchParams {
  id: string
}

const EditUser: React.FC<RouteComponentProps<MatchParams>> = ({ match, history }) => {
  const { id } = match.params
  const { data, loading, error } = useApi<IUser>(id && `users/${id}`)
  const toast = useToast()

  const page = localStorage.getItem("paginationUser")
  const linkBack = page ? `/users/list?page=${page}` : `/users/list`

  if (loading || error) {
    return (
      <Container title="Modifier un·e utilisateur·rice">
        <Placeholder loading={loading} error={error} />
      </Container>
    )
  }

  const initialValues = pick(data, ["firstname", "lastname", "mail", "profile", "school"])
  if (data) {
    initialValues.role = data.roles[0]
    initialValues.state = data.isActive ? "active" : "inactive"
  }

  const handleSubmit = async (values: AnyObject) => {
    if (values.password && values.password !== values.confirmPassword) {
      throw new Error("Les mots de passe ne correspondent pas")
    }
    var payload = {}
    if (values.password) {
      payload = {
        ...values,
        plainPassword: values.password ? values.password : null,
        roles: [values.role],
        isActive: values.state === "active",
      }
    } else {
      payload = {
        ...values,
        roles: [values.role],
        isActive: values.state === "active",
      }
    }

    if (id) {
      await api.put<IUser>(`users/${id}`, payload)
    } else {
      await api.post<IUser>("users", payload)
    }
    history.push(linkBack)
    toast(id ? "L’utilisateur·rice a bien été modifié·e" : "L’utilisateur·rice a bien été créé·e")
  }

  return (
    <Container
      title={id ? "Modifier un utilisateur·rice" : "Nouvel utilisateur·rice"}
      back={linkBack}
    >
      <Paper>
        <Form initialValues={initialValues} submitLabel="Enregistrer" onSubmit={handleSubmit}>
          <Title>Informations personnelles de l’utilisateur·rice</Title>
          <Row>
            <Select required name="role" placeholder="Rôle">
              {Object.keys(ROLES).map((role) => (
                <option key={role} value={role}>
                  {ROLES[role as IUserRole]}
                </option>
              ))}
            </Select>
            <Space />
          </Row>
          <Row>
            <TextInput required name="lastname" placeholder="Nom" />
            <TextInput required name="firstname" placeholder="Prénom" />
          </Row>
          <Row>
            <TextInput required type="email" name="mail" placeholder="Adresse e-mail" />
          </Row>
          <Row>
            <TextInput
              required={!id}
              type="password"
              name="password"
              placeholder="Choisir un mot de passe"
            />
            <TextInput
              required={!id}
              type="password"
              name="confirmPassword"
              placeholder="Confirmation du mot de passe"
            />
          </Row>
          <Condition when="role" is="ROLE_TEACHER">
            <Row>
              <Select required name="profile" placeholder="Choisissez un profil">
                {Object.keys(PROFILES).map((key) => (
                  <option key={key} value={key}>
                    {PROFILES[key as IUserProfile]}
                  </option>
                ))}
              </Select>
              <TextInput required name="school" placeholder="Nom de l’établissement scolaire" />
            </Row>
          </Condition>
          <Row>
            <StateSelect required name="state">
              <option value="inactive">Compte inactif</option>
              <option value="active">Compte actif</option>
            </StateSelect>
            <Space />
          </Row>
        </Form>
      </Paper>
    </Container>
  )
}

export default EditUser
