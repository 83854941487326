import React from "react"
import styled from "styled-components"
import { Link, RouteComponentProps } from "react-router-dom"
import { AnyObject } from "final-form"

import { SidesContainer, Left, Logo, Title, Right, Help, Actions, Paper } from "Anonymous/common"
import { Form, Row, TextInput, Select, Checkbox } from "components/form"
import { LinkButton } from "components/buttons"
import api from "services/api"
import { PROFILES } from "helpers/constants"

const BoldLink = styled(Link)`
  font-weight: 500;
`
const TosCheckbox = styled(Checkbox)`
  margin-bottom: 30px;
`

const Register: React.FC<RouteComponentProps> = ({ history }) => {
  const handleSubmit = async (values: AnyObject) => {
    if (values.password !== values.confirmPassword) {
      throw new Error("Les mots de passe ne correspondent pas")
    }
    if (!values.tos) {
      throw new Error("Vous devez accepter les conditions générales")
    }
    const user = await api.post<ISession>("users", values)
    history.replace("/confirmation", user)
  }

  const initialValues = {
    roles: ["ROLE_TEACHER"], // TODO: should be done API side
  }

  return (
    <SidesContainer>
      <Left>
        <Logo />
        <Title>Bienvenue dans l’environnement numérique de conception pédagogique ARTEfac.</Title>
        <Actions>
          <LinkButton to="/login">Déjà un compte ? Se connecter</LinkButton>
        </Actions>
      </Left>
      <Right>
        <Paper>
          <Form initialValues={initialValues} submitLabel="Je m'inscris" onSubmit={handleSubmit}>
            <Title>Je crée mon espace personnel</Title>
            <Row>
              <TextInput required name="lastname" placeholder="Nom" />
              <TextInput required name="firstname" placeholder="Prénom" />
            </Row>
            <Row>
              <TextInput required type="email" name="mail" placeholder="Adresse e-mail" />
            </Row>
            <Row>
              <TextInput
                required
                type="password"
                name="password"
                placeholder="Créez votre mot de passe"
              />
              <TextInput
                required
                type="password"
                name="confirmPassword"
                placeholder="Validez votre mot de passe"
              />
            </Row>
            <Row>
              <Select required name="profile" placeholder="Choisissez un profil">
                {Object.keys(PROFILES).map((key) => (
                  <option key={key} value={key}>
                    {PROFILES[key as IUserProfile]}
                  </option>
                ))}
              </Select>
              <TextInput name="school" placeholder="Etablissement d’exercice" />
            </Row>
            <Row>
              <TextInput
                multiline
                name="whyApp"
                placeholder="Pourquoi souhaitez-vous créer votre espace concepteur·rice ?"
              />
            </Row>
            <TosCheckbox name="tos">
              J’accepte les{" "}
              <BoldLink to="/tos" target="_blank">
                Conditions Générales d’Utilisation
              </BoldLink>{" "}
              de l’application
            </TosCheckbox>
          </Form>
        </Paper>
        <Help to="/help">Besoin d’aide ?</Help>
      </Right>
    </SidesContainer>
  )
}

export default Register
