import React from "react"
import styled from "styled-components"
import { Link, RouteComponentProps } from "react-router-dom"
import { AnyObject } from "final-form"

import { SidesContainer, Left, Logo, Title, Right, Help, Actions, Paper } from "Anonymous/common"
import { Form, Row, TextInput } from "components/form"
import { LinkButton } from "components/buttons"
import api from "services/api"
import { useSession } from "components/user"
import { Disclaimer } from "./LostPassword"

const Narrow = styled.div`
  margin: 0 auto 20px;
  max-width: 335px;
`
const LostPassword = styled.div`
  text-align: right;
  font-size: 12px;
`

const Login: React.FC<RouteComponentProps> = () => {
  const { setSession } = useSession()

  const handleSubmit = async (values: AnyObject) => {
    const session = await api.post<IAuthResponse>("login_check", values)
    setSession({
      user: session.data,
      token: session.token,
      refresh_token: session.refresh_token,
    })
  }

  return (
    <SidesContainer>
      <Left>
        <Logo />
        <Title>Bienvenue dans l’environnement numérique de conception pédagogique ARTEfac.</Title>
        <Actions>
          <LinkButton to="/register">Pas encore de compte ? Créer mon compte</LinkButton>
        </Actions>
      </Left>
      <Right>
        <Paper>
          <Narrow>
            <Form submitLabel="Je me connecte" onSubmit={handleSubmit}>
              <Title>Je me connecte à mon espace personnel</Title>
              <Disclaimer>Renseignez votre identifiant et votre mot de passe</Disclaimer>
              <Row>
                <TextInput
                  required
                  type="email"
                  name="mail"
                  placeholder="Saisissez votre adresse e-mail"
                />
              </Row>
              <Row>
                <TextInput
                  required
                  type="password"
                  name="password"
                  placeholder="Saisissez votre mot de passe"
                />
              </Row>
            </Form>
            <LostPassword>
              <Link to="/password">Mot de passe oublié ?</Link>
            </LostPassword>
          </Narrow>
        </Paper>
        <Help to="/help">Besoin d’aide ?</Help>
      </Right>
    </SidesContainer>
  )
}

export default Login
