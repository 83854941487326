import React from "react"
import styled from "styled-components"

import { getUrl } from "services/api"

const Image = styled.img`
  max-width: 100%;
  max-height: 80vh;
`
const Video = styled.video`
  width: 100%;
`
const Audio = styled.audio`
  width: 100%;
`
const Iframe = styled.iframe`
  width: 100%;
  height: 95vh;
  border: none;
`

interface Props {
  data: IResource
}

const ResourceFile: React.FC<Props> = ({ data }) => {
  if (data.type === "scene") {
    return (
      <p>
        Identifiant : <code>{data.sceneId}</code>
      </p>
    )
  }

  const src = getUrl(data.contentUrl)

  switch (data.type) {
    case "image":
    case "360":
    case "picto_poi":
    case "tag":
      return <Image src={src} alt="" />
    case "video":
      return <Video controls src={src} />
    case "audio":
      return <Audio controls src={src} />
    case "object3D":
      return (
        <p>
          <a href={src}>Télécharger le fichier</a>
        </p>
      )
    case "scenario":
      return (
        <p>
          <a href={src}>Télécharger le fichier</a>
        </p>
      )
    case "pdf":
      return <Iframe src={src} />
    default:
      return <p>Le type de ressource "{data.type}" est inconnu.</p>
  }
}

export default ResourceFile
