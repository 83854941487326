import React from "react"

import { Placeholder } from "Authenticated/components/containers"
import { useApi } from "helpers/hooks"

import {
  Container,
  Row,
  Cell,
  Content,
  Title,
  Value,
  Indicator,
  Label,
  Widget,
  Categories,
} from "Authenticated/components/Widget"
import LineChart from "Authenticated/components/LineChart"
import BarChart from "Authenticated/components/BarChart"
import { MONTHS, RESOURCE_TYPES } from "helpers/constants"
import { getUrl } from "services/api"
import { pluralize } from "helpers/string"

interface IMonthStats {
  year: string // 20xx
  month: string // month (1..12)
  nbSession: string // number of visits
}
interface ICategoryStats {
  category: ICategory
  nbModules: string // number
}
interface ITypeStats {
  type: (IFileResource | ISceneResource)["type"]
  nbResources: string // number
}
interface IAdminStats {
  connexionsPerMonth?: IMonthStats[]
  minutesPerTeacher: number // seconds!
  beginnerModeConnexions: number // visits ended in beginner mode
  advancedModeConnexions: number // visits ended in advanced mode
  createdModules: number
  createdModulePerCategories?: ICategoryStats[]
  moduleCreationTry: number
  mediaPerModule: number
  mediaPerModulePerType?: ITypeStats[]
}

interface Props {
  userId?: number
  moduleId?: number
}

const AdminStats: React.FC<Props> = ({ userId, moduleId }) => {
  const params = []
  if (userId) {
    params.push(`user=${userId}`)
  }
  if (moduleId) {
    params.push(`module=${moduleId}`)
  }
  const { data, loading, error } = useApi<IAdminStats>(`stats_admins/get?${params.join("&")}`)

  if (!data) {
    return <Placeholder loading={loading} error={error} />
  }

  const visitData = data.connexionsPerMonth?.map(({ month, nbSession }) => ({
    name: MONTHS[month],
    primary: Number(nbSession),
  }))

  const mediaData = data.mediaPerModulePerType
    ?.filter(({ type }) => !["scene", "tag", "picto_poi"].includes(type))
    .map(({ type, nbResources }) => ({
      name: RESOURCE_TYPES[type],
      primary: Number(nbResources),
    }))

  const userSuffix = userId ? "" : "pour tous les concepteurs"
  const moduleSuffix = moduleId ? "" : "en moyenne"

  const minutes = Math.round(data.minutesPerTeacher / 60)
  const medias = Math.round(data.mediaPerModule)

  return (
    <Container>
      {!moduleId && (
        <>
          <Row>
            <Widget big title={`Nombre de connexions au Back Office ${userSuffix}`}>
              <LineChart data={visitData} />
            </Widget>
            <Cell>
              <Content>
                <Title>Temps moyen passé sur le Back Office {userSuffix}</Title>
                <Value>
                  <Indicator>{minutes}</Indicator>
                  {pluralize("Minute", minutes)}
                </Value>
              </Content>
              <Content>
                <Title>Nombre de connexions par mode</Title>
                <Value>
                  <Indicator>{data.advancedModeConnexions}</Indicator>
                  <Label>Mode avancé</Label>
                  <Indicator>{data.beginnerModeConnexions}</Indicator>
                  <Label>Mode débutant</Label>
                </Value>
              </Content>
            </Cell>
          </Row>
          <Row>
            <Widget
              title="Nombre de modules créés au total"
              value={data.createdModules}
              label={pluralize("Module", data.createdModules)}
            />
            <Cell>
              <Content>
                <Title>Nombre de modules créés par catégorie</Title>
                <Categories>
                  {data.createdModulePerCategories?.map(({ category, nbModules }) => (
                    <span key={category.id} title={category.label}>
                      <img src={getUrl(category.contentUrl)} alt={category.label} />
                      {nbModules} {pluralize("module", Number(nbModules))}
                    </span>
                  ))}
                </Categories>
              </Content>
            </Cell>
            <Widget
              title="Nombre de tentatives de création de modules"
              value={data.moduleCreationTry}
              label={pluralize(`Tentative`, data.moduleCreationTry)}
            />
          </Row>
        </>
      )}
      <Row>
        <Widget
          title={`Nombre de médias utilisés ${moduleSuffix} par module`}
          value={medias}
          label={pluralize("Média", medias)}
        />
        <Cell>
          <Content>
            <Title>Nombre de médias utilisés {moduleSuffix} par module</Title>
            <BarChart data={mediaData} />
          </Content>
        </Cell>
        <Cell />
      </Row>
    </Container>
  )
}

export default AdminStats
