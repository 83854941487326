import React from "react"

import { Colors } from "helpers/constants"

interface Props {
  bgColor?: string
}

const VideoIcon: React.FC<Props> = ({ bgColor, ...props }) => (
  <svg viewBox="0 0 77 77" {...props}>
    <circle
      stroke="#FFFEFE"
      fill={bgColor || Colors.blue}
      fillRule="nonzero"
      cx="37.5"
      cy="37.5"
      r="37.5"
    />
    <g fill="none" fillRule="nonzero">
      <path
        d="M56.043 17.835H18.957A3.365 3.365 0 0 0 15.59 21.2v30.236a3.365 3.365 0 0 0 3.366 3.366h37.027a3.365 3.365 0 0 0 3.366-3.366V21.201c.06-1.89-1.476-3.366-3.307-3.366z"
        fill="#324A5E"
      />
      <path
        fill="#FFF"
        d="M15.591 25.098H59.41V47.54H15.591zM24.094 19.783h-2.893c-.177 0-.295.119-.295.296v2.834c0 .178.118.296.295.296h2.893c.178 0 .296-.118.296-.296V20.08c0-.177-.118-.296-.296-.296zM31.535 19.783h-2.893c-.177 0-.296.119-.296.296v2.834c0 .178.119.296.296.296h2.893c.178 0 .296-.118.296-.296V20.08c0-.177-.177-.296-.296-.296zM38.917 19.783h-2.893c-.178 0-.296.119-.296.296v2.834c0 .178.118.296.296.296h2.893c.177 0 .296-.118.296-.296V20.08c0-.177-.119-.296-.296-.296zM46.358 19.783h-2.893c-.178 0-.296.119-.296.296v2.834c0 .178.118.296.296.296h2.893c.177 0 .296-.118.296-.296V20.08c0-.177-.119-.296-.296-.296zM53.8 19.783h-2.894c-.178 0-.296.119-.296.296v2.834c0 .178.118.296.296.296h2.893c.177 0 .295-.118.295-.296V20.08c0-.177-.177-.296-.295-.296zM24.094 49.488h-2.893c-.177 0-.295.118-.295.295v2.835c0 .177.118.295.295.295h2.893c.178 0 .296-.118.296-.295v-2.835c0-.177-.118-.295-.296-.295zM31.535 49.488h-2.893c-.177 0-.296.118-.296.295v2.835c0 .177.119.295.296.295h2.893c.178 0 .296-.118.296-.295v-2.835c0-.177-.177-.295-.296-.295zM38.917 49.488h-2.893c-.178 0-.296.118-.296.295v2.835c0 .177.118.295.296.295h2.893c.177 0 .296-.118.296-.295v-2.835c0-.177-.119-.295-.296-.295zM46.358 49.488h-2.893c-.178 0-.296.118-.296.295v2.835c0 .177.118.295.296.295h2.893c.177 0 .296-.118.296-.295v-2.835c0-.177-.119-.295-.296-.295zM53.8 49.488h-2.894c-.178 0-.296.118-.296.295v2.835c0 .177.118.295.296.295h2.893c.177 0 .295-.118.295-.295v-2.835c0-.177-.177-.295-.295-.295z"
      />
      <circle fill="#E6E9EE" cx="37.5" cy="36.319" r="7.677" />
      <path
        d="M41.457 35.61l-4.96-4.075c-.591-.531-1.536-.059-1.536.71v8.149c0 .826.945 1.24 1.535.708l4.96-4.074a.886.886 0 0 0 0-1.418z"
        fill="#FF7058"
      />
    </g>
  </svg>
)

export default VideoIcon
