import React from "react"
import styled from "styled-components"
import {
  BarChart as Chart,
  ResponsiveContainer,
  BarChartProps,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts"

import { Colors } from "helpers/constants"

interface Props {
  data: BarChartProps["data"]
}

const StyledChart = styled(Chart)`
  font-size: 14px;
`

const BarChart: React.FC<Props> = ({ data }) => (
  <ResponsiveContainer>
    <StyledChart data={data} margin={{ bottom: 50 }} layout="vertical">
      <XAxis type="number" dataKey="primary" stroke={Colors.grey} hide />
      <YAxis
        type="category"
        dataKey="name"
        stroke={Colors.blue}
        interval={0}
        minTickGap={0}
        width={80}
        tickLine={false}
        axisLine={false}
      />
      <Bar dataKey="primary" fill={Colors.blue} maxBarSize={6} background />
      <Tooltip separator="" cursor={false} formatter={(value) => [value]} />
    </StyledChart>
  </ResponsiveContainer>
)

export default BarChart
