import React from "react"
import styled, { css } from "styled-components"
import { ReactComponent as SearchIcon } from "images/search.svg"
import { ReactComponent as FilterIcon } from "images/filter.svg"
import { Colors, Shadows } from "helpers/constants"

const baseCss = css`
  background-color: ${Colors.white};
  display: inline-block;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  box-shadow: ${Shadows.paper};
  margin-right: 20px;
  height: 50px;
  white-space: nowrap;
`

const SearchInput = styled.div`
  ${baseCss}
  width: 270px;

  svg {
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 10px;
    stroke: ${Colors.button};
  }

  input {
    border: none;
    height: 30px;
    outline: none;
    vertical-align: middle;

    font-size: 15px;
    font-weight: 500;
    width: calc(100% - 50px);
    color: ${Colors.button};
    &::placeholder {
      color: ${Colors.button};
    }
  }
`

export const SearchFilter: React.FC<React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>> = ({ className, ...props }) => {
  return (
    <SearchInput className={className}>
      <SearchIcon />
      <input {...props} />
    </SearchInput>
  )
}

const SelectInput = styled.div`
  ${baseCss}
  width: 240px;

  svg {
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 10px;
    fill: ${Colors.button};
  }

  select {
    border: none;
    outline: none;
    font-size: 15px;
    font-weight: 500;
    height: 100%;
    width: calc(100% - 50px);
    background-color: transparent;
    vertical-align: middle;
    color: ${Colors.button};
  }

  option {
    border: none;
    border: none;
    outline: none;
    font-size: 15px;
    font-weight: 500;
  }
`

type SelectFilterProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>

interface ListItem {
  id: number | string
  label: string
}

interface FilterProps extends SelectFilterProps {
  label: string
  list?: ListItem[]
  loading?: boolean
}

export const SelectFilter: React.FC<FilterProps> = ({
  className,
  label,
  list,
  loading,
  ...props
}) => (
  <SelectInput className={className}>
    <FilterIcon />
    <select disabled={loading} {...props}>
      <option value="">{loading ? "Chargement..." : label}</option>
      {list &&
        list.map(({ id, label }) => (
          <option key={id} value={id}>
            {label}
          </option>
        ))}
    </select>
  </SelectInput>
)
