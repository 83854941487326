import React from "react"
import { Map, TileLayer, Circle, Marker } from "react-leaflet"
import styled from "styled-components"

const StyledMap = styled(Map)`
  height: 300px;
`

interface Props {
  lat: number
  lng: number
  radius?: number
}

const SimpleMap: React.FC<Props> = ({ lat, lng, radius }) => {
  const center: [number, number] = [lat, lng]
  const zoom = radius ? Math.min(19, Math.max(2, Math.round(24 - Math.log2(radius)))) : 15

  return (
    <StyledMap center={center} zoom={zoom}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {radius ? <Circle center={center} radius={radius} /> : <Marker position={center} />}
    </StyledMap>
  )
}

export default SimpleMap
