import React from "react"
import { RouteComponentProps } from "react-router-dom"
import { AnyObject } from "final-form"

import { Container, Paper } from "Authenticated/components/containers"
import { Form, Title, Row, TextInput, Select, Caption, Checkbox } from "components/form"
import api from "services/api"
import { useToast } from "components/toast"
import { RESOURCE_TYPES, MAX_UPLOAD_SIZE, MIME_TYPES } from "helpers/constants"
import { getFileExt } from "helpers/string"
import FileField from "components/form/FileField"
import { useUser } from "components/user"
import { secondsInDay } from "date-fns"

const fileIsValid = (file: File, type: IResourceType): boolean => {
  if (type === "object3D" || type === "scenario") {
    const ext = getFileExt(file.name)
    return ext === "glb" || ext === "gltf"
  } else {
    return MIME_TYPES[type].includes(file.type)
  }
}

const NewResource: React.FC<RouteComponentProps> = ({ history }) => {
  const toast = useToast()
  const user = useUser()
  const AVAILABLE_TYPES = Object.keys(RESOURCE_TYPES).filter((type) => {
    if (type !== "scene") {
      if (type == "scenario" && !user.user.roles.includes("ROLE_ADMIN")) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  })
  const handleSubmit = async (values: AnyObject) => {
    if (values.type === "scene") {
      await api.post<IResource>("resource_scenes", values)
    } else {
      const file: File = values.file
      if (!fileIsValid(file, values.type)) {
        const type = RESOURCE_TYPES[values.type as IResourceType]
        throw new Error(`Ce type de fichier est invalide pour une ressource de type ${type}`)
      }
      if (file.size > MAX_UPLOAD_SIZE * 1024 * 1024) {
        throw new Error(`Le fichier ne doit pas dépasser ${MAX_UPLOAD_SIZE} Mo`)
      }
      const payload = new FormData()
      payload.append("label", values.label)
      payload.append("type", values.type)
      payload.append(
        "isDeleteable",
        values.isDeleteable === "false" || values.isDeleteable === undefined ? "true" : "false"
      )
      payload.append("file", file)
      await api.post<IResource>("resource_files", payload)
    }
    history.push("/resources")
    toast("La ressource a bien été créée")
  }
  console.log("USER: ", user)

  return (
    <Container title="Nouvelle ressource" back="/resources">
      <Paper>
        <Form submitLabel="Enregistrer" onSubmit={handleSubmit}>
          <Title>Ajouter le nom de la ressource</Title>
          <Caption>
            Merci de respecter la nomenclature suivante pour choisir le nom de votre ressource :
            INITIALES nom/prénom_année en cours format AA_nom souhaité
            <br />
            (exemple : SM_22_la théorie des jeux).
            <br />
            Cela vous permettra de retrouver plus facilement les ressources qui vous appartiennent
            dans notre base de données.
          </Caption>
          <Row>
            <TextInput required name="label" placeholder="Saisissez ici le nom de la ressource" />
          </Row>
          <Row>
            <Select required name="type" placeholder="Sélectionnez le type de ressource">
              {AVAILABLE_TYPES.map((type) => (
                <option key={type} value={type}>
                  {RESOURCE_TYPES[type as IResourceType]}
                </option>
              ))}
            </Select>
          </Row>
          <Title>Ajouter un fichier</Title>
          <Caption>
            Sélectionnez un fichier depuis votre poste de travail.
            <br />
            Taille maximale autorisée : {MAX_UPLOAD_SIZE} Mo.
            <br />
            Il est recommandé d'utiliser des ressources ne dépassant pas 50Mo afin d'éviter une
            surcharge du serveur, susceptible d'entraîner des latences importantes.
          </Caption>
          <Row>
            <FileField required name="file" />
          </Row>
          <Row>
            <Checkbox name="isDeleteable">Bloquer l'édition / suppression</Checkbox>
          </Row>
        </Form>
      </Paper>
    </Container>
  )
}

export default NewResource
