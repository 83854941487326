import React from "react"

import { Colors } from "helpers/constants"

interface Props {
  bgColor?: string
}

const PdfIcon: React.FC<Props> = ({ bgColor, ...props }) => (
  <svg viewBox="0 0 77 77" {...props}>
    <circle
      stroke="#FFFEFE"
      fill={bgColor || Colors.blue}
      fillRule="nonzero"
      cx="37.5"
      cy="37.5"
      r="37.5"
    />
    <g fill="none" fillRule="evenodd">
      <path
        d="M60.531 21.437l-25.393-10.04-2.717 6.91-6.91-2.657-12.696 32.303a2.923 2.923 0 0 0 1.654 3.78l29.586 11.633a2.923 2.923 0 0 0 3.78-1.653l14.35-36.496c.59-1.536-.118-3.19-1.654-3.78z"
        fill="#324A5E"
        fillRule="nonzero"
      />
      <path
        d="M53.386 11.398H26.102v4.488c0 1.594-1.299 2.953-2.952 2.953H18.66v34.724c0 1.594 1.3 2.953 2.953 2.953h31.83c1.595 0 2.954-1.3 2.954-2.953V14.35c-.06-1.594-1.359-2.952-3.012-2.952z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        fill="#E6E9EE"
        fillRule="nonzero"
        d="M26.102 11.398l-7.44 7.44h4.488c1.594 0 2.952-1.299 2.952-2.952v-4.488zM23.208 23.563h28.524v2.303H23.208zM23.208 29.232h28.524v2.303H23.208zM23.208 34.961h28.524v2.303H23.208zM23.208 40.69h28.524v2.302H23.208zM23.208 46.417h18.78v2.303h-18.78z"
      />
      <path
        d="M60.91 59.66a.667.667 0 0 1-.591.34H35.282a2.29 2.29 0 0 1-.678-.102A2.265 2.265 0 0 1 33 57.742V47.444c0-.245.201-.444.448-.444h26.87c.358 0 .533.238.591.34a.652.652 0 0 1-.008.677l-2.727 4.498a1.894 1.894 0 0 0 0 1.97l2.727 4.498a.653.653 0 0 1 .008.676z"
        stroke="#FBB900"
        fill="#FBB900"
        fillRule="nonzero"
      />
      <text
        fontFamily="Klavika-Regular, Klavika"
        fontSize="11"
        letterSpacing="-.138"
        fill="#1D1D1B"
        transform="translate(33 47)"
      >
        <tspan x="4.494" y="10">
          PDF
        </tspan>
      </text>
    </g>
  </svg>
)

export default PdfIcon
