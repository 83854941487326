import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { Colors, Shadows } from "helpers/constants"
import Loader from "images/Loader"
import { ReactComponent as PrevIcon } from "images/prev.svg"
import { ReactComponent as NextIcon } from "images/next.svg"
import { ErrorMessage } from "Authenticated/components/containers"

const TableContainer = styled.table`
  border: none;
  width: 100%;
  border-collapse: collapse;
`
const TableHead = styled.thead`
  background-color: #f7f7f7;
  box-shadow: ${Shadows.paper};
  border-radius: 5px;
`
const Cell = styled.td<{ large?: boolean }>`
  color: ${Colors.darkGrey};
  padding: 10px 20px;
  font-size: 15px;
  &:last-of-type {
    width: 1%;
  }
  ${(props) => (props.large ? "word-break: break-word;" : "")}
`
const BigCell = styled.td<{ empty?: boolean }>`
  text-align: center;
  padding: ${(props) => (props.empty ? 80 : 20)}px;
`
const HeadCell = styled.th`
  font-weight: 500;
  text-align: left;
  padding: 20px;
  white-space: nowrap;
`
const TableBody = styled.tbody`
  box-shadow: ${Shadows.paper};
  background-color: ${Colors.white};
  padding: 10px 0 80px;
  min-height: 670px;
`
const Row = styled.tr`
  height: 50px;
  &:not(:last-of-type):hover {
    background-color: #fafafa;
  }
`

const Page = styled.button<{ active?: boolean }>`
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 2px;
  text-align: center;
  display: inline-block;
  margin: 2px;
  font-size: 11px;
  font-weight: 600;
  color: ${(props) => (props.active ? Colors.white : Colors.text)};
  background-color: ${(props) => (props.active ? Colors.grey : "#f2f2f2")};
`
const Navigate = styled.button`
  width: 30px;
  svg {
    vertical-align: middle;
  }
`

const NUM_ROWS = 10

interface IRow {
  row: React.ReactNode[]
  data: any
}

interface TableProps {
  columns: string[]
  rows?: IRow[]
  largeColumns?: Record<number, boolean>
  loading: boolean
  error?: Error
  update?: any
  pageUrl?: string | null
}

const Table: React.FC<TableProps> = ({
  columns,
  rows = [],
  largeColumns,
  loading,
  error,
  update,
  pageUrl,
}) => {
  const [currentPage, setCurrentPage] = useState(0)
  const nbPages = Math.ceil(rows.length / NUM_ROWS)

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNext = () => {
    if (currentPage < nbPages - 1) {
      setCurrentPage(currentPage + 1)
    }
  }

  useEffect(() => {
    if (!loading) {
      update((currentPage + 1).toString())
    }
  }, [currentPage])

  useEffect(() => {
    // reset to first page when number of rows changes, to avoid offset issues
    setCurrentPage(0)
    if (pageUrl) {
      setCurrentPage(parseInt(pageUrl) - 1)
    }
  }, [rows.length])
  return (
    <TableContainer>
      <TableHead>
        <tr>
          {columns.map((column, index) => (
            <HeadCell key={index}>{column}</HeadCell>
          ))}
        </tr>
      </TableHead>
      <TableBody>
        {rows
          .slice(currentPage * NUM_ROWS, (currentPage + 1) * NUM_ROWS)
          .map(({ row }, rowIndex) => (
            <Row key={rowIndex}>
              {row.map((cell, index: number) => (
                <Cell key={index} large={largeColumns && largeColumns[index]}>
                  {cell}
                </Cell>
              ))}
            </Row>
          ))}
        <Row>
          {loading || error ? (
            <BigCell colSpan={columns.length} empty>
              {loading && <Loader color={Colors.text} />}
              {error && <ErrorMessage>Erreur : {error.message}</ErrorMessage>}
            </BigCell>
          ) : (
            <BigCell colSpan={columns.length} empty={!rows.length}>
              {rows.length === 0 && "Aucune donnée"}
              {rows.length > NUM_ROWS && (
                <>
                  <Navigate type="button" onClick={handlePrevious}>
                    <PrevIcon />
                  </Navigate>
                  {Array.from({ length: nbPages }, (_, index) => index).map((page) => (
                    <Page
                      key={page}
                      type="button"
                      onClick={() => setCurrentPage(page)}
                      active={currentPage === page}
                    >
                      {page + 1}
                    </Page>
                  ))}
                  <Navigate type="button" onClick={handleNext}>
                    <NextIcon />
                  </Navigate>
                </>
              )}
            </BigCell>
          )}
        </Row>
      </TableBody>
    </TableContainer>
  )
}

export default Table

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  white-space: nowrap;
  .importGroup {
    margin-left: auto;
    margin-right: 20px;
  }
`

export const ControlsEnd = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 30px;
  white-space: nowrap;
`

export const Actions = styled.div`
  white-space: nowrap;
  button {
    white-space: nowrap;
  }
  svg {
    width: 30px;
    height: 30px;
    margin: 0 5px;
  }
  .checkbox-wrapper-65 *,
  .checkbox-wrapper-65 ::after,
  .checkbox-wrapper-65 ::before {
    box-sizing: border-box;
  }
  .checkbox-wrapper-65 .cbx {
    position: relative;
    display: block;
    float: left;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: #606062;
    background-image: linear-gradient(#474749, #606062);
    box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.15), inset 0 -1px 1px rgba(0, 0, 0, 0.15);
    transition: all 0.15s ease;
  }
  .checkbox-wrapper-65 .cbx svg {
    position: absolute;
    top: 3px;
    left: 3px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 17;
    stroke-dashoffset: 17;
    transform: translate3d(0, 0, 0);
    width: 12px;
    height: 11px;
    margin: 0px;
  }
  .checkbox-wrapper-65 .cbx + span {
    float: left;
    margin-left: 6px;
  }
  .checkbox-wrapper-65 {
    user-select: none;
  }
  .checkbox-wrapper-65 label {
    display: inline-block;
    cursor: pointer;
  }
  .checkbox-wrapper-65 input[type="checkbox"] {
    display: none;
    visibility: hidden;
  }
  .checkbox-wrapper-65 input[type="checkbox"]:checked + .cbx {
    background-color: #606062;
    background-image: linear-gradient(#eaac00, #ffbb00);
  }
  .checkbox-wrapper-65 input[type="checkbox"]:checked + .cbx svg {
    stroke-dashoffset: 0;
    transition: all 0.15s ease;
  }
`
