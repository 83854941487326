import { Mode } from "Authenticated/components/mode"

const COLORS: Record<string, string> = {
  transparent: "transparent",
  overlay: "rgba(0, 0, 0, 0.53)",

  green: "#28A745",
  red: "#DC3545",
  yellow: "#FFBB00",
  blue: "#21BBEF",
  lightBlue: "#F2F8FC",
  greenBlue: "#0070BA",

  white: "#FFFFFF",
  lightGrey: "#E1E1E1",
  grey: "#CCCCCC",
  grey2: "#979797",
  darkGrey: "#1D1D1B",
  black: "#000000",
}

export const Colors: Record<string, string> = {
  ...COLORS,
  button: COLORS.yellow,
  success: COLORS.green,
  error: COLORS.red,
  background: COLORS.lightBlue,
  separator: COLORS.grey,
  text: COLORS.darkGrey,
  delete: "#FF0E00",
}

export const Shadows = {
  paper: "0 1px 5px 0 rgba(0, 0, 0, 0.1)",
  menu: "0 0 10px 0 rgba(74, 74, 74, 0.3)",
}

export const HELP_EMAIL_ADDRESS = "projet.artefac@gmail.com"

export const PROFILES: Record<IUserProfile, string> = {
  teacher_primary: "Enseignant·e de primaire",
  teacher_secondary: "Enseignant·e de collège",
  teacher_highschool: "Enseignant·e de lycée",
  trainer: "Formateur·rice",
  researcher: "Enseignant·e chercheur·e",
  other: "Autre",
}

export const ROLES: Record<IUserRole, string> = {
  ROLE_ADMIN: "Administrateur·rice",
  ROLE_TEACHER: "Enseignant·e",
  ROLE_STUDENT: "Étudiant·e",
}

export const RESOURCE_TYPES: Record<IResourceType, string> = {
  image: "Image",
  audio: "Audio",
  video: "Vidéo",
  pdf: "PDF",
  360: "Image 360°",
  object3D: "Objet 3D",
  picto_poi: "Picto POI",
  scene: "Scène 3D",
  scenario: "Scénario 3D",
  tag: "Tag",
}

export const STEP_TYPES: Record<IStepType, string> = {
  simple: "Simple",
  file: "Document PDF, Image, Vidéo ou son",
  file360: "Image 360°",
  object3D: "Objet 3D",
  tagScene: "Objets 3D déclenchés via tags",
  scene: "Scène 3D",
  gps: "Points d’intérêt géographiques",
  exercice: "Quiz",
  scenario: "Scenario 3D",
}

export const MODE_TYPES: Record<Mode, IStepType[]> = {
  [Mode.advanced]: Object.keys(STEP_TYPES) as IStepType[],
  [Mode.beginner]: ["simple", "file", "file360", "object3D", "tagScene", "scene"],
}

export const STEP_ENDPOINTS: Record<IStepType, string> = {
  simple: "simples",
  file: "files",
  file360: "file360s",
  object3D: "object3_ds",
  tagScene: "tag_scenes",
  scene: "scenes",
  scenario: "scenarios",
  gps: "g_p_s",
  exercice: "exercices",
}

export const MAX_UPLOAD_SIZE = 128

export const FILE_RESOURCE_TYPES: IResourceType[] = ["image", "audio", "video", "pdf"]

export const MIME_TYPES: Record<string, string[]> = {
  image: ["image/jpeg", "image/png"],
  audio: ["audio/mp3", "audio/mpeg"], // https://stackoverflow.com/a/10688641/1052033
  video: ["video/mp4"],
  pdf: ["application/pdf"],
}
MIME_TYPES["360"] = MIME_TYPES.image
MIME_TYPES["picto_poi"] = MIME_TYPES.image
MIME_TYPES["tag"] = MIME_TYPES.image

// export const DEFAULT_COORDINATES = {
//   // Strategia office:
//   lat: "43.56875",
//   lng: "1.3912",
// }

export const MONTHS: Record<string, string> = {
  "1": "Janvier",
  "2": "Février",
  "3": "Mars",
  "4": "Avril",
  "5": "Mai",
  "6": "Juin",
  "7": "Juillet",
  "8": "Août",
  "9": "Septembre",
  "10": "Octobre",
  "11": "Novembre",
  "12": "Décembre",
}

export const NOTIFICATIONS: Record<string, any> = {
  user: {
    title: "Alerte utilisateurs",
    account_creation: "un utilisateur vient de créer son compte",
  },
  module: {
    title: "Alerte modules",
    teacher_share: "un module a été partagé",
    student_share: "un module a été partagé",
    created: "un nouveau module a été ajouté",
    completed: "un module a été complété",
    cloned: "un module a été dupliqué",
  },
}
