import React, { useRef } from "react"
import styled from "styled-components"
import { RouteComponentProps } from "react-router-dom"

import { Container, Paper, Placeholder } from "Authenticated/components/containers"
import { Colors, RESOURCE_TYPES, STEP_TYPES } from "helpers/constants"
import { useApi } from "helpers/hooks"
import { useUser } from "components/user"
import { Title } from "components/form"
import api, { getUrl } from "services/api"
import { LinkButton, IconButton, Button } from "components/buttons"
import { useToast } from "components/toast"
import { ReactComponent as PrevIcon } from "images/prev.svg"
import { ReactComponent as NextIcon } from "images/next.svg"
import RESOURCE_ICONS from "images/resources"

const SCROLL_OFFSET = 540 // pixels

const Pages = styled.div`
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  padding: 20px 0 30px;
  width: 100%;
`
const Page = styled(Paper)`
  flex: 0 0 500px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
`
const Content = styled.div`
  flex: 1;
  padding-bottom: 30px;
  ${Title} {
    text-align: center;
    margin-bottom: 30px;
  }
`
const Icon = styled.img`
  width: 50px;
  vertical-align: middle;
  margin-right: 20px;
`
const Type = styled.p`
  color: ${Colors.blue};
  text-transform: uppercase;
  svg {
    height: 52px;
    width: 52px;
    vertical-align: middle;
    margin-right: 15px;
  }
`
const Info = styled.p`
  font-weight: 500;
`
const ModuleAction = styled.div`
  text-align: center;
  a {
    padding: 15px 80px;
  }
`
const StepActions = styled.div`
  display: flex;
  justify-content: stretch;
  margin: 0 -5px;
  button,
  a {
    flex: 1;
    display: inline-block;
    text-align: center;
    padding: 15px;
    margin: 0 5px;
  }
`
const End = styled.div`
  flex: 0 0 40px;
`
const Paging = styled.div`
  text-align: center;
  margin: 20px;
  color: ${Colors.button};
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 200;
  button {
    padding: 10px;
  }
  svg {
    fill: ${Colors.button};
    height: 40px;
    width: 40px;
  }
`
const ModuleActions = styled.div`
  display: flex;
  justify-content: space-between;
`

interface MatchParams {
  id: string
}

const Module: React.FC<RouteComponentProps<MatchParams>> = ({ match, history }) => {
  const { id } = match.params
  const { data, loading, error, reload } = useApi<IModule>(`modules/${id}`)
  const { admin, user } = useUser()

  const page = localStorage.getItem("paginationModule")
  const from = localStorage.getItem("from")
  const linkBack = page ? `/${from}?page=${page}` : `/${from}`

  const toast = useToast()
  const scrollbars: React.RefObject<HTMLDivElement> = useRef<any>()

  if (!data) {
    return (
      <Container back={linkBack}>
        <Placeholder loading={loading} error={error} />
      </Container>
    )
  }

  const editable = admin || data.author?.id === user.id

  const groups =
    data.groupes && data.groupes.length > 0 && data.groupes.map(({ groupName }) => groupName)
  const mail = data.mail || data.author?.mail

  const scroll = (offset: number) => {
    if (!scrollbars.current) {
      return
    }
    const left = scrollbars.current.scrollLeft + offset
    if (typeof scrollbars.current.scroll === "function") {
      try {
        scrollbars.current.scroll({ left, behavior: "smooth" })
      } catch (e) {
        scrollbars.current.scrollTo(left, 0)
      }
    } else {
      // older browsers
      scrollbars.current.scrollLeft = left
    }
  }

  const handleDeleteStep =
    ({ id: stepId, hasStats }: IStep) =>
    () => {
      let message = "Êtes-vous sûr·e de vouloir supprimer cette étape ?"
      if (hasStats) {
        message += "\nAttention : cela effacera aussi ses statistiques d’utilisation !"
      }
      if (window.confirm(message)) {
        api
          .delete(`steps/${stepId}`)
          .then(() => {
            toast("L’étape a bien été supprimée")
            reload()
          })
          .catch((error) => {
            alert(`Erreur : ${error.message}`)
          })
      }
    }
  const handlePrev = () => {
    scroll(-SCROLL_OFFSET)
  }
  const handleNext = () => {
    scroll(SCROLL_OFFSET)
  }
  const handlePublish = () => {
    api
      .put<IModule>(`modules/${id}`, {
        isPublished: !data.isPublished,
      })
      .then((module) => {
        toast(`Le module a bien été ${module.isPublished ? "publié" : "dépublié"}`)
        reload()
      })
  }

  return (
    <Container title={data.label} code={data.accessCode} back={linkBack}>
      <Pages ref={scrollbars}>
        <Page>
          <Content>
            <Title>Paramètres du module</Title>
            <Type>
              <Icon src={getUrl(data.category.contentUrl)} />
              {data.category.label}
            </Type>
            <p>{data.description}</p>
            {data.visibility && <Info>Module partagé avec les concepteur·rice·s</Info>}
            {groups && <Info>Module partagé avec les groupes {groups.join(", ")}</Info>}
            <Info>
              <a href={`mailto:${mail}`}>{mail}</a>
            </Info>
          </Content>
          {editable && (
            <ModuleAction>
              <LinkButton to={`/modules/${id}/edit`}>Modifier</LinkButton>
            </ModuleAction>
          )}
        </Page>
        {data.steps.map((step) => {
          const { id: stepId, weight, label } = step

          const Icon = RESOURCE_ICONS[step.type === "file" ? step.file.type : step.type]

          const typeLabel =
            step.type === "file" ? RESOURCE_TYPES[step.file.type] : STEP_TYPES[step.type]

          let description
          switch (step.type) {
            case "simple":
            case "file":
              description = step.description
              break
            case "file360":
              description = `Ressource : ${step.file.label}`
              break
            case "object3D":
              description = `Ressource : ${step.object3D.label}`
              break
            case "tagScene":
              description = `${step.tagObject3Ds.length} tag(s)`
              break
            case "gps":
              description = `${step.pois.length} POI(s)`
              break
            case "scene":
              description = step.resource && step.resource.label
              break
            case "exercice":
              description = step.question
              break
          }

          return (
            <Page key={stepId}>
              <Content>
                <Title>
                  {weight} — {label}
                </Title>
                <Type>
                  <Icon /> {typeLabel}
                </Type>
                <p>{description}</p>
              </Content>
              {editable && (
                <StepActions>
                  <Button variant="delete" onClick={handleDeleteStep(step)}>
                    Supprimer cette étape
                  </Button>
                  <LinkButton to={`/modules/${id}/steps/${stepId}`}>Modifier</LinkButton>
                </StepActions>
              )}
            </Page>
          )
        })}
        <End />
      </Pages>
      <Paging>
        <IconButton onClick={handlePrev}>
          <PrevIcon />
        </IconButton>
        Faire défiler les étapes
        <IconButton onClick={handleNext}>
          <NextIcon />
        </IconButton>
      </Paging>
      {editable && (
        <ModuleActions>
          <Button variant="white" onClick={handlePublish}>
            {data.isPublished ? "Dépublier" : "Publier"} le module
          </Button>
          <LinkButton variant="white" to={`/modules/${id}/steps/new`}>
            Nouvelle étape
          </LinkButton>
        </ModuleActions>
      )}
    </Container>
  )
}

export default Module
